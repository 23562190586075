export default {
    domains: [
        {
            title: 'State Data Entry',
            messages: [
                'statePublicSchoolTitle',
                'statePublicSchoolDescription',
                'statePrivateSchoolTitle',
                'statePrivateSchoolDescription',
            ],
        },
        {
            title: 'District Data Entry',
            messages: ['districtPublicSchoolTitle', 'districtPublicSchoolDescription'],
        },
    ],
    messages: {
        statePublicSchoolTitle: {
            title: 'Public School Data Entry Question',
            message: 'Will your state enter data for public schools this school year?',
        },
        statePublicSchoolDescription: {
            title: 'Public School Data Entry Description',
            message: `<p>If <strong>YES</strong>, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br />
            If <strong>NO</strong>, Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        statePrivateSchoolTitle: {
            title: 'Private School Data Entry Question',
            message: 'Will your state enter data for private schools this school year?',
        },
        statePrivateSchoolDescription: {
            title: 'Private School Data Entry Description',
            message: `<p>If <strong>YES</strong>, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br />
            If <strong>NO</strong>, Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        districtPublicSchoolTitle: {
            title: 'Public School Data Entry Question',
            message: 'Will your district enter data for public schools this school year?',
        },
        districtPublicSchoolDescription: {
            title: 'Public School Data Entry Description',
            message: `<p>If <strong>YES</strong>, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br />
            If <strong>NO</strong>, Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
    },
};
