import { handleActions, createAction } from 'redux-actions';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

export const initialState = {
    overviewFields: {},
    reportFields: {},
};

const SET_OVERVIEW_FIELDS = 'app/nasn/SET_OVERVIEW_FIELDS';
const SET_DATAENTRY_FIELDS = 'app/nasn/SET_DATAENTRY_FIELDS';
const SET_REPORT_FIELDS = 'app/nasn/SET_REPORT_FIELDS';

export const setOverviewFields = createAction(SET_OVERVIEW_FIELDS);
export const setDataEntryFields = createAction(SET_DATAENTRY_FIELDS);
export const setReportFields = createAction(SET_REPORT_FIELDS);

export const updateParams = (params, key) => async () => {
    const response = await axios.post(Boilerplate.route('site.set.data'), {
        data: JSON.stringify(params),
        data_key: key,
    });
    if (response.data.success) {
        notification({
            message: `Site fields have been updated.`,
            type: 'success',
            duration: 8000,
        });
    }
};

// fields: JSON object, contains the default text to be used.
export const getSavedParams = (fields, key) => {
    return async dispatch => {
        const response = await axios.get(Boilerplate.route('site.get.data'), {
            data_key: key,
        });
        let params = {};
        if (fields) {
            Object.keys(fields.messages).forEach(handle => {
                params[handle] = fields.messages[handle].message;
            });
            if (response.data.data[key]) {
                params = {
                    ...params,
                    ...JSON.parse(response.data.data[key].data),
                };
            }
        }

        if (key === 'overview') {
            await dispatch(setOverviewFields(params));
        }
        if (key === 'dataEntry') {
            await dispatch(setDataEntryFields(params));
        }
        if (key === 'report') {
            await dispatch(setReportFields(params));
        }
    };
};

export default handleActions(
    {
        [SET_OVERVIEW_FIELDS]: (state, action) => ({
            ...state,
            overviewFields: action.payload,
        }),
        [SET_DATAENTRY_FIELDS]: (state, action) => ({
            ...state,
            dataEntryFields: action.payload,
        }),
        [SET_REPORT_FIELDS]: (state, action) => ({
            ...state,
            reportFields: action.payload,
        }),
    },
    initialState
);
