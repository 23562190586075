import React from 'react';
import style from './style.css';

const ReportFooter = () => {
    return (
        <div className={style.wrapper}>
            <div>
                To learn more about Every Student Counts! including data point definitions and tiers, go to{' '}
                <a href="https://www.nasn.org/nasn/research/everystudentcounts">
                    nasn.org/nasn/research/everystudentcounts
                </a>{' '}
                or email <a href="mailto:data@nasn.org">data@nasn.org</a>{' '}
            </div>
            <div>
                <div className={style.logo} />
            </div>
        </div>
    );
};

export default ReportFooter;
