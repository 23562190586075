export default {
    publicSchool: [
        'PUB_SCH_NUM_RN_FTE',
        'PUB_SCH_NUM_LPN_FTE',
        'PUB_SCH_NUM_AIDE_FTE',
        'PUB_SCH_RN_FTE_CASELOAD',
        'PUB_SCH_RN_FTE_FLOAT',
        'PUB_SCH_RN_FTE_SPEC_ASN',
        'PUB_SCH_RN_FTE_ADMIN',
        'PUB_SCH_LPN_FTE_CASELOAD',
        'PUB_SCH_LPN_FTE_FLOAT',
        'PUB_SCH_LPN_FTE_SPEC_ASN',
        'PUB_SCH_LPN_FTE_ADMIN',
        'PUB_SCH_AIDE_FTE_CASELOAD',
        'PUB_SCH_AIDE_FTE_FLOAT',
        'PUB_SCH_AIDE_FTE_SPEC_ASN',
        'PUB_SCH_AIDE_FTE_ADMIN',
    ],

    privateSchool: [
        'PRIV_SCH_NUM_RN_FTE',
        'PRIV_SCH_NUM_LPN_FTE',
        'PRIV_SCH_NUM_AIDE_FTE',
        'PRIV_SCH_RN_FTE_CASELOAD',
        'PRIV_SCH_RN_FTE_FLOAT',
        'PRIV_SCH_RN_FTE_SPEC_ASN',
        'PRIV_SCH_RN_FTE_ADMIN',
        'PRIV_SCH_LPN_FTE_CASELOAD',
        'PRIV_SCH_LPN_FTE_FLOAT',
        'PRIV_SCH_LPN_FTE_SPEC_ASN',
        'PRIV_SCH_LPN_FTE_ADMIN',
        'PRIV_SCH_AIDE_FTE_CASELOAD',
        'PRIV_SCH_AIDE_FTE_FLOAT',
        'PRIV_SCH_AIDE_FTE_SPEC_ASN',
        'PRIV_SCH_AIDE_FTE_ADMIN',
    ],

    district: [
        'DIST_RN_TOTAL_FTE',
        'DIST_LPN_TOTAL_FTE',
        'DIST_AIDE_TOTAL_FTE',
        'DIST_RN_FTE_CASELOAD',
        'DIST_RN_FTE_FLOAT',
        'DIST_RN_FTE_SPEC_ASN',
        'DIST_RN_FTE_ADMIN',
        'DIST_LPN_FTE_CASELOAD',
        'DIST_LPN_FTE_FLOAT',
        'DIST_LPN_FTE_SPEC_ASN',
        'DIST_LPN_FTE_ADMIN',
        'DIST_AIDE_FTE_CASELOAD',
        'DIST_AIDE_FTE_FLOAT',
        'DIST_AIDE_FTE_SPEC_ASN',
        'DIST_AIDE_FTE_ADMIN',
    ],

    state: [
        'ST_RN_TOTAL_FTE',
        'ST_LPN_TOTAL_FTE',
        'ST_AIDE_TOTAL_FTE',
        'ST_RN_FTE_CASELOAD',
        'ST_RN_FTE_FLOAT',
        'ST_RN_FTE_SPEC_ASN',
        'ST_RN_FTE_ADMIN',
        'ST_LPN_FTE_CASELOAD',
        'ST_LPN_FTE_FLOAT',
        'ST_LPN_FTE_SPEC_ASN',
        'ST_LPN_FTE_ADMIN',
        'ST_AIDE_FTE_CASELOAD',
        'ST_AIDE_FTE_FLOAT',
        'ST_AIDE_FTE_SPEC_ASN',
        'ST_AIDE_FTE_ADMIN',
    ],
    statePrivate: [
        'ST_PRIV_SCH_NUM_RN_FTE',
        'ST_PRIV_SCH_NUM_LPN_FTE',
        'ST_PRIV_SCH_NUM_AIDE_FTE',
        'ST_PRIV_SCH_RN_FTE_CASELOAD',
        'ST_PRIV_SCH_RN_FTE_FLOAT',
        'ST_PRIV_SCH_RN_FTE_SPEC_ASN',
        'ST_PRIV_SCH_RN_FTE_ADMIN',
        'ST_PRIV_SCH_LPN_FTE_CASELOAD',
        'ST_PRIV_SCH_LPN_FTE_FLOAT',
        'ST_PRIV_SCH_LPN_FTE_SPEC_ASN',
        'ST_PRIV_SCH_LPN_FTE_ADMIN',
        'ST_PRIV_SCH_AIDE_FTE_CASELOAD',
        'ST_PRIV_SCH_AIDE_FTE_FLOAT',
        'ST_PRIV_SCH_AIDE_FTE_SPEC_ASN',
        'ST_PRIV_SCH_AIDE_FTE_ADMIN',
    ],
};
