import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import GroupComparison from '../../GroupComparison';
import districtQuery from './districtsQuery.graphql';
import publicSchoolsQuery from './publicSchoolsQuery.graphql';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';

const StateComparisonTable = props => {
    const [selectedDeployment, setSelectedDeployment] = useState('');
    const districtDeploymentHandle = selectedDeployment.slice(0, 9) + '_district';
    const schoolDeploymentHandle = selectedDeployment.slice(0, 9) + '_publicSchool';

    const columns = [
        { name: 'group.groupId', label: 'Id', hideInTable: true },
        { name: 'group.label', label: 'District', sort: true, filter: true },
    ];
    const schoolColumns = [
        { name: 'group.groupId', label: 'Id', hideInTable: true },
        { name: 'group.label', label: 'School', sort: true, filter: true },
        {
            name: 'parentGroup.district.group.label',
            label: 'School District',
            sort: true,
            filter: true,
        },
    ];

    const deployments = props.deployments.filter(x => x.isOpen === false || x.closed === true); // Change this to === false before live deployment

    useEffect(() => {
        if (deployments.length > 0) {
            setSelectedDeployment(deployments[0].deploymentHandle);
        }
    }, []);

    if (deployments.length <= 0) {
        return (
            <div className="alert alert-info">
                There are currently no completed assessment periods for comparison.
            </div>
        );
    }

    return (
        <div>
            {deployments.length > 0 ? (
                <>
                    <div className={style.tableInfo}>
                        <label className="control-label">
                            School Year
                            <select
                                className="form-control"
                                value={selectedDeployment}
                                onChange={e => setSelectedDeployment(e.target.value)}
                            >
                                {deployments.map(option => {
                                    return (
                                        <option
                                            key={option.deploymentHandle}
                                            value={option.deploymentHandle}
                                        >
                                            {option.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </label>
                        <div className={style.infoBox}>
                            <div className={style.infoBoxLeft}>
                                <IconInfo />
                            </div>
                            <div className={style.infoBoxRight}>
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '0.5em',
                                    }}
                                >
                                    These tables only show groups that have data entered for the
                                    selected school year.
                                </div>
                                <div>
                                    Select up to 2 groups using the table checkboxes, then select
                                    Generate Report in the dropdown menu above the table.
                                </div>
                            </div>
                        </div>
                    </div>

                    <h4>My Districts</h4>
                    <Table
                        rowKey="group.groupId"
                        graphqlVariables={{ deploymentHandle: districtDeploymentHandle }}
                        query={districtQuery}
                        columns={columns}
                        perPage={10}
                        useTaskMaster
                        maxSelectableRows={2}
                        rowActions={[
                            {
                                name: 'groupComparison',
                                title: 'Generate Report',
                                inModal: true,
                                size: '900px',
                                component: dProps => {
                                    return (
                                        <GroupComparison
                                            groupsToCompare={dProps.selectedRows}
                                            schoolYear={selectedDeployment}
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                    <h4>My Schools</h4>
                    <Table
                        rowKey="group.groupId"
                        graphqlVariables={{ deploymentHandle: schoolDeploymentHandle }}
                        query={publicSchoolsQuery}
                        columns={schoolColumns}
                        perPage={10}
                        useTaskMaster
                        maxSelectableRows={2}
                        rowActions={[
                            {
                                name: 'groupComparison',
                                title: 'Generate Report',
                                inModal: true,
                                size: '900px',
                                component: dProps => {
                                    return (
                                        <GroupComparison
                                            groupsToCompare={dProps.selectedRows}
                                            schoolYear={selectedDeployment}
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                </>
            ) : (
                <div className="alert alert-info">
                    There are no completed data collection periods currently.
                </div>
            )}
        </div>
    );
};

StateComparisonTable.propTypes = {
    deploymentIsOpen: PropTypes.bool,
    deploymentHandle: PropTypes.string,
    deployments: PropTypes.array,
};

export default StateComparisonTable;
