import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import { BrowserOnly } from 'cccisd-laravel-report';
import _pick from 'lodash/pick';
import _round from 'lodash/round';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);

    const { nationalData, nationalDataLoaded } = reduxStore;

    const nationalDataKey = props.filters.schoolType; // 'national_All Schools', 'national_Public Schools'

    const getReportedStatesCount = () => {
        if (nationalDataLoaded) {
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];
                const ccData = _pick(data, [
                    'Total_Students_Asthma_Per_1000',
                    'Total_Students_Type_1_Diabetes_Per_1000',
                    'Total_Students_Type_2_Diabetes_Per_1000',
                    'Total_Students_Seizure_Disorder_Per_1000',
                    'Total_Students_Life_Threatening_Allergy_Per_1000',
                    'Total_Students_ME_CFS_Per_1000',
                ]);
                if (Object.values(ccData).some(x => x !== '0') && data.Reported_Chronic_Conditions !== '0') {
                    return (
                        <>
                            {'('} {data.Reported_Chronic_Conditions} {' states represented )'}
                        </>
                    );
                }
                return '';
            }
        }
        return '';
    };

    const getData = () => {
        if (nationalDataLoaded) {
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];

                const totalAsthma = data.Total_Students_Asthma_Per_1000;
                const totalType1 = data.Total_Students_Type_1_Diabetes_Per_1000;
                const totalType2 = data.Total_Students_Type_2_Diabetes_Per_1000;
                const totalSeizure = data.Total_Students_Seizure_Disorder_Per_1000;
                const totalLta = data.Total_Students_Life_Threatening_Allergy_Per_1000;
                const totalMecfs = data.Total_Students_ME_CFS_Per_1000;

                return {
                    asthma: _round(totalAsthma, 2),
                    type1: _round(totalType1, 2),
                    type2: _round(totalType2, 2),
                    lta: _round(totalLta, 2),
                    seizure: _round(totalSeizure, 2),
                    mecfs: _round(totalMecfs, 2),
                };
            }
        }
        return { asthma: '', type1: '', type2: '', lta: '', seizure: '', mecfs: '' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Chronic Conditions'}
                        </div>
                        <div className={style.stateInfo}>{getReportedStatesCount()}</div>
                        {nationalDataLoaded &&
                            nationalData !== null &&
                            nationalData[nationalDataKey] &&
                            !nationalData[nationalDataKey].Student_Population && (
                                <BrowserOnly>
                                    <div
                                        style={{
                                            fontSize: '0.58em',
                                            color: 'red',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Data for this section cannot be calculated because a value for total students
                                        was not found
                                    </div>
                                </BrowserOnly>
                            )}
                    </div>
                    <div className={style.content}>
                        {!nationalDataLoaded ? (
                            <Skeleton height={73} />
                        ) : (
                            <div className={style.boxWrapper}>
                                <div className={style.box}>
                                    <div className={`${style.asthma}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Asthma</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().asthma || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.diabetes}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Diabetes</div>
                                        <div>
                                            Type 1: <span style={{ fontWeight: 'bold' }}>{getData().type1 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                        <div>
                                            Type 2: <span style={{ fontWeight: 'bold' }}>{getData().type2 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.allergy}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Life Threatening
                                            <br /> Allergy
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().lta || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.seizure}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Seizure Disorder</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().seizure || '?'}</span> /
                                            1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.mecfs}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>ME/CFS</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().mecfs || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
