import React, { useEffect } from 'react';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDeployment } from 'js/reducers/nasn.js';
import { useMetricspawnQuery, useDeploymentsQuery, useGetAssignmentProgress } from 'js/queries';
import OverviewReport from 'js/vendor/reports/reportTemplates/Overview';
import PrivateSchoolsTable from './PrivateSchoolsTable';

const appDefs = window.cccisd.appDefs;

const PrivateOverview = () => {
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);
    const metricspawnQuery = useMetricspawnQuery();
    const reportType = 'statePrivate';
    const deploymentsQuery = useDeploymentsQuery([`deployments ${reportType}`], reportType);
    const selectedDeployment = deploymentsQuery.data?.selectedDeployment;
    const assignmentProgressQuery = useGetAssignmentProgress(
        [`${selectedDeployment} statePrivate assignmentProgress`],
        selectedDeployment,
        'statePrivate'
    );
    const deploymentHandle = deploymentsQuery.data?.selectedDeployment;

    const queriesLoading =
        metricspawnQuery.isLoading || deploymentsQuery.isLoading || assignmentProgressQuery.isLoading;

    useEffect(() => {
        if (deploymentHandle) {
            dispatch(setSelectedDeployment(deploymentHandle));
        }
    }, [deploymentHandle]);

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;

        const anyOverview = reportList.find(r => r.reportTemplateHandle === 'overview');
        if (anyOverview) {
            return anyOverview.reportTemplateSettings;
        }
    };
    const reportSettings = getReportSettings();

    if (queriesLoading) {
        return <Loader loading />;
    }

    return (
        <>
            <div style={{ maxWidth: '870px' }}>
                {reportSettings ? (
                    <OverviewReport.player
                        settings={reportSettings}
                        reportGroupType="statePrivate"
                        tabSelected="privateOverview"
                        deploymentHandle={deploymentHandle}
                        deployments={deploymentsQuery.data}
                        overviewType="Private Schools"
                    />
                ) : (
                    <div>Could not find an overview report.</div>
                )}
            </div>
            {(!assignmentProgressQuery.data?.importData.stateEntersPrivateData ||
                assignmentProgressQuery.data?.importData.importData.privateSchool.importSchoolData) && (
                <div style={{ marginTop: '1em' }}>
                    <h3>My Private Schools</h3>
                    <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                        This table shows reports if schools entered their own data.
                    </span>
                    <PrivateSchoolsTable
                        reportSettings={reportSettings}
                        deploymentHandle={reduxStore.selectedDeployment}
                    />
                </div>
            )}
        </>
    );
};

export default PrivateOverview;
