import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import MyTable from './table.js';
import style from './style.css';
import fakeData from './fakeData.js';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);
    return (
        <>
            {props.settings.show && (
                <div style={{ marginBottom: '2em' }}>
                    <div className={style.wrapper}>
                        <div className={style.header}>
                            <div className={style.headerLeft}>
                                <div className={style.headerIcon} />
                                {props.settings.title || 'Models of Practice'}
                            </div>
                        </div>
                        <Html content={props.settings.additionalInfo} />
                        {!reduxStore.comparisonDataLoaded ? (
                            <Skeleton height={889} />
                        ) : (
                            <MyTable
                                data={reduxStore.comparisonData}
                                filters={props.filters}
                                fakeData={fakeData}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
