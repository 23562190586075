import React from 'react';
import OverviewReport from 'js/vendor/reports/reportTemplates/OverviewSingleGroup';

const PrivateSchoolReport = props => {
    const { row, reportSettings, metricspawns } = props;
    return (
        <div style={{ maxWidth: '870px' }}>
            {reportSettings ? (
                <OverviewReport.player
                    settings={reportSettings}
                    group={{
                        groupId: row['group.groupId'],
                        label: row['group.label'],
                        groupType: row['group.groupType'],
                    }}
                    groupType={row['group.groupType']}
                    metricspawns={metricspawns}
                    thisGroupReport
                />
            ) : (
                <div>Could not find an overview report.</div>
            )}
        </div>
    );
};

export default PrivateSchoolReport;
