import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import Table from 'cccisd-table';
import Loader from 'cccisd-loader';
import { useStatesQuery } from 'js/queries';
import IconCheck from 'cccisd-icons/checkmark';
import style from './style.css';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);
    const { nationalData, nationalDataLoaded } = reduxStore;
    const nationalDataKey = props.filters.schoolType; // 'national_All Schools', 'national_Public Schools'

    const statesQuery = useStatesQuery();

    const columns = [
        { name: 'stateName', label: 'State', sort: true },
        {
            name: 'reportingLevel',
            label: 'Reporting Level',
            tooltip: <div>Level at which data is being reported for public and private schools.</div>,
            sort: true,
        },
        { name: 'workforce', label: 'Workforce', class: 'text-center' },
        { name: 'cc', label: 'Chronic Conditions', class: 'text-center' },
        { name: 'absenteeism', label: 'Chronic Absenteeism', class: 'text-center' },
        { name: 'hov', label: 'Health Office Visits', class: 'text-center' },
    ];

    /*
     *   Return data for the table, eg:
     *   [{ stateName: 'Alaska', reportingLevel: 'State', workforce: <IconCheck />, cc: <IconCheck /> }, ...]
     */
    const getData = () => {
        if (nationalDataLoaded) {
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];
                let workforceStates = [];
                let workforcePrivate = [];
                let ccStates = [];
                let ccPrivate = [];
                let absenteeismStates = [];
                let absenteeismPrivate = [];
                let hovStates = [];
                let hovPrivate = [];

                if (data.Reported_Staffing_States) {
                    const staffing = JSON.parse(data.Reported_Staffing_States);
                    if (staffing.public) {
                        workforceStates = staffing.public;
                    } else {
                        workforceStates = staffing;
                    }

                    workforcePrivate = staffing.private ? staffing.private : [];
                }
                if (data.Reported_Chronic_Conditions_States) {
                    const cc = JSON.parse(data.Reported_Chronic_Conditions_States);
                    if (cc.public) {
                        ccStates = cc.public;
                    } else {
                        ccStates = cc;
                    }
                    ccPrivate = cc.private ? cc.private : [];
                }
                if (data.Reported_HOV_States) {
                    const hov = JSON.parse(data.Reported_HOV_States);
                    if (hov.public) {
                        hovStates = hov.public;
                    } else {
                        hovStates = hov;
                    }
                    hovPrivate = hov.private ? hov.private : [];
                }
                if (data.Reported_Absenteeism_States) {
                    const absenteeism = JSON.parse(data.Reported_Absenteeism_States);
                    if (absenteeism.public) {
                        absenteeismStates = absenteeism.public;
                    } else {
                        absenteeismStates = absenteeism;
                    }
                    absenteeismPrivate = absenteeism.private ? absenteeism.private : [];
                }

                let stateData = [];
                const transformData = (domainData, domain, schoolType) => {
                    Object.keys(domainData).forEach(w => {
                        let stateObj = stateData.find(s => s.stateName === w);
                        if (stateObj) {
                            stateObj[domain] = <IconCheck />;
                            if (schoolType === 'private' && !stateObj.existingReportingLevels.includes('private')) {
                                stateObj.reportingLevel.push(
                                    <p style={{ fontSize: 'smaller' }}>
                                        <span style={{ fontWeight: 'bold' }}>Private: </span>
                                        {domainData[w]}
                                    </p>
                                );
                                stateObj.existingReportingLevels.push('private');
                            }
                        } else if (schoolType === 'private') {
                            stateData.push({
                                stateName: w,
                                reportingLevel: [
                                    <p style={{ fontSize: 'smaller' }}>
                                        <span style={{ fontWeight: 'bold' }}>Private: </span>
                                        {domainData[w]}
                                    </p>,
                                ],
                                existingReportingLevels: ['private'],
                                [domain]: <IconCheck />,
                            });
                        } else {
                            stateData.push({
                                stateName: w,
                                reportingLevel: [
                                    <p style={{ fontSize: 'smaller' }}>
                                        <span style={{ fontWeight: 'bold' }}>Public: </span>
                                        {domainData[w]}
                                    </p>,
                                ],
                                existingReportingLevels: ['public'],
                                [domain]: <IconCheck />,
                            });
                        }
                    });
                };
                transformData(workforceStates, 'workforce');
                transformData(ccStates, 'cc');
                transformData(absenteeismStates, 'absenteeism');
                transformData(hovStates, 'hov');
                if (props.filters.schoolType === 'national_All Schools') {
                    transformData(workforcePrivate, 'workforce', 'private');
                    transformData(ccPrivate, 'cc', 'private');
                    transformData(absenteeismPrivate, 'absenteeism', 'private');
                    transformData(hovPrivate, 'hov', 'private');
                }
                return stateData;
            }
            return [];
        }
    };

    const tableData = getData();

    const isLoading = statesQuery.isLoading;
    const allQueryDataReturned = statesQuery.data;

    if (!isLoading && allQueryDataReturned) {
        <Loader loading />;
    }

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    {!nationalDataLoaded ? (
                        <Skeleton height={268} />
                    ) : (
                        <>
                            <Table
                                rowKey="stateName"
                                columns={columns}
                                data={tableData}
                                showPerPageOptions={false}
                                hideShowingRowsSummary
                            />
                        </>
                    )}
                    <div className={style.additionalInfo}>
                        <Html content={props.settings.additionalInfo} />
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
