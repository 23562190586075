import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'cccisd-react-query';
import Loader from 'cccisd-loader';
import OverviewReport from 'js/vendor/reports/reportTemplates/Overview';
import ComparisonReport from 'js/vendor/reports/reportTemplates/Comparison';
import NationalReport from 'js/vendor/reports/reportTemplates/NationalInfographic';
import IndividualESCReport from 'js/vendor/reports/reportTemplates/IndividualESC';
import Tabs from 'cccisd-tabs';
import { Html } from 'cccisd-wysiwyg';
import { getSavedParams } from 'js/reducers/params.js';
import reportFields from 'js/reducers/siteFields/reportFields.js';
import { useMetricspawnQuery, useDeploymentsQuery } from 'js/queries';

const appDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const Reports = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tabSelected, setTabSelected] = useState('privateOverview');
    const params = useSelector(state => state.app.params);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    /*
     *  Queries
     */
    const metricspawnQuery = useMetricspawnQuery();
    const deploymentsQuery = useDeploymentsQuery([`deployments privateSchool`], 'privateSchool');

    const deploymentHandle = deploymentsQuery.data?.selectedDeployment;

    const queriesLoading = metricspawnQuery.isLoading || deploymentsQuery.isLoading;

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (Object.keys(params.reportFields).length < 1) {
                await dispatch(getSavedParams(reportFields, 'report'));
            }
            setIsLoading(false);
        })();
        return () => queryClient.cancelQueries();
    }, []);

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;
        if (tabSelected === 'individualESC') {
            return reportList.find(r => r.reportTemplateHandle === 'individualESC').reportTemplateSettings;
        }
        if (tabSelected !== 'comparison') {
            const privateSchoolInfographic = reportList.find(r => r.id === 'PrivateSchoolInfographic');
            if (privateSchoolInfographic) {
                return privateSchoolInfographic.reportTemplateSettings;
            }
            const anyOverview = reportList.find(r => r.reportTemplateHandle === 'overview');
            if (anyOverview) {
                return anyOverview.reportTemplateSettings;
            }
        }
        const publicSchoolComparison = reportList.find(r => r.id === 'PublicSchoolComparison');
        if (publicSchoolComparison) {
            return publicSchoolComparison.reportTemplateSettings;
        }
        const anyComparison = reportList.find(r => r.reportTemplateHandle === 'comparison');
        if (anyComparison) {
            return anyComparison.reportTemplateSettings;
        }
    };

    const getNationalReportSettings = () => {
        const report = appDefs.reports.list.find(r => r.reportTemplateHandle === 'national');
        return report.reportTemplateSettings;
    };

    const reportSettings = getReportSettings();

    const getPageTitle = () => {
        if (Fortress.user.acting.group) {
            return Fortress.user.acting.group.label;
        }
        return 'My School Report';
    };

    if (isLoading) {
        return <Loader loading />;
    }

    const tabs = [
        {
            name: 'overview',
            title: 'Overview',
            content: (
                <>
                    {reportSettings ? (
                        <OverviewReport.player
                            settings={reportSettings}
                            reportGroupType="privateSchool"
                            deploymentHandle={deploymentHandle}
                            deployments={deploymentsQuery.data}
                        />
                    ) : (
                        <div>Could not find an overview report.</div>
                    )}
                </>
            ),
        },
        {
            name: 'comparison',
            title: 'Comparison',
            content: (
                <>
                    {reportSettings ? (
                        <>
                            <div style={{ marginBottom: '0.5em' }}>
                                <Html content={params.reportFields.privateSchoolComparisonReportDescription} />
                            </div>
                            <div style={{ maxWidth: '870px' }}>
                                <ComparisonReport.player settings={reportSettings} />
                            </div>
                        </>
                    ) : (
                        <div>Could not find a comparison report.</div>
                    )}
                </>
            ),
        },
        {
            name: 'national',
            title: 'National Overview',
            content: (
                <div style={{ maxWidth: '870px' }}>
                    {reportSettings ? (
                        <NationalReport.player settings={getNationalReportSettings()} />
                    ) : (
                        <div>Could not find an overview report.</div>
                    )}
                </div>
            ),
        },
    ];

    if (!metricspawnQuery.isLoading && metricspawnQuery.data.enableSurveillance === true) {
        tabs.splice(1, 0, {
            name: 'individualESC',
            title: 'Surveillance ESC',
            content: (
                <>
                    {reportSettings ? (
                        <IndividualESCReport.player settings={reportSettings} />
                    ) : (
                        <div>Could not find an overview report.</div>
                    )}
                </>
            ),
        });
    }

    if (queriesLoading) {
        return <Loader />;
    }

    return (
        <div style={{ maxWidth: '870px' }}>
            <h3>{getPageTitle()}</h3>
            <Tabs
                onTabSelect={tabName => {
                    setTabSelected(tabName);
                    queryClient.cancelQueries();
                }}
                tabList={tabs}
            />
        </div>
    );
};

export default Reports;
