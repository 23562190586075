import React from 'react';
import PropTypes from 'prop-types';
import { BrowserOnly } from 'cccisd-laravel-report';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import { gql } from 'cccisd-apollo';
import { useGetOverviewReportData, useGetAssignmentProgress, useGetReportMetricsPawnsQuery } from 'js/queries';
import { getWidgetData } from '../../helpers.js';
import style from './style.css';

import publicSchoolQuery from './publicSchoolAbsenteeism.graphql';
import privateSchoolQuery from './privateSchoolAbsenteeism.graphql';
import districtQuery from './districtAbsenteeism.graphql';
import stateQuery from './stateAbsenteeism.graphql';
import statePrivateQuery from './statePrivateSchoolAbsenteeism.graphql';
import absenteeismVariables from './absenteeismVariables.js';
import _round from 'lodash/round';

const Absenteeism = props => {
    const deploymentHandle = props.filters.schoolYear.slice(0, 9) + `_${props.widgetProps.reportGroupType}`;

    const assignmentProgressQuery = useGetAssignmentProgress(
        [`${props.filters.schoolYear} ${props.widgetProps.reportGroupType} assignmentProgress`],
        props.filters.schoolYear,
        props.widgetProps.reportGroupType
    );
    const reportGroupType = assignmentProgressQuery?.data?.reportGroupType;

    const reportMetricsPawnsQuery = useGetReportMetricsPawnsQuery(
        [`overview metricspawns ${props.filters.schoolYear} ${props.widgetProps.reportGroupType}`],
        reportGroupType,
        props.filters.schoolYear
    );

    const getQueryType = () => {
        let query = stateQuery;
        const formattedDistrictQuery = gql`
            ${districtQuery}
        `;
        const formattedSchoolQuery = gql`
            ${publicSchoolQuery}
        `;
        let variables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_state',
            absenteeism: absenteeismVariables.state,
        };
        const districtVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.districtIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_district',
            absenteeism: absenteeismVariables.district,
        };
        const schoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.schoolIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_publicSchool',
            absenteeism: absenteeismVariables.publicSchool,
        };
        const statePrivateVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_statePrivate',
            workforce: absenteeismVariables.statePrivate,
        };
        const privateSchoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_privateSchool',
            workforce: absenteeismVariables.privateSchool,
        };

        if (reportGroupType === 'districtsAndSchools') {
            return [
                { query: formattedDistrictQuery, variables: districtVariables },
                { query: formattedSchoolQuery, variables: schoolVariables },
            ];
        }
        if (reportGroupType === 'district') {
            query = districtQuery;
            variables = districtVariables;
        }
        if (reportGroupType === 'publicSchool') {
            query = publicSchoolQuery;
            variables = schoolVariables;
        }
        if (reportGroupType === 'statePrivate') {
            query = statePrivateQuery;
            variables = statePrivateVariables;
        }
        if (reportGroupType === 'privateSchool') {
            query = privateSchoolQuery;
            variables = privateSchoolVariables;
        }

        return [{ query, variables }];
    };

    const overviewReportQuery = useGetOverviewReportData('absenteeism', reportGroupType, getQueryType());

    const queriesLoading =
        overviewReportQuery.some(q => q.isFetching) ||
        reportMetricsPawnsQuery.isFetching ||
        assignmentProgressQuery.isLoading;

    const getData = () => {
        if (!overviewReportQuery.some(q => q.isLoading)) {
            const dataReturned = overviewReportQuery.some(q => q.data);
            if (dataReturned) {
                const data = getWidgetData(overviewReportQuery);
                let totalPerMonthAbsent = data.data.roles.metricspawnSummary.totalabsent;
                let totalAnnAbsent = data.data.roles.metricspawnSummary.totalAnnAbsent;
                const totalAbsent = totalPerMonthAbsent + totalAnnAbsent;
                const totalStudents = data.data.roles.metricspawnSummary.totalstudents;
                const per1000 = (totalAbsent / totalStudents) * 1000;

                if (!totalStudents) {
                    return {
                        absenteeism: '?',
                    };
                }
                return { absenteeism: _round(per1000, 2) || '?' };
            }
        }
        return { absenteeism: '?' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Absenteeism'}
                        </div>
                        {!overviewReportQuery.some(q => q.isFetching) &&
                            overviewReportQuery.data &&
                            !overviewReportQuery.data.data.roles.metricspawnSummary.totalstudents && (
                                <BrowserOnly>
                                    <div
                                        style={{
                                            fontSize: '0.58em',
                                            color: 'red',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Data for this section cannot be calculated without entering total student
                                        population.
                                    </div>
                                </BrowserOnly>
                            )}
                    </div>

                    <div className={style.content}>
                        {queriesLoading ? (
                            <Skeleton height={84} />
                        ) : (
                            <div className={style.box}>
                                <div className={style.boxIcon} />
                                <div className={style.boxText}>
                                    <div style={{ fontWeight: 'bold' }}>Students chronically absent per month</div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{getData().absenteeism}</span> / 1000
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Absenteeism.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Absenteeism.defaultProps = {
    filters: {},
};

export default Absenteeism;
