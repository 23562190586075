import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import MyTable from './table.js';
import fakeData from '../fakeData.js';
import style from './style.css';

const Component = props => {
    return (
        <div style={{ marginBottom: '2em' }}>
            <div className={style.wrapper}>
                <div className={style.header}>
                    <div className={style.headerLeft}>
                        <div className={style.headerIcon} />
                        Workforce
                    </div>
                </div>
                {props.loading ? (
                    <Skeleton height={804} />
                ) : (
                    <MyTable
                        data={props.data}
                        fakeData={fakeData}
                        groupLabels={props.groupLabels}
                    />
                )}
            </div>
        </div>
    );
};

Component.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    groupLabels: PropTypes.array,
};

export default Component;
