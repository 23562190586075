import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'cccisd-react-query';
import Tabs from 'cccisd-tabs';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import ComparisonReport from 'js/vendor/reports/reportTemplates/Comparison';
import NationalReport from 'js/vendor/reports/reportTemplates/NationalInfographic';
import StateComparisonTable from './StateComparisonTable';
import ComponentWrapper from 'js/components/ComponentWrapper';
import { getDeployments } from 'js/reducers/nasn.js';
import { getSavedParams } from 'js/reducers/params.js';
import reportFields from 'js/reducers/siteFields/reportFields.js';
import { useMetricspawnQuery, useDeploymentsQuery } from 'js/queries';
import StateOverview from './StateOverview.js';
import PrivateOverview from './PrivateOverview.js';

const Fortress = window.cccisd.fortress;
const appDefs = window.cccisd.appDefs;

const StateReports = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tabSelected, setTabSelected] = useState('privateOverview');
    const [collapsed, setCollapsed] = useState(['2']);
    const reduxStore = useSelector(state => state.app.nasn);
    const params = useSelector(state => state.app.params);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const reportType = tabSelected === 'privateOverview' ? 'statePrivate' : 'state';

    /*
     *  Queries
     */
    const metricspawnQuery = useMetricspawnQuery();
    const deploymentsQuery = useDeploymentsQuery([`deployments ${reportType}`], reportType);
    // const deploymentHandle = deploymentsQuery.data?.selectedDeployment;
    const queriesLoading = metricspawnQuery.isLoading || deploymentsQuery.isLoading;

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (Object.keys(params.reportFields).length < 1) {
                await dispatch(getSavedParams(reportFields, 'report'));
            }
            dispatch(getDeployments('state'));
            setIsLoading(false);
        })();
        return () => queryClient.cancelQueries();
    }, []);

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;
        if (tabSelected !== 'comparison') {
            const stateInfographic = reportList.find(r => r.id === 'StateInfographic');
            if (stateInfographic) {
                return stateInfographic.reportTemplateSettings;
            }
            const anyOverview = reportList.find(r => r.reportTemplateHandle === 'overview');
            if (anyOverview) {
                return anyOverview.reportTemplateSettings;
            }
        }
        const stateComparison = reportList.find(r => r.id === 'StateComparison');
        if (stateComparison) {
            return stateComparison.reportTemplateSettings;
        }
        const anyComparison = reportList.find(r => r.reportTemplateHandle === 'comparison');
        if (anyComparison) {
            return anyComparison.reportTemplateSettings;
        }
    };

    const getNationalReportSettings = () => {
        const report = appDefs.reports.list.find(r => r.reportTemplateHandle === 'national');
        return report.reportTemplateSettings;
    };

    const reportSettings = getReportSettings();

    const getPageTitle = () => {
        if (Fortress.user.acting.group) {
            if (Fortress.user.acting.group.data_type === 'state') {
                const stateName = Fortress.user.acting.group.type_data.stateName;
                return stateName;
            }
            return Fortress.user.acting.group.label;
        }
        return 'Individual Account';
    };

    if (queriesLoading || isLoading) {
        return <Loader loading />;
    }

    return (
        <div>
            <h3>{getPageTitle()}</h3>
            <Tabs
                onTabSelect={tabName => {
                    setTabSelected(tabName);
                    queryClient.cancelQueries();
                }}
                tabList={[
                    {
                        name: 'overview',
                        title: 'Public School Overview',
                        content: <StateOverview tabSelected="overview" />,
                    },
                    {
                        name: 'privateOverview',
                        title: 'Private School Overview',
                        content: <PrivateOverview />,
                    },
                    {
                        name: 'comparison',
                        title: 'Comparison',
                        content: (
                            <>
                                {reportSettings ? (
                                    <>
                                        <div style={{ marginBottom: '0.5em' }}>
                                            <Html content={params.reportFields.stateComparisonReportDescription} />
                                        </div>
                                        <ComponentWrapper
                                            title="Compare using data sets"
                                            id="1"
                                            collapsed={collapsed}
                                            setCollapsed={setCollapsed}
                                        >
                                            <div style={{ maxWidth: '870px' }}>
                                                <ComparisonReport.player settings={reportSettings} />
                                            </div>
                                        </ComponentWrapper>
                                        <ComponentWrapper
                                            title="Compare with groups in my state"
                                            id="2"
                                            collapsed={collapsed}
                                            setCollapsed={setCollapsed}
                                        >
                                            <StateComparisonTable
                                                deploymentHandle={reduxStore.selectedDeployment}
                                                deployments={reduxStore.deploymentsInfo}
                                            />
                                        </ComponentWrapper>
                                    </>
                                ) : (
                                    <div>Could not find a comparison report.</div>
                                )}
                            </>
                        ),
                    },
                    {
                        name: 'national',
                        title: 'National Overview',
                        content: (
                            <div style={{ maxWidth: '870px' }}>
                                {reportSettings ? (
                                    <NationalReport.player settings={getNationalReportSettings()} />
                                ) : (
                                    <div>Could not find an overview report.</div>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default StateReports;
