export default {
    publicSchool: {
        asthma: [
            'PUB_SCH_ASTHMA_TOTAL',
            'PUB_SCH_AGG_ASTHMA',
            'PUB_SCH_K3_ASTHMA',
            'PUB_SCH_K4_ASTHMA',
            'PUB_SCH_K_ASTHMA',
            'PUB_SCH_1_ASTHMA',
            'PUB_SCH_2_ASTHMA',
            'PUB_SCH_3_ASTHMA',
            'PUB_SCH_4_ASTHMA',
            'PUB_SCH_5_ASTHMA',
            'PUB_SCH_6_ASTHMA',
            'PUB_SCH_7_ASTHMA',
            'PUB_SCH_8_ASTHMA',
            'PUB_SCH_9_ASTHMA',
            'PUB_SCH_10_ASTHMA',
            'PUB_SCH_11_ASTHMA',
            'PUB_SCH_12_ASTHMA',
        ],
        type1: [
            'PUB_SCH_TYPE1_TOTAL',
            'PUB_SCH_AGG_TYPE1',
            'PUB_SCH_K3_TYPE1',
            'PUB_SCH_K4_TYPE1',
            'PUB_SCH_K_TYPE1',
            'PUB_SCH_1_TYPE1',
            'PUB_SCH_2_TYPE1',
            'PUB_SCH_3_TYPE1',
            'PUB_SCH_4_TYPE1',
            'PUB_SCH_5_TYPE1',
            'PUB_SCH_6_TYPE1',
            'PUB_SCH_7_TYPE1',
            'PUB_SCH_8_TYPE1',
            'PUB_SCH_9_TYPE1',
            'PUB_SCH_10_TYPE1',
            'PUB_SCH_11_TYPE1',
            'PUB_SCH_12_TYPE1',
        ],
        type2: [
            'PUB_SCH_TYPE2_TOTAL',
            'PUB_SCH_AGG_TYPE2',
            'PUB_SCH_K3_TYPE2',
            'PUB_SCH_K4_TYPE2',
            'PUB_SCH_K_TYPE2',
            'PUB_SCH_1_TYPE2',
            'PUB_SCH_2_TYPE2',
            'PUB_SCH_3_TYPE2',
            'PUB_SCH_4_TYPE2',
            'PUB_SCH_5_TYPE2',
            'PUB_SCH_6_TYPE2',
            'PUB_SCH_7_TYPE2',
            'PUB_SCH_8_TYPE2',
            'PUB_SCH_9_TYPE2',
            'PUB_SCH_10_TYPE2',
            'PUB_SCH_11_TYPE2',
            'PUB_SCH_12_TYPE2',
        ],
        seizure: [
            'PUB_SCH_SEIZURE_TOTAL',
            'PUB_SCH_AGG_SEIZURE',
            'PUB_SCH_K3_SEIZURE',
            'PUB_SCH_K4_SEIZURE',
            'PUB_SCH_K_SEIZURE',
            'PUB_SCH_1_SEIZURE',
            'PUB_SCH_2_SEIZURE',
            'PUB_SCH_3_SEIZURE',
            'PUB_SCH_4_SEIZURE',
            'PUB_SCH_5_SEIZURE',
            'PUB_SCH_6_SEIZURE',
            'PUB_SCH_7_SEIZURE',
            'PUB_SCH_8_SEIZURE',
            'PUB_SCH_9_SEIZURE',
            'PUB_SCH_10_SEIZURE',
            'PUB_SCH_11_SEIZURE',
            'PUB_SCH_12_SEIZURE',
        ],
        lta: [
            'PUB_SCH_LT_ALLERGY_TOTAL',
            'PUB_SCH_AGG_LT_ALLERGY',
            'PUB_SCH_K3_LT_ALLERGY',
            'PUB_SCH_K4_LT_ALLERGY',
            'PUB_SCH_K_LT_ALLERGY',
            'PUB_SCH_1_LT_ALLERGY',
            'PUB_SCH_2_LT_ALLERGY',
            'PUB_SCH_3_LT_ALLERGY',
            'PUB_SCH_4_LT_ALLERGY',
            'PUB_SCH_5_LT_ALLERGY',
            'PUB_SCH_6_LT_ALLERGY',
            'PUB_SCH_7_LT_ALLERGY',
            'PUB_SCH_8_LT_ALLERGY',
            'PUB_SCH_9_LT_ALLERGY',
            'PUB_SCH_10_LT_ALLERGY',
            'PUB_SCH_11_LT_ALLERGY',
            'PUB_SCH_12_LT_ALLERGY',
        ],
        mecfs: ['PUB_SCH_MECFS_TOTAL'],
    },
};
