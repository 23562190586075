import { useQuery, useQueries } from 'cccisd-react-query';
import { client as apollo, taskMasterClient } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import {
    selectMetricspawn,
    selectDeploymentInfo,
    selectPublicSchoolAssignmentProgress,
    selectDistrictAssignmentProgress,
    selectStateAssignmentProgress,
    selectStatePrivateAssignmentProgress,
    selectReportMetrics,
    selectPrivateSchoolAssignmentProgress,
} from './selectors';

import statesQuery from './states.graphql';
import metricsPawnQuery from './getMetricsPawn.graphql';
import deploymentsQuery from './deploymentsQuery.graphql';
import publicSchoolAssignmentProgressQuery from './publicSchoolAssignmentProgress.graphql';
import districtAssignmentProgressQuery from './districtAssignmentProgress.graphql';
import stateAssignmentProgressQuery from './stateAssignmentProgress.graphql';
import privateSchoolAssignmentProgressQuery from './privateSchoolAssignmentProgress.graphql';
import statePrivateAssignmentProgressQuery from './statePrivateAssignmentProgress.graphql';
import publicSchoolsMetricsPawnQuery from 'js/pages/Reports/State/publicSchoolMetrics.graphql';
import privateSchoolsMetricsPawnQuery from 'js/pages/Reports/State/privateSchoolMetrics.graphql';
import districtsMetricsPawnQuery from 'js/pages/Reports/State/districtMetrics.graphql';
import stateMetricsPawnQuery from 'js/pages/Reports/State/stateMetrics.graphql';

const Boilerplate = window.cccisd.boilerplate;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *   The order of the queries in this file should be
 *   roughly the order of the query waterfall in the app.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const _setAdditionalInfo = async (pawn, dId, additionalInfo) => {
    await axios.post(Boilerplate.route('api.assignmentProgress.update'), {
        deploymentId: dId,
        pawnId: pawn.pawnId,
        flowlist: 'default',
        additional_info: additionalInfo,
    });
};

const fetchStates = async () => {
    const response = await apollo.query({
        query: statesQuery,
        fetchPolicy: 'network-only',
    });
    return response;
};

// Metricspawn for current group
const fetchMetricsPawn = async () => {
    const result = await apollo.query({
        query: metricsPawnQuery,
        fetchPolicy: 'network-only',
    });
    return result;
};

// To populate the schoolYear dropdowns on data entry pages and reports
const fetchDeployments = async assignmentHandle => {
    const result = await apollo.query({
        query: deploymentsQuery,
        fetchPolicy: 'network-only',
        variables: {
            assignmentHandle,
        },
    });
    return result;
};

// Checks own group + child groups + parent group for assignment progress,
//  and to determine at which level to query report data.
const fetchAssignmentProgress = async (query, variables) => {
    const result = await apollo.query({
        query,
        fetchPolicy: 'network-only',
        variables,
    });

    return result;
};

// Get metricspawns to be used to query report data
const fetchReportMetricsPawns = async (reportGroupType, selectedDeployment) => {
    let result = null;
    let districtsResult = null;
    let schoolsResult = null;
    if (reportGroupType === 'state' || reportGroupType === 'statePrivate') {
        result = await fetchStateMetricsPawn();
    }

    if (reportGroupType === 'districtsAndSchools') {
        const districtDeploymentHandle = selectedDeployment.slice(0, 9) + '_district';
        const schoolDeploymentHandle = selectedDeployment.slice(0, 9) + '_publicSchool';
        districtsResult = await fetchDistrictsMetricsPawns(districtDeploymentHandle);
        schoolsResult = await fetchPublicSchoolsMetricsPawns(schoolDeploymentHandle);
    }
    if (reportGroupType === 'district') {
        result = await fetchDistrictsMetricsPawns(selectedDeployment);
    }
    if (reportGroupType === 'publicSchool') {
        result = await fetchPublicSchoolsMetricsPawns(selectedDeployment);
    }

    if (reportGroupType === 'privateSchool') {
        result = await fetchPrivateSchoolsMetricsPawns(selectedDeployment);
    }

    return { result, districtsResult, schoolsResult };
};

// Metricspawn for state associated with current group
const fetchStateMetricsPawn = async () => {
    const result = await apollo.query({
        query: stateMetricsPawnQuery,
        fetchPolicy: 'network-only',
    });
    return result;
};

// Metricspawn for districts associated with current group that have assignment progress
const fetchDistrictsMetricsPawns = async selectedDeployment => {
    const districtDeploymentHandle = selectedDeployment.slice(0, 9) + '_district';
    const result = await apollo.query({
        query: districtsMetricsPawnQuery,
        fetchPolicy: 'network-only',
        variables: {
            deploymentHandle: districtDeploymentHandle,
        },
    });
    return result;
};

// Metricspawn for public schools associated with current group that have assignment progress
const fetchPublicSchoolsMetricsPawns = async selectedDeployment => {
    const schoolDeploymentHandle = selectedDeployment.slice(0, 9) + '_publicSchool';
    const result = await apollo.query({
        query: publicSchoolsMetricsPawnQuery,
        fetchPolicy: 'network-only',
        variables: {
            deploymentHandle: schoolDeploymentHandle,
        },
    });
    return result;
};

const fetchPrivateSchoolsMetricsPawns = async selectedDeployment => {
    const schoolDeploymentHandle = selectedDeployment.slice(0, 9) + '_privateSchool';

    const result = await apollo.query({
        query: privateSchoolsMetricsPawnQuery,
        fetchPolicy: 'network-only',
        variables: {
            deploymentHandle: schoolDeploymentHandle,
        },
    });

    return result;
};

const fetchOverviewWidgetData = async (query, variables) => {
    let result;

    if (variables.metricspawns.length < 1) {
        return null;
    }

    result = await taskMasterClient.query({
        query,
        variables,
        fetchPolicy: 'network-only',
    });
    return result;
};

/* **************
 *   Query Hooks
 ***************** */

export const useStatesQuery = (key = ['states'], select, notifyOnChangeProps) =>
    useQuery(key, fetchStates, {
        refetchOnWindowFocus: false,
    });

export const useMetricspawnQuery = (key = ['metricspawn'], notifyOnChangeProps) =>
    useQuery(key, fetchMetricsPawn, {
        refetchOnWindowFocus: false,
        select: data => selectMetricspawn(data),
    });

export const useDeploymentsQuery = (key = ['deployments'], assignmentHandle) =>
    useQuery(key, () => fetchDeployments(assignmentHandle), {
        refetchOnWindowFocus: false,
        select: data => selectDeploymentInfo(data),
    });

export const useGetAssignmentProgress = (
    key = ['assignmentProgress'],
    deploymentHandle,
    reportGroupType,
    checkAdditionalInfo
) => {
    if (reportGroupType === 'state') {
        let variables = {};
        if (deploymentHandle) {
            const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_state';
            variables = { deploymentHandle, stateDeploymentHandle };
        }

        return useQuery(key, () => fetchAssignmentProgress(stateAssignmentProgressQuery, variables), {
            enabled: !!deploymentHandle && !!reportGroupType,
            refetchOnWindowFocus: false,
            select: data => selectStateAssignmentProgress(data),
            onSuccess: async data => {
                if (checkAdditionalInfo && data.importData.stateEntersPublicData === null) {
                    _setAdditionalInfo(data.stateMetricsPawn.pawn, data.deploymentId, {
                        stateEntersPublicData: false,
                        importData: {
                            publicSchool: {
                                importStateData: false,
                                importDistrictData: false,
                                importSchoolData: false,
                            },
                        },
                    });
                }
            },
        });
    }
    if (reportGroupType === 'district') {
        let variables = {};
        if (deploymentHandle) {
            const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_state';
            variables = { deploymentHandle, stateDeploymentHandle };
        }

        return useQuery(key, () => fetchAssignmentProgress(districtAssignmentProgressQuery, variables), {
            enabled: !!deploymentHandle && !!reportGroupType,
            refetchOnWindowFocus: false,
            select: data => selectDistrictAssignmentProgress(data),
            onSuccess: async data => {
                if (checkAdditionalInfo && data.importData.districtEntersData === null) {
                    _setAdditionalInfo(data.districtMetricsPawn.pawn, data.deploymentId, {
                        districtEntersData: false,
                        importData: { importDistrictData: false, importSchoolData: false },
                    });
                }
            },
        });
    }

    if (reportGroupType === 'publicSchool') {
        let variables = {};
        if (deploymentHandle) {
            const districtDeploymentHandle = deploymentHandle.slice(0, 9) + '_district';
            const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_state';
            variables = { deploymentHandle, districtDeploymentHandle, stateDeploymentHandle };
        }

        return useQuery(key, () => fetchAssignmentProgress(publicSchoolAssignmentProgressQuery, variables), {
            enabled: !!deploymentHandle && !!reportGroupType,
            refetchOnWindowFocus: false,
            select: data => selectPublicSchoolAssignmentProgress(data),
            onSuccess: async data => {
                if (checkAdditionalInfo && data.importData.publicSchoolImportData === null) {
                    _setAdditionalInfo(data.publicSchoolMetricsPawn.pawn, data.deploymentId, {
                        publicSchoolImportData: false,
                    });
                }
            },
        });
    }

    if (reportGroupType === 'statePrivate') {
        const variables = { deploymentHandle };

        return useQuery(key, () => fetchAssignmentProgress(statePrivateAssignmentProgressQuery, variables), {
            enabled: !!deploymentHandle && !!reportGroupType,
            refetchOnWindowFocus: false,
            select: data => selectStatePrivateAssignmentProgress(data),
            onSuccess: async data => {
                if (checkAdditionalInfo && data.importData.stateEntersPrivateData === null) {
                    _setAdditionalInfo(data.stateMetricsPawn.pawn, data.deploymentId, {
                        stateEntersPrivateData: false,
                        importData: {
                            privateSchool: {
                                importStateData: false,
                                importSchoolData: false,
                            },
                        },
                    });
                }
            },
        });
    }

    if (reportGroupType === 'privateSchool') {
        let variables = {};
        if (deploymentHandle) {
            const schoolDeploymentHandle = deploymentHandle.slice(0, 9) + '_privateSchool';
            const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_statePrivate';
            variables = { deploymentHandle: schoolDeploymentHandle, stateDeploymentHandle };
        }

        return useQuery(key, () => fetchAssignmentProgress(privateSchoolAssignmentProgressQuery, variables), {
            enabled: !!deploymentHandle && !!reportGroupType,
            refetchOnWindowFocus: false,
            select: data => selectPrivateSchoolAssignmentProgress(data),
            onSuccess: async data => {
                if (checkAdditionalInfo && data.importData?.privateSchoolImportData === null) {
                    _setAdditionalInfo(data.schoolMetricsPawn.pawn, data.deploymentId, {
                        privateSchoolImportData: false,
                    });
                }
            },
        });
    }
};

export const useGetReportMetricsPawnsQuery = (
    key = ['overview report metricspawn ids'],
    reportGroupType,
    selectedDeployment
) => {
    return useQuery(key, () => fetchReportMetricsPawns(reportGroupType, selectedDeployment), {
        enabled: !!reportGroupType && !!selectedDeployment,
        refetchOnWindowFocus: false,
        select: data => selectReportMetrics(data, reportGroupType),
    });
};

export const useGetOverviewReportData = (widgetHandle, reportGroupType, queryParams) => {
    const queries = queryParams.map(qp => ({
        queryKey: [
            `${widgetHandle} metrics data | ${qp.variables.deploymentHandle} | metricspawns: ${qp.variables.metricspawns}`,
        ],
        queryFn: () => fetchOverviewWidgetData(qp.query, qp.variables, reportGroupType),
        enabled: !!qp.variables.metricspawns,
        refetchOnWindowFocus: false,
    }));

    return useQueries({ queries });
};
