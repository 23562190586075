import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import { BrowserOnly } from 'cccisd-laravel-report';
import { gql } from 'cccisd-apollo';
import { useGetOverviewReportData, useGetAssignmentProgress, useGetReportMetricsPawnsQuery } from 'js/queries';
import { getWidgetData } from '../../helpers.js';
import style from './style.css';

import publicSchoolQuery from './pubSchChronicConditions.graphql';
import privateSchoolQuery from './privSchChronicConditions.graphql';
import districtQuery from './districtChronicConditions.graphql';
import stateQuery from './stateChronicConditions.graphql';
import statePrivateQuery from './statePrivateChronicConditions.graphql';

import ccVariables from './ccVariables.js';
import _round from 'lodash/round';

const ChronicConditions = props => {
    const deploymentHandle = props.filters.schoolYear.slice(0, 9) + `_${props.widgetProps.reportGroupType}`;

    const assignmentProgressQuery = useGetAssignmentProgress(
        [`${props.filters.schoolYear} ${props.widgetProps.reportGroupType} assignmentProgress`],
        props.filters.schoolYear,
        props.widgetProps.reportGroupType
    );
    const reportGroupType = assignmentProgressQuery?.data?.reportGroupType;

    const reportMetricsPawnsQuery = useGetReportMetricsPawnsQuery(
        [`overview metricspawns ${props.filters.schoolYear} ${props.widgetProps.reportGroupType}`],
        reportGroupType,
        props.filters.schoolYear
    );

    const getQueryType = () => {
        let query = stateQuery;
        const formattedDistrictQuery = gql`
            ${districtQuery}
        `;
        const formattedSchoolQuery = gql`
            ${publicSchoolQuery}
        `;
        let variables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_state',
            ...ccVariables.state,
        };
        const districtVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.districtIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_district',
            ...ccVariables.district,
        };
        const schoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.schoolIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_publicSchool',
            ...ccVariables.publicSchool,
        };
        const statePrivateVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            workforce: ccVariables.statePrivate,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_statePrivate',
        };
        const privateSchoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            workforce: ccVariables.privateSchool,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_privateSchool',
        };

        if (reportGroupType === 'districtsAndSchools') {
            return [
                { query: formattedDistrictQuery, variables: districtVariables },
                { query: formattedSchoolQuery, variables: schoolVariables },
            ];
        }
        if (reportGroupType === 'district') {
            query = districtQuery;
            variables = districtVariables;
        }
        if (reportGroupType === 'publicSchool') {
            query = publicSchoolQuery;
            variables = schoolVariables;
        }
        if (reportGroupType === 'statePrivate') {
            query = statePrivateQuery;
            variables = statePrivateVariables;
        }
        if (reportGroupType === 'privateSchool') {
            query = privateSchoolQuery;
            variables = privateSchoolVariables;
        }

        return [{ query, variables }];
    };

    const overviewReportQuery = useGetOverviewReportData('cc', reportGroupType, getQueryType());

    const queriesLoading =
        overviewReportQuery.some(q => q.isFetching) ||
        reportMetricsPawnsQuery.isFetching ||
        assignmentProgressQuery.isLoading;

    const getData = () => {
        if (!overviewReportQuery.some(q => q.isLoading)) {
            const dataReturned = overviewReportQuery.some(q => q.data);
            if (dataReturned) {
                const data = getWidgetData(overviewReportQuery);
                const mps = data.data.roles.metricspawnSummary;
                const totalStudents = mps.totalstudents;
                const totalAsthma = mps.totalasthma;
                const totalType1 = mps.totaltype1;
                const totalType2 = mps.totaltype2;
                const totalSeizure = mps.totalseizure;
                const totalLta = mps.totallta;
                const totalMecfs = mps.totalmecfs;
                const per1000 = x => _round((x / totalStudents) * 1000, 2);

                if (!totalStudents) {
                    return {
                        asthma: '?',
                        type1: '?',
                        type2: '?',
                        lta: '?',
                        seizure: '?',
                        mecfs: '?',
                    };
                }

                return {
                    asthma: per1000(totalAsthma),
                    type1: per1000(totalType1),
                    type2: per1000(totalType2),
                    lta: per1000(totalLta),
                    seizure: per1000(totalSeizure),
                    mecfs: per1000(totalMecfs),
                };
            }
        }
        return { asthma: '', type1: '', type2: '', lta: '', seizure: '', mecfs: '' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Chronic Conditions'}
                        </div>
                        {!overviewReportQuery.some(q => q.isFetching) &&
                            overviewReportQuery.data &&
                            !overviewReportQuery.data.data.roles.metricspawnSummary.totalstudents && (
                                <BrowserOnly>
                                    <div
                                        style={{
                                            fontSize: '0.58em',
                                            color: 'red',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Data for this section cannot be calculated without entering total student
                                        population.
                                    </div>
                                </BrowserOnly>
                            )}
                    </div>
                    <div className={style.content}>
                        {queriesLoading ? (
                            <Skeleton height={73} />
                        ) : (
                            <div className={style.boxWrapper}>
                                <div className={style.box}>
                                    <div className={`${style.asthma}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Asthma</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().asthma || 0}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.diabetes}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Diabetes</div>
                                        <div>
                                            Type 1: <span style={{ fontWeight: 'bold' }}>{getData().type1 || 0}</span> /
                                            1000
                                        </div>
                                        <div>
                                            Type 2: <span style={{ fontWeight: 'bold' }}>{getData().type2 || 0}</span> /
                                            1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.allergy}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Life Threatening
                                            <br /> Allergy
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().lta || 0}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.seizure}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Seizure Disorder</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().seizure || 0}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.mecfs}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>ME/CFS</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().mecfs || 0}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

ChronicConditions.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

ChronicConditions.defaultProps = {
    filters: {},
};

export default ChronicConditions;
