import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import widgets from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import filterFields from '../filterFields.js';
import { useIsFetching } from 'cccisd-react-query';

const Component = props => {
    const { workforce, chronicConditions, absenteeism, healthOfficeVisits } = props.widgets;
    const isFetching = useIsFetching();

    return (
        <Report
            downloadFilename="ESC_Individual_Report"
            disableDownload={!!isFetching}
            disableDownloadMessage="Loading"
        >
            <Page>
                <ReportHeader
                    settings={props.settings}
                    filters={props.filters}
                    isPreview={props.isPreview}
                    reportDataLoading={props.reportDataLoading}
                />
                {workforce}
                {chronicConditions}
                {absenteeism}
                {healthOfficeVisits}
                <ReportFooter />
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    options: PropTypes.array, // Deployment handles for school year filter
    schoolYear: PropTypes.string,
    reportDataLoading: PropTypes.bool,
};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: {
                reportDataLoading: props.reportDataLoading,
            },
        })),
    getFilterFields: props =>
        filterFields.map(field => {
            if (field.name === 'schoolYear') {
                return {
                    ...field,
                    options: props.options,
                    initialValue: props.options[props.options.length - 1]
                        ? props.options[props.options.length - 1].value
                        : '',
                };
            }
            return field;
        }),
})(Component);
