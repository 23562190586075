import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const PieExample = props => {
    const data = [
        {
            id: 'javascript',
            label: 'javascript',
            value: 42,
            color: 'hsl(18, 70%, 50%)',
        },
        {
            id: 'php',
            label: 'php',
            value: 35,
            color: 'hsl(33, 70%, 50%)',
        },
        {
            id: 'sass',
            label: 'sass',
            value: 23,
            color: 'hsl(4, 70%, 50%)',
        },
    ];
    return (
        <div style={{ width: '220px', height: '200px' }}>
            {props.data.some(x => x.value) && (
                <ResponsivePie
                    data={props.data || data}
                    colors={{ scheme: 'pastel2' }}
                    margin={{
                        top: 26,
                        right: 26,
                        bottom: 26,
                        left: 26,
                    }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    enableArcLabels={false}
                    arcLinkLabel={v => `${v.formattedValue}%`}
                    labelFormat={d => <tspan x={(d + 5) * ((300 - 160) / 100)}>{`${d}%`}</tspan>}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    arcLinkLabelsStraightLength={2}
                    arcLinkLabelsDiagonalLength={2}
                    animate
                    motionStiffness={90}
                    motionDamping={15}
                    {...props}
                />
            )}
        </div>
    );
};

export default PieExample;
