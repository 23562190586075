const Fortress = window.cccisd.fortress;
const currentUserGroup = Fortress.user.acting.group ? Fortress.user.acting.group.data_type : '';

export const selectMetricspawn = data => {
    let currentGroupMetricsPawn = '';
    let groupType = '';
    let group = {};
    const metricspawnList = data.data.groups.anyGroup.descendantRoles.metricspawnList;

    if (metricspawnList.length > 0) {
        const mpInfo = metricspawnList.find(mp => mp.parentGroup.anyGroup.group.groupType === currentUserGroup);

        if (mpInfo) {
            currentGroupMetricsPawn = mpInfo.pawn;
            groupType = mpInfo.parentGroup.anyGroup.group.groupType;
            group = mpInfo.parentGroup.anyGroup.group;
        }
    }
    return {
        enableSurveillance: data.data.roles.anyRole.user.pawn.fields.enableSurveillance,
        metricspawn: currentGroupMetricsPawn,
        group,
        groupType,
    };
};

export const selectDeploymentInfo = data => {
    const deploymentList = data.data.flows.deploymentList;
    const openDeployments = deploymentList.filter(i => i.closed === false);
    const closedDeployments = deploymentList.filter(i => i.closed === true);
    const schoolYearList = [...closedDeployments, ...openDeployments];

    if (deploymentList.length > 0) {
        const selectedDeployment = schoolYearList[schoolYearList.length - 1].deploymentHandle;
        return {
            deployments: schoolYearList.map(x => ({ value: x.deploymentHandle, label: x.label })),
            deploymentsInfo: schoolYearList,
            selectedDeployment,
            deploymentId: schoolYearList[schoolYearList.length - 1].deploymentId,
        };
    }
};

const getImportedInfo = (assignmentProgress, flowProgress) => {
    const assignmentFinished = assignmentProgress.status === 'Complete';
    const allSurveysFinished = flowProgress.every(f => f.status === 'Complete');
    const importedDate = assignmentProgress.completedAt || assignmentProgress.lastVisitedAt;

    if (assignmentFinished && !allSurveysFinished) {
        return { imported: true, importedDate };
    }
    return { imported: false, importedDate: '' };
};

/*
 *
 *     Public School Assignment Progress
 *
 */
export const selectPublicSchoolAssignmentProgress = data => {
    if (!data.data.flows.deploymentList.length > 0) {
        return;
    }

    const publicSchoolMetricsPawn = data.data.groups.publicSchool.childRoles.metricspawn;
    const districtMetricsPawn = data.data.groups.publicSchool.parentGroup.district.childRoles.metricspawn;
    const stateMetricsPawn = data.data.groups.publicSchool.ancestorGroups.state.childRoles.metricspawn;

    // Values returned:
    const isOpenDeployment = data.data.flows.deploymentList[0].isOpen;
    let importedInfo = { imported: null, importedDate: '' };
    let importData = { publicSchoolImportData: null };
    let districtDataEntryInfo = { districtEntersData: null, importData: {} };
    let stateDataEntryInfo = { stateEntersPublicData: null, importData: {} };
    let deploymentId = data.data.flows.deploymentList[0].deploymentId;
    let reportGroupType = 'publicSchool';

    //  PUBLIC SCHOOL - Get and Set Import Data
    if (publicSchoolMetricsPawn.assignmentProgressList?.length > 0) {
        importedInfo = getImportedInfo(
            publicSchoolMetricsPawn.assignmentProgressList[0],
            publicSchoolMetricsPawn.flowProgressList.filter(
                f => f.responseSet === data.data.flows.deploymentList[0].deploymentId
            )
        );

        const additionalInfo = publicSchoolMetricsPawn.assignmentProgressList[0].additionalInfo;
        if (typeof additionalInfo === 'object' && Object.keys(additionalInfo).includes('publicSchoolImportData')) {
            importData = { publicSchoolImportData: additionalInfo.publicSchoolImportData };
        }
    }

    //  PUBLIC SCHOOL - Get and Set District or State enter data
    if (districtMetricsPawn.assignmentProgressList?.length > 0) {
        const districtAdditionalInfo = districtMetricsPawn.assignmentProgressList[0].additionalInfo;

        if (typeof districtAdditionalInfo === 'object') {
            if (Object.keys(districtAdditionalInfo).includes('districtEntersData')) {
                districtDataEntryInfo = districtAdditionalInfo;
            }
        }
    }
    if (stateMetricsPawn.assignmentProgressList?.length > 0) {
        const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
        if (typeof stateAdditionalInfo === 'object') {
            if (Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')) {
                stateDataEntryInfo = stateAdditionalInfo;
            }
        }
    }

    // Set reportGroupType
    if (
        (stateDataEntryInfo.stateEntersPublicData === true &&
            !stateDataEntryInfo.importData?.publicSchool?.importDistrictData &&
            !stateDataEntryInfo.importData?.publicSchool?.importSchoolData) ||
        stateDataEntryInfo.importData?.publicSchool?.importStateData === true
    ) {
        reportGroupType = 'state';
    } else if (
        districtDataEntryInfo.districtEntersData === true ||
        districtDataEntryInfo.importData?.importDistrictData === true ||
        stateDataEntryInfo.importData?.publicSchool?.importDistrictData === true
    ) {
        reportGroupType = 'district';

        if (
            districtDataEntryInfo.importData?.importSchoolData === true ||
            stateDataEntryInfo.importData?.publicSchool?.importSchoolData === true
        ) {
            reportGroupType = 'publicSchool';
        }

        if (stateDataEntryInfo.importData?.publicSchool?.importDistrictData === true) {
            reportGroupType = 'district';
        }
    }

    return {
        publicSchoolMetricsPawn,
        deploymentId,
        isOpenDeployment,
        importedInfo,
        importData,
        districtDataEntryInfo,
        stateDataEntryInfo,
        reportGroupType,
    };
};

/*
 *
 *     District Assignment Progress
 *
 */
export const selectDistrictAssignmentProgress = data => {
    if (!data.data.flows.deploymentList?.length > 0) {
        return;
    }
    const districtMetricsPawn = data.data.groups.district.childRoles.metricspawn;
    const stateMetricsPawn = data.data.groups.district.parentGroup.state.childRoles.metricspawn;

    // Values Returned
    const isOpenDeployment = data.data.flows.deploymentList[0].isOpen;
    let importedInfo = { imported: null, importedDate: '' };
    let importData = {
        districtEntersData: null,
        importData: { importDistrictData: null, importSchoolData: null },
    };
    let stateDataEntryInfo = { stateEntersPublicData: null, importData: {} };
    let deploymentId = data.data.flows.deploymentList[0].deploymentId;
    let reportGroupType = 'district';

    //  DISTRICT - Get and Set Import district or school data
    if (districtMetricsPawn.assignmentProgressList?.length > 0) {
        importedInfo = getImportedInfo(
            districtMetricsPawn.assignmentProgressList[0],
            districtMetricsPawn.flowProgressList.filter(
                f => f.responseSet === data.data.flows.deploymentList[0].deploymentId
            )
        );
        const additionalInfo = districtMetricsPawn.assignmentProgressList[0].additionalInfo;

        if (Object.keys(additionalInfo).includes('districtEntersData')) {
            importData.districtEntersData = additionalInfo.districtEntersData;
        }
        if (Object.keys(additionalInfo).includes('importData')) {
            importData.importData = {
                importDistrictData: additionalInfo.importData.importDistrictData,
                importSchoolData: additionalInfo.importData.importSchoolData,
            };
        }
    }

    //  DISTRICT - Get and Set State enters data
    if (stateMetricsPawn.assignmentProgressList?.length > 0) {
        const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
        if (typeof stateAdditionalInfo === 'object') {
            if (Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')) {
                stateDataEntryInfo.stateEntersPublicData = stateAdditionalInfo.stateEntersPublicData;
            }
            if (Object.keys(stateAdditionalInfo).includes('importData')) {
                stateDataEntryInfo.importData = stateAdditionalInfo.importData;
            }
        }
    }

    // Set reportGroupType
    if (
        (stateDataEntryInfo.stateEntersPublicData === true &&
            !stateDataEntryInfo.importData?.publicSchool?.importDistrictData &&
            !stateDataEntryInfo.importData?.publicSchool?.importSchoolData) ||
        stateDataEntryInfo.importData?.publicSchool?.importStateData === true
    ) {
        reportGroupType = 'state';
    } else if (
        importData?.districtEntersData === true ||
        importData?.importData.importDistrictData === true ||
        stateDataEntryInfo.importData?.publicSchool?.importDistrictData === true
    ) {
        reportGroupType = 'district';
        if (
            importData?.importData.importSchoolData === true ||
            stateDataEntryInfo.importData?.publicSchool?.importSchoolData === true
        ) {
            reportGroupType = 'publicSchool';
        }

        if (stateDataEntryInfo.importData?.publicSchool?.importDistrictData === true) {
            reportGroupType = 'district';
        }
    } else {
        reportGroupType = 'publicSchool';
    }

    return {
        districtMetricsPawn,
        deploymentId,
        isOpenDeployment,
        importedInfo,
        importData,
        stateDataEntryInfo,
        reportGroupType,
    };
};

/*
 *
 *     State Assignment Progress
 *
 */
export const selectStateAssignmentProgress = data => {
    const stateMetricsPawn = data.data.groups.state.childRoles.metricspawn;
    const isOpenDeployment = data.data.flows.deploymentList[0].isOpen;
    let importedInfo = { imported: null, importedDate: '' };
    let importData = {
        stateEntersPublicData: null,
        importData: {
            publicSchool: {
                importStateData: null,
                importDistrictData: null,
                importSchoolData: null,
            },
        },
    };
    let deploymentId = data.data.flows.deploymentList[0].deploymentId;
    let reportGroupType = 'state';

    // STATE - State enters public data
    if (stateMetricsPawn.assignmentProgressList?.length > 0) {
        importedInfo = getImportedInfo(
            stateMetricsPawn.assignmentProgressList[0],
            stateMetricsPawn.flowProgressList.filter(
                f => f.responseSet === data.data.flows.deploymentList[0].deploymentId
            )
        );

        const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
        if (
            typeof stateAdditionalInfo === 'object' &&
            Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')
        ) {
            importData.stateEntersPublicData =
                stateMetricsPawn.assignmentProgressList[0].additionalInfo.stateEntersPublicData;
        }

        /*
         *  STATE - State imports data
         */
        if (Object.keys(stateAdditionalInfo).includes('importData')) {
            importData.importData = {
                publicSchool: {
                    importStateData: stateAdditionalInfo.importData?.publicSchool?.importStateData,
                    importDistrictData: stateAdditionalInfo.importData?.publicSchool?.importDistrictData,
                    importSchoolData: stateAdditionalInfo.importData?.publicSchool?.importSchoolData,
                },
            };
        }
    }

    // Set reportGroupType
    if (!importData.stateEntersPublicData && !importData.importData.publicSchool.importStateData) {
        reportGroupType = 'districtsAndSchools';
    }

    if (importData.importData.publicSchool.importDistrictData === true) {
        reportGroupType = 'district';
    }

    if (importData.importData.publicSchool.importSchoolData === true) {
        reportGroupType = 'publicSchool';
    }

    return {
        stateMetricsPawn,
        deploymentId,
        isOpenDeployment,
        importedInfo,
        importData,
        reportGroupType,
    };
};

export const selectStatePrivateAssignmentProgress = data => {
    const isOpenDeployment = data.data.flows.deploymentList[0].isOpen;
    let importedInfo = { imported: null, importedDate: '' };
    let importData = {
        stateEntersPrivateData: null,
        importData: {
            privateSchool: {
                importStateData: null,
                importSchoolData: null,
            },
        },
    };
    let deploymentId = data.data.flows.deploymentList[0].deploymentId;
    let reportGroupType = 'statePrivate';

    const stateMetricsPawn = data.data.groups.state.childRoles.metricspawn;

    if (stateMetricsPawn.assignmentProgressList?.length > 0) {
        importedInfo = getImportedInfo(
            stateMetricsPawn.assignmentProgressList[0],
            stateMetricsPawn.flowProgressList.filter(
                f => f.responseSet === data.data.flows.deploymentList[0].deploymentId
            )
        );

        /*
         *  STATE Priv - State enters private school data
         */
        const additionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
        if (Object.keys(additionalInfo).includes('stateEntersPrivateData')) {
            if (additionalInfo.stateEntersPrivateData === false) {
                reportGroupType = 'privateSchool';
            }
            importData.stateEntersPrivateData = additionalInfo.stateEntersPrivateData;
        }

        /*
         *  STATE Priv - State imports private school data
         */
        if (Object.keys(additionalInfo).includes('importData')) {
            if (additionalInfo.importData.privateSchool?.importSchoolData === true) {
                reportGroupType = 'privateSchool';
            }
            importData.importData = additionalInfo.importData;
        }
    }

    return {
        stateMetricsPawn,
        deploymentId,
        isOpenDeployment,
        importedInfo,
        importData,
        reportGroupType,
    };
};

/*
 *
 *     Private School Assignment Progress
 *
 */
export const selectPrivateSchoolAssignmentProgress = data => {
    const isOpenDeployment = data.data.flows.deploymentList[0].isOpen;
    let importedInfo = { imported: null, importedDate: '' };
    let importData = { privateSchoolImportData: null };
    let stateImportData = {
        stateEntersPrivateData: null,
        importData: { privateSchool: { importStateData: null, importSchoolData: null } },
    };
    let deploymentId = data.data.flows.deploymentList[0].deploymentId;
    let reportGroupType = 'privateSchool';
    const schoolMetricsPawn = data.data.groups.privateSchool.childRoles.metricspawn;
    const stateMetricsPawn = data.data.groups.privateSchool.ancestorGroups.state.childRoles.metricspawn;

    /*
     *  PRIVATE SCHOOL - Import Data
     */
    if (schoolMetricsPawn.assignmentProgressList?.length > 0) {
        importedInfo = getImportedInfo(
            schoolMetricsPawn.assignmentProgressList[0],
            schoolMetricsPawn.flowProgressList.filter(
                f => f.responseSet === data.data.flows.deploymentList[0].deploymentId
            )
        );
        const additionalInfo = schoolMetricsPawn.assignmentProgressList[0]?.additionalInfo;
        const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0]?.additionalInfo;
        if (typeof additionalInfo === 'object' && Object.keys(additionalInfo).includes('privateSchoolImportData')) {
            importData.privateSchoolImportData = additionalInfo.privateSchoolImportData;
        }

        if (
            typeof stateAdditionalInfo === 'object' &&
            Object.keys(stateAdditionalInfo).includes('stateEntersPrivateData')
        ) {
            stateImportData.stateEntersPrivateData = stateAdditionalInfo.stateEntersPrivateData;
            if (stateAdditionalInfo.stateEntersPrivateData === true) {
                if (stateAdditionalInfo.importData?.privateSchool?.importSchoolData === true) {
                    reportGroupType = 'privateSchool';
                } else {
                    reportGroupType = 'statePrivate';
                }
            }
        }
    }

    return {
        schoolMetricsPawn,
        stateMetricsPawn,
        deploymentId,
        isOpenDeployment,
        importedInfo,
        importData,
        stateImportData,
        reportGroupType,
    };
};

export const selectReportMetrics = (data, reportGroupType) => {
    let districtIds = [];
    let schoolIds = [];
    let reportMetricsIds = [];
    let districtsEnteringData = [];
    let schoolsEnteringData = [];
    if (reportGroupType === 'state') {
        const state = data.result.data.groups.state;
        return { reportMetricsIds: [state.descendantRoles.metricspawn.pawn.pawnId] };
    }

    if (reportGroupType === 'district') {
        const districtList = data.result.data.groups.districtList;
        if (districtList?.length > 0) {
            districtIds = [...districtList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            reportMetricsIds = [...districtList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            districtsEnteringData = districtList.map(x => x.childRoles.metricspawn.pawn.pawnId);
        }
    }

    if (reportGroupType === 'publicSchool') {
        const psList = data.result.data.groups.publicSchoolList;
        if (psList?.length > 0) {
            reportMetricsIds = [...districtIds, ...psList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            schoolsEnteringData = psList.map(x => x.childRoles.metricspawn.pawn.pawnId);
            schoolIds = [...psList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
        }
    }

    if (reportGroupType === 'districtsAndSchools') {
        const districtList = data.districtsResult.data.groups.districtList;
        if (districtList?.length > 0) {
            districtIds = [...districtList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            reportMetricsIds = [...districtList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            districtsEnteringData = districtList.map(x => x.childRoles.metricspawn.pawn.pawnId);
        }
        const psList = data.schoolsResult.data.groups.publicSchoolList;
        if (psList?.length > 0) {
            reportMetricsIds = [...districtIds, ...psList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
            schoolsEnteringData = psList.map(x => x.childRoles.metricspawn.pawn.pawnId);
            schoolIds = [...psList.map(x => x.childRoles.metricspawn.pawn.pawnId)];
        }
    }

    if (reportGroupType === 'statePrivate') {
        const state = data.result.data.groups.state;
        return { reportMetricsIds: [state.descendantRoles.metricspawn.pawn.pawnId] };
    }

    if (reportGroupType === 'privateSchool') {
        const psList = data.result.data.groups.privateSchoolList;

        if (psList?.length > 0) {
            const metricspawnsList = psList.map(x => x.childRoles.metricspawn.pawn.pawnId);
            schoolIds = metricspawnsList;
            reportMetricsIds = metricspawnsList;
            schoolsEnteringData = metricspawnsList;
        }
    }

    return {
        districtIds,
        schoolIds,
        reportMetricsIds,
        districtsEnteringData,
        schoolsEnteringData,
    };
};
