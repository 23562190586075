import React from 'react';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import { getUserGroups } from 'js/reducers/nasn.js';
import RolePickerGroup from './RolePickerGroup';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import style from './style.css';

import DistrictAdmin from './images/DistrictAdmin.png';
import SchoolAdmin from './images/SchoolAdmin.png';
import StateAdmin from './images/StateAdmin.png';
import SiteAdmin from './images/SiteAdmin.png';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const mapStateToProps = (state, props) => ({
    userGroups: state.app.nasn.userGroups,
});

export default connect(mapStateToProps, { getUserGroups })(
    class RolePicker extends React.Component {
        static propTypes = {
            getUserGroups: PropTypes.func,
            userGroups: PropTypes.array,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.getUserGroups();
            let response = await axios.get(Boilerplate.route('pawn.pending.requests'));
            this.setState({ pendingRequests: response.data.pendingRequests, loading: false });
        };

        render() {
            const { userGroups } = this.props;
            // console.log('userGroups', userGroups);
            let publicSchoolPawns = userGroups.filter(
                user => user.data_type === 'publicSchoolUser'
            );
            let privateSchoolPawns = userGroups.filter(
                user => user.data_type === 'privateSchoolUser'
            );
            let districtPawns = userGroups.filter(
                user =>
                    user.data_type === 'districtAdmin' || user.data_type === 'districtTeamMember'
            );
            let statePawns = userGroups.filter(
                user => user.data_type === 'stateAdmin' || user.data_type === 'stateTeamMember'
            );
            let uberPawns = userGroups.filter(user => user.data_type === 'uberadmin');
            if (this.state.loading) {
                return <Loader loading />;
            }
            // console.log('districtPawns', districtPawns, 'uberPawns', uberPawns);
            return (
                <div>
                    {publicSchoolPawns.length > 0 ||
                    privateSchoolPawns.length > 0 ||
                    districtPawns.length > 0 ||
                    statePawns.length > 0 ||
                    uberPawns.length > 0 ? (
                        <div className={style.subheading}>
                            <span className={style.subheadingTitle}>
                                Please choose which account you would like to visit
                            </span>
                        </div>
                    ) : (
                        <div className={style.subheading}>
                            <span className={style.subheadingTitle}>
                                Your accounts will appear here when you join one or more teams.
                            </span>
                        </div>
                    )}

                    <div className={style.groupSelectWrapper}>
                        {publicSchoolPawns.length >= 1 && (
                            <RolePickerGroup
                                groupPawns={publicSchoolPawns}
                                backgroundImage={SchoolAdmin}
                            />
                        )}
                        {privateSchoolPawns.length >= 1 && (
                            <RolePickerGroup
                                groupPawns={privateSchoolPawns}
                                backgroundImage={SchoolAdmin}
                            />
                        )}
                        {districtPawns.length >= 1 && (
                            <RolePickerGroup
                                groupPawns={districtPawns}
                                backgroundImage={DistrictAdmin}
                            />
                        )}
                        {statePawns.length >= 1 && (
                            <RolePickerGroup groupPawns={statePawns} backgroundImage={StateAdmin} />
                        )}
                        {uberPawns.length >= 1 && (
                            <RolePickerGroup groupPawns={uberPawns} backgroundImage={SiteAdmin} />
                        )}
                    </div>
                </div>
            );
        }
    }
);
