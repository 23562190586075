import React from 'react';
import Select from 'react-select';

const options = [
    { value: 'workforce', label: 'Workforce' },
    { value: 'chronic', label: 'Chronic Conditions' },
    { value: 'absenteeism', label: 'Absenteeism' },
    { value: 'healthoffice', label: 'Health Office Visits' },
];

const Multiselect = () => (
    <Select
        isMulti
        closeModalOnSelect={false}
        options={options}
        styles={{ container: provided => ({ ...provided, width: '200px' }) }}
    />
);

export default Multiselect;
