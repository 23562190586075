import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import { BrowserOnly } from 'cccisd-laravel-report';
import style from './style.css';

import publicSchoolQuery from './pubSchChronicConditions.graphql';
import privateSchoolQuery from './privSchChronicConditions.graphql';
import districtQuery from './districtChronicConditions.graphql';
import stateQuery from './stateChronicConditions.graphql';

import ccVariables from './ccVariables.js';
import _round from 'lodash/round';
import { useGetOverviewReportData } from 'js/queries';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);
    const deploymentHandle = reduxStore.selectedDeployment.slice(0, 9) + `_${props.widgetProps.reportGroupType}`;

    const getQueryType = () => {
        let query = stateQuery;
        let variables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_state',
            workforce: ccVariables.state,
        };
        const districtVariables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_district',
            workforce: ccVariables.district,
        };
        const schoolVariables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_publicSchool',
            workforce: ccVariables.publicSchool,
        };

        const privateSchoolVariables = {
            metricspawns: props.widgetProps.metricspawns,
            workforce: ccVariables.privateSchool,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_privateSchool',
        };

        if (props.widgetProps.reportGroupType === 'district') {
            query = districtQuery;
            variables = districtVariables;
        }
        if (props.widgetProps.reportGroupType === 'publicSchool') {
            query = publicSchoolQuery;
            variables = schoolVariables;
        }
        if (props.widgetProps.reportGroupType === 'privateSchool') {
            query = privateSchoolQuery;
            variables = privateSchoolVariables;
        }

        return [{ query, variables }];
    };

    const overviewReportQuery = useGetOverviewReportData(
        'cc singleGroup',
        props.widgetProps.reportGroupType,
        getQueryType()
    );

    const queriesLoading = overviewReportQuery.some(q => q.isFetching);

    const getData = () => {
        if (!queriesLoading) {
            const dataReturned = overviewReportQuery.every(q => q.data);
            if (dataReturned) {
                const data = overviewReportQuery[0].data;
                const mps = data.data.roles.metricspawnSummary;
                const totalStudents = mps.totalstudents;
                const totalAsthma = mps.totalasthma;
                const totalType1 = mps.totaltype1;
                const totalType2 = mps.totaltype2;
                const totalSeizure = mps.totalseizure;
                const totalLta = mps.totallta;
                const totalMecfs = mps.totalmecfs;
                const per1000 = x => _round((x / totalStudents) * 1000, 2);

                if (!totalStudents) {
                    return {
                        asthma: '?',
                        type1: '?',
                        type2: '?',
                        lta: '?',
                        seizure: '?',
                        mecfs: '?',
                    };
                }

                return {
                    asthma: per1000(totalAsthma),
                    type1: per1000(totalType1),
                    type2: per1000(totalType2),
                    lta: per1000(totalLta),
                    seizure: per1000(totalSeizure),
                    mecfs: per1000(totalMecfs),
                };
            }
        }
        return { asthma: '', type1: '', type2: '', lta: '', seizure: '', mecfs: '' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Chronic Conditions'}
                        </div>
                        {!queriesLoading &&
                            overviewReportQuery.data?.data.roles &&
                            !overviewReportQuery.data?.data.roles.metricspawnSummary.totalstudents && (
                                <BrowserOnly>
                                    <div
                                        style={{
                                            fontSize: '0.58em',
                                            color: 'red',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Data for this section cannot be calculated without entering total students.
                                    </div>
                                </BrowserOnly>
                            )}
                    </div>
                    <div className={style.content}>
                        {queriesLoading ? (
                            <Skeleton height={73} />
                        ) : (
                            <div className={style.boxWrapper}>
                                <div className={style.box}>
                                    <div className={`${style.asthma}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Asthma</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().asthma || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.diabetes}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Diabetes</div>
                                        <div>
                                            Type 1: <span style={{ fontWeight: 'bold' }}>{getData().type1 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                        <div>
                                            Type 2: <span style={{ fontWeight: 'bold' }}>{getData().type2 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.allergy}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Life Threatening
                                            <br /> Allergy
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().lta || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.seizure}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Seizure Disorder</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().seizure || '?'}</span> /
                                            1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.mecfs}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>ME/CFS</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().mecfs || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
