/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import style from './style.css';
import tableHeaders from '../../../tableHeaders.js';
import _round from 'lodash/round';
import _isPlainObject from 'lodash/isPlainObject';

const Fortress = window.cccisd.fortress;
const actingUserGroup = Fortress.user.acting.group;

const Table = props => {
    const {
        filters: { comparison1, comparison2 },
    } = props;

    if (props.data) {
        if (Object.keys(props.data).length <= 0) {
            return (
                <div className="alert alert-info">
                    Could not find data for your group for this time period.
                </div>
            );
        }
    } else {
        return (
            <div className="alert alert-info">
                Could not find data for your group for this time period.
            </div>
        );
    }

    const { data } = props;

    const comparisonGroupTitle1 = tableHeaders[comparison1];
    const comparisonGroupTitle2 = tableHeaders[comparison2];

    const { currentGroup } = data;
    const comparisonGroupKeys = Object.keys(data).filter(x => x !== 'currentGroup');
    const comparisonGroup1 = data[comparisonGroupKeys[0]];
    const comparisonGroup2 = data[comparisonGroupKeys[1]];

    const currentGroupHasData =
        _isPlainObject(currentGroup) && Object.keys(currentGroup).length > 0;
    const comparisonGroup1ExistsWithData =
        _isPlainObject(comparisonGroup1) && Object.keys(comparisonGroup1).length > 0;
    const comparisonGroup2ExistsWithData =
        _isPlainObject(comparisonGroup2) && Object.keys(comparisonGroup2).length > 0;

    const getMyGroupHeaderName = () => {
        let myRole = '';
        if (actingUserGroup) {
            myRole = actingUserGroup.data_type;
        }

        if (myRole === 'district') {
            return currentGroup.District_Name;
        }
        if (myRole === 'state') {
            return currentGroup.State_Name;
        }
        if (['privateSchool', 'publicSchool'].includes(myRole)) {
            return currentGroup.School_Name;
        }
        return 'Self';
    };

    const formatNumber = n => Number(n).toLocaleString();

    if (!currentGroupHasData) {
        return (
            <div className="alert alert-info">
                Could not find data for your group for this time period.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ tableLayout: 'fixed', width: '100%' }}>
            <colgroup>
                <col style={{ width: '60px' }} />
                <col style={{ width: '60px' }} />
                {comparisonGroup1ExistsWithData && <col style={{ width: '60px' }} />}
                {comparisonGroup2ExistsWithData && <col style={{ width: '60px' }} />}
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading}>Domain/Factor</th>
                    <th className={style.tgHeading}>{getMyGroupHeaderName()}</th>
                    {comparisonGroup1ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle1}</th>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle2}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Student Population</td>
                    <td className={style.tgCell}>
                        {formatNumber(currentGroup.Student_Population)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {formatNumber(comparisonGroup1.Student_Population)}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {formatNumber(comparisonGroup2.Student_Population)}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        TOTAL NURSING FTE
                        <ul>
                            <li>RN</li>
                            <li>LPN/LVN</li>
                            <li>Health Aide</li>
                        </ul>
                    </td>
                    <td className={style.tgCell}>
                        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                            <li style={{ fontWeight: 'bold' }}>
                                {currentGroup.Total_Nursing_FTE &&
                                    formatNumber(currentGroup.Total_Nursing_FTE) + ' total'}
                            </li>
                            <li>
                                {currentGroup.RN_Total_FTE &&
                                    formatNumber(currentGroup.RN_Total_FTE) + ' RN'}
                            </li>
                            <li>
                                {currentGroup.LPN_LVN_Total_FTE &&
                                    formatNumber(currentGroup.LPN_LVN_Total_FTE) + ' LPN/LVN'}
                            </li>
                            <li>
                                {currentGroup.Health_Aide_Total_FTE &&
                                    formatNumber(currentGroup.Health_Aide_Total_FTE) + ' Aide'}
                            </li>
                        </ul>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup1.Total_Nursing_FTE &&
                                        formatNumber(comparisonGroup1.Total_Nursing_FTE) + ' total'}
                                </li>
                                <li>
                                    {comparisonGroup1.RN_Total_FTE &&
                                        formatNumber(comparisonGroup1.RN_Total_FTE) + ' RN'}
                                </li>
                                <li>
                                    {comparisonGroup1.LPN_LVN_Total_FTE &&
                                        formatNumber(comparisonGroup1.LPN_LVN_Total_FTE) +
                                            ' LPN/LVN'}
                                </li>
                                <li>
                                    {comparisonGroup1.Health_Aide_Total_FTE &&
                                        formatNumber(comparisonGroup1.Health_Aide_Total_FTE) +
                                            ' Aide'}
                                </li>
                            </ul>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup2.Total_Nursing_FTE &&
                                        formatNumber(comparisonGroup2.Total_Nursing_FTE) + ' total'}
                                </li>
                                <li>
                                    {comparisonGroup2.RN_Total_FTE &&
                                        formatNumber(comparisonGroup2.RN_Total_FTE) + ' RN'}
                                </li>
                                <li>
                                    {comparisonGroup2.LPN_LVN_Total_FTE &&
                                        formatNumber(comparisonGroup2.LPN_LVN_Total_FTE) +
                                            ' LPN/LVN'}
                                </li>
                                <li>
                                    {comparisonGroup2.Health_Aide_Total_FTE &&
                                        formatNumber(comparisonGroup2.Health_Aide_Total_FTE) +
                                            ' Aide'}
                                </li>
                            </ul>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Asthma (per 1000)</td>
                    <td className={style.tgCell}>
                        {_round(currentGroup.Total_Students_Asthma_Per_1000, 2)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup1.Total_Students_Asthma_Per_1000, 2)}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup2.Total_Students_Asthma_Per_1000, 2)}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Type 1 Diabetes (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        <div>{_round(currentGroup.Total_Students_Type_1_Diabetes_Per_1000, 2)}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup1.Total_Students_Type_1_Diabetes_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup2.Total_Students_Type_1_Diabetes_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Type 2 Diabetes (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        <div>{_round(currentGroup.Total_Students_Type_2_Diabetes_Per_1000, 2)}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup1.Total_Students_Type_2_Diabetes_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup2.Total_Students_Type_2_Diabetes_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Seizure Disorder (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        <div>
                            {_round(currentGroup.Total_Students_Seizure_Disorder_Per_1000, 2)}
                        </div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup1.Total_Students_Seizure_Disorder_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {_round(
                                    comparisonGroup2.Total_Students_Seizure_Disorder_Per_1000,
                                    2
                                )}
                            </div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Life Threatening Allergy (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        {_round(currentGroup.Total_Students_Life_Threatening_Allergy_Per_1000, 2)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(
                                comparisonGroup1.Total_Students_Life_Threatening_Allergy_Per_1000,
                                2
                            )}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(
                                comparisonGroup2.Total_Students_Life_Threatening_Allergy_Per_1000,
                                2
                            )}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>ME/CFS (per 1000)</td>
                    <td className={style.tgCell}>
                        {_round(currentGroup.Total_Students_ME_CFS_Per_1000, 2)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup1.Total_Students_ME_CFS_Per_1000, 2)}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup2.Total_Students_ME_CFS_Per_1000, 2)}
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default Table;
