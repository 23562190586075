import _omit from 'lodash/omit';
import _mergeWith from 'lodash/mergeWith';

export const getWidgetData = queryResultList => {
    if (queryResultList.length === 1) {
        return queryResultList[0].data;
    }
    if (queryResultList.length === 2) {
        return combineDistrictSchoolData(queryResultList[1].data, queryResultList[0].data);
    }
    return queryResultList;
};

export const combineDistrictSchoolData = (schoolData, districtData) => {
    let combinedData = {
        data: {
            roles: {
                metricspawnList: [],
                metricspawnSummary: {},
            },
        },
    };

    if (!schoolData && !districtData) {
        return combinedData;
    }
    if (schoolData && !districtData) {
        return schoolData;
    }
    if (districtData && !schoolData) {
        return districtData;
    }
    if (schoolData && districtData) {
        const districtMPList = districtData.data.roles.metricspawnList;
        const schoolMPList = schoolData.data.roles.metricspawnList;
        const districtMPSummary = _omit(districtData.data.roles.metricspawnSummary, ['groupstatus', '__typename']);
        const schoolMPSummary = _omit(schoolData.data.roles.metricspawnSummary, ['groupstatus', '__typename']);

        combinedData = {
            data: {
                roles: {
                    metricspawnList: [...districtMPList, ...schoolMPList],
                    metricspawnSummary: _mergeWith(schoolMPSummary, districtMPSummary, (a, b) => a + b),
                },
            },
        };
    }

    return combinedData;
};
