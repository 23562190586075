export default {
    publicSchool: [
        'PUB_SCH_NUM_RN_FTE',
        'PUB_SCH_NUM_LPN_FTE',
        'PUB_SCH_NUM_AIDE_FTE',
        'PUB_SCH_RN_FTE_CASELOAD',
        'PUB_SCH_RN_FTE_FLOAT',
        'PUB_SCH_RN_FTE_SPEC_ASN',
        'PUB_SCH_RN_FTE_ADMIN',
        'PUB_SCH_LPN_FTE_CASELOAD',
        'PUB_SCH_LPN_FTE_FLOAT',
        'PUB_SCH_LPN_FTE_SPEC_ASN',
        'PUB_SCH_LPN_FTE_ADMIN',
        'PUB_SCH_AIDE_FTE_CASELOAD',
        'PUB_SCH_AIDE_FTE_FLOAT',
        'PUB_SCH_AIDE_FTE_SPEC_ASN',
        'PUB_SCH_AIDE_FTE_ADMIN',
    ],
};
