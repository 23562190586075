import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const getGroupName = () => {
        const user = Fortress.user.acting;
        if (['state', 'statePrivate'].includes(user.group.data_type)) {
            return user.group.type_data.stateName;
        }
        return user.group.label;
    };

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <div className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>{getGroupName()}</div>
                    <div className={style.reportType}>Comparison Report</div>
                </div>
                <div className={style.info}>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Report Created On: </span>
                        {moment(new Date()).format('MMMM Do YYYY')}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>School Year: </span>
                        <span style={{ textTransform: 'capitalize' }}>
                            {props.schoolYear.slice(0, 9)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    schoolYear: PropTypes.string,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'Overview Report',
            createdDateText: 'Report Created On:',
            schoolYearText: 'School Year:',
        },
    },
};

export default ReportHeader;
