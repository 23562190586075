import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

import IconFacebook from 'cccisd-icons/facebook';
import IconTwitter from 'cccisd-icons/twitter';
import IconYoutube from 'cccisd-icons/youtube';
import IconLinkedIn from 'cccisd-icons/linkedin';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        showPolicyLinks: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPolicyLinks: true,
    };

    render() {
        return (
            <div className={style.footer} id="layout_footer">
                <div className={style.footerUpper}>
                    <div className="container">
                        <div className={style.footerContent}>
                            <div className={style.footerImage} />
                            <div className={style.footerText}>
                                <h4 style={{ fontWeight: 'bold' }}>NATIONAL ASSOCIATION OF SCHOOL NURSES</h4>
                                1100 Wayne Ave, Suite #925 <br />
                                Silver Spring, MD 20910-5669
                                <br />
                                240-821-1130 <br />
                                <span className={style.links}>
                                    <a style={{ fontWeight: 'bold' }} href="mailto:data@nasn.org">
                                        data@nasn.org
                                    </a>
                                </span>
                            </div>
                            <div className={style.footerText}>
                                <h4>
                                    <span style={{ fontWeight: 'bold' }}>SITE</span> LINKS
                                </h4>
                                <span className={style.siteLinks}>
                                    <a style={{ fontWeight: 'bold' }} href="//www.schoolhealthdata.org/">
                                        Home
                                    </a>

                                    <a style={{ fontWeight: 'bold' }} href="//www.schoolhealthdata.org/contact/">
                                        Contact
                                    </a>
                                </span>
                            </div>
                            <div className={style.socialLinks}>
                                <a
                                    href="//www.facebook.com/SchoolNurses"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={style.socialIcon}
                                    style={{ backgroundColor: '#3b5998' }}
                                >
                                    <IconFacebook />
                                </a>
                                <a
                                    href="//twitter.com/schoolnurses"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={style.socialIcon}
                                    style={{ backgroundColor: '#1da1f2' }}
                                >
                                    <IconTwitter />
                                </a>
                                <a
                                    href="//www.youtube.com/user/NASNChannel"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={style.socialIcon}
                                    style={{ backgroundColor: '#cd201f' }}
                                >
                                    <IconYoutube />
                                </a>
                                <a
                                    href="//www.linkedin.com/company/national-association-of-school-nurses/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={style.socialIcon}
                                    style={{ backgroundColor: '#0077b5' }}
                                >
                                    <IconLinkedIn />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.footerLower}>
                    <div className="container">
                        <div className={style.wrapper}>
                            <div>
                                Website and software developed by{' '}
                                <span className={style.links}>
                                    <a href="//www.3cisd.com/" rel="noopener noreferrer" target="_blank">
                                        3C Institute.
                                    </a>
                                </span>{' '}
                                © {new Date().getFullYear()} NASN.
                            </div>
                            {this.props.showPolicyLinks && (
                                <div className={style.links}>
                                    <a
                                        href="https://www.3cisd.com/#ccc-lets-talk"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Contact Us
                                    </a>
                                    <a href="//www.3cisd.com/terms-of-use" rel="noopener noreferrer" target="_blank">
                                        Terms of Use
                                    </a>
                                    <a href="//www.3cisd.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                                        Privacy Policy
                                    </a>
                                    <a href="//www.nasn.org/accessibility" rel="noopener noreferrer" target="_blank">
                                        Accessibility Statement
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
