/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import style from './style.css';
import _isPlainObject from 'lodash/isPlainObject';

const Table = props => {
    const data = Object.keys(props.data).length > 0 ? props.data : props.fakeData;

    const comparisonGroupTitle1 = props.groupLabels[0];
    const comparisonGroupTitle2 = props.groupLabels[1];

    const { currentGroup } = data;
    const comparisonGroupKeys = Object.keys(data).filter(x => x !== 'currentGroup');
    const comparisonGroup1 = data[comparisonGroupKeys[0]];
    const comparisonGroup2 = data[comparisonGroupKeys[1]];

    const currentGroupHasData =
        _isPlainObject(currentGroup) && Object.keys(currentGroup).length > 0;
    const comparisonGroup1ExistsWithData =
        _isPlainObject(comparisonGroup1) && Object.keys(comparisonGroup1).length > 0;
    const comparisonGroup2ExistsWithData =
        _isPlainObject(comparisonGroup2) && Object.keys(comparisonGroup2).length > 0;

    if (!currentGroupHasData) {
        return (
            <div className="alert alert-info">
                Could not find data for your group for this time period.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ tableLayout: 'fixed', width: '100%' }}>
            <colgroup>
                <col style={{ width: '60px' }} />
                <col style={{ width: '60px' }} />
                {comparisonGroup1ExistsWithData && <col style={{ width: '60px' }} />}
                {comparisonGroup2ExistsWithData && <col style={{ width: '60px' }} />}
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading}>Domain/Factor</th>
                    <th className={style.tgHeading}>{currentGroup.State_Name || 'Self'}</th>
                    {comparisonGroup1ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle1}</th>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle2}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN provides direct care to students on a daily basis (one nurse in one
                        building)
                    </td>
                    <td className={style.tgCell}>{currentGroup.MOP_1}</td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup1.MOP_1}</td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup2.MOP_1}</td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN provides direct care to students on a daily basis with the assistance of
                        an LPN or UAP (in one building)
                    </td>
                    <td className={style.tgCell}>{currentGroup.MOP_2}</td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup1.MOP_2}</td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup2.MOP_2}</td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN provides direct care to students on a daily basis (one nurse covering
                        more than one building with no health clerk, aide, secretary, or UAP
                        covering when RN not present)
                    </td>
                    <td className={style.tgCell}>{currentGroup.MOP_3}</td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup1.MOP_3}</td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup2.MOP_3}</td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN oversees one or more LPNs as a team to provide care (one nurse covering
                        more than one building)
                    </td>
                    <td className={style.tgCell}>
                        <div>{currentGroup.MOP_4}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup1.MOP_4}</div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup2.MOP_4}</div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN oversees health clerks or aides who work in the health office(s) (one
                        nurse covering more than one building)
                    </td>
                    <td className={style.tgCell}>
                        <div>{currentGroup.MOP_5}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup1.MOP_5}</div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup2.MOP_5}</div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        RN trains unlicensed personnel (including secretaries) to perform routine
                        procedures needed in the schools (one nurse covering more than one building)
                    </td>
                    <td className={style.tgCell}>
                        <div>{currentGroup.MOP_6}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup1.MOP_6}</div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup2.MOP_6}</div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Advance Practice/Nurse Practitioner works with RN
                    </td>
                    <td className={style.tgCell}>{currentGroup.MOP_7}</td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup1.MOP_7}</td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup2.MOP_7}</td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Other (please specify)</td>
                    <td className={style.tgCell}>{currentGroup.MOP_8}</td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup1.MOP_8}</td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>{comparisonGroup2.MOP_8}</td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default Table;
