import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const userGroupDataType = Fortress.user.acting.group?.data_type;

const ReportHeader = props => {
    const { group, filters } = props;
    const reduxStore = useSelector(state => state.app.nasn);
    const { reportGroupType, reportMetricsIds } = reduxStore;
    const {
        header: { title, createdDateText, schoolYearText },
    } = props.settings;

    const getGroupName = () => {
        const user = Fortress.user.acting;
        if (['state', 'statePrivate'].includes(group.groupType)) {
            return user.group.type_data.stateName;
        }
        if (group.label) {
            return group.label;
        }
        return 'Group Name';
    };

    const getGroupLabel = () => {
        switch (reportGroupType) {
            case 'districtsAndSchools':
                return 'district and school';
            case 'publicSchool':
                return 'school';
            case 'privateSchool':
                return 'school';
            case 'statePrivate':
                return 'state';
            default:
                return reportGroupType;
        }
    };

    const getAggregateCompletionMessage = () => {
        let message = '';
        if (
            reportGroupType !== userGroupDataType &&
            !(reportGroupType === 'statePrivate' && userGroupDataType === 'state') &&
            reportMetricsIds &&
            reportMetricsIds.length > 0
        ) {
            message = `Data entered at the ${getGroupLabel(reportGroupType)} level`;
        }
        return message;
    };

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <div className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>{getGroupName()}</div>
                    <div className={style.reportType}>
                        {title} {props.overviewType && `(${props.overviewType})`}
                    </div>
                    <div className={style.reportType}>{reportGroupType && getAggregateCompletionMessage()}</div>
                </div>
                <div className={style.info}>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                        {moment(new Date()).format('MMMM Do YYYY')}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{schoolYearText} </span>
                        <span style={{ textTransform: 'capitalize' }}>{filters.schoolYear.slice(0, 9)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    schoolType: PropTypes.string,
    group: PropTypes.object,
    schoolYear: PropTypes.string,
    reportGroupType: PropTypes.string,
    districtsEnteringData: PropTypes.array,
    schoolsEnteringData: PropTypes.array,
    tabSelected: PropTypes.string,
    overviewType: PropTypes.string,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'Overview Report',
            createdDateText: 'Report Created On:',
            schoolYearText: 'School Year:',
            schoolTypeText: 'School Type:',
        },
    },
    group: { groupType: 'state', label: 'My Group' },
    reportGroupType: 'state',
};

export default ReportHeader;
