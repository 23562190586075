import { SchoolYear } from './filters';
import React from 'react';

export default [
    {
        name: 'schoolYear',
        label: 'School Year',
        component: props => <SchoolYear {...props} />,
        // initialValue: '2020-2021_state',
    },
];
