import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const { group } = props;
    const {
        header: { title, createdDateText, schoolYearText, schoolTypeText },
    } = props.settings;

    const getGroupName = () => {
        const user = Fortress.user.acting;
        if (['state', 'statePrivate'].includes(group.groupType)) {
            return user.group.type_data.stateName;
        }
        if (group.label) {
            return group.label;
        }
        return 'Group Name';
    };

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <div className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>{getGroupName()}</div>
                    <div className={style.reportType}>{title}</div>
                </div>
                <div className={style.info}>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                        {moment(new Date()).format('MMMM Do YYYY')}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{schoolYearText} </span>
                        <span style={{ textTransform: 'capitalize' }}>
                            {props.filters.schoolYear.slice(0, 9)}
                        </span>
                    </div>
                    {props.filters.schoolType && (
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{schoolTypeText} </span>
                            <span style={{ textTransform: 'capitalize' }}>
                                {props.filters.schoolType}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    schoolType: PropTypes.string,
    group: PropTypes.object,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'Overview Report',
            createdDateText: 'Report Created On:',
            schoolYearText: 'School Year:',
            schoolTypeText: 'School Type:',
        },
    },
};

export default ReportHeader;
