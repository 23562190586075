import React from 'react';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { useMetricspawnQuery } from 'js/queries';

const Component = props => {
    const metricspawnQuery = useMetricspawnQuery();
    const queriesLoading = metricspawnQuery.isLoading;

    if (queriesLoading) {
        return <Loader loading />;
    }

    if (!queriesLoading) {
        return (
            <>
                <Player
                    {...props}
                    options={props.deployments.deployments}
                    reportGroupType={props.reportGroupType || metricspawnQuery.data.groupType}
                    group={props.group || metricspawnQuery.data.group}
                    schoolYear={props.deployments.selectedDeployment}
                    tabSelected={props.tabSelected}
                    thisGroupReport={props.thisGroupReport}
                    selectedDeployment={props.deployments.selectedDeployment}
                    overviewType={props.overviewType}
                />
            </>
        );
    }
};

export default Component;
