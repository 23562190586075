import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import { setSelectedDeployment, setDeploymentId } from 'js/reducers/nasn.js';

import _some from 'lodash/some';

const SchoolYear = props => {
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);

    const handleChangeDeployment = async (e, form) => {
        const currentDeployment = reduxStore.deploymentsInfo.find(d => d.deploymentHandle === e.target.value);
        form.setFieldValue('schoolYear', e.target.value);
        await dispatch(setSelectedDeployment(e.target.value));
        const currentDeploymentId = currentDeployment.deploymentId;
        await dispatch(setDeploymentId(currentDeploymentId));
    };

    return (
        <Field name="schoolYear" label="School Year">
            {({ field, form }) => (
                <div style={props.settings.hidden ? { display: 'none' } : {}}>
                    <CccisdFieldWrapper field={field} form={form} label="School Year">
                        <select
                            {...field}
                            className="form-control"
                            value={
                                _some(reduxStore.deployments, ['value', reduxStore.selectedDeployment])
                                    ? reduxStore.selectedDeployment
                                    : reduxStore.deployments[reduxStore.deployments.length - 1].value
                            }
                            onChange={e => handleChangeDeployment(e, form)}
                        >
                            {props.settings.options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </CccisdFieldWrapper>
                </div>
            )}
        </Field>
    );
};

SchoolYear.propTypes = {
    settings: PropTypes.object,
};

export default SchoolYear;
