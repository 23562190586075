import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { taskMasterClient, gql } from 'cccisd-apollo';
import _isEqual from 'lodash/isEqual';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import HorizontalBar from './HorizontalBar';
import ChartLegend from '../../ChartLegend';
import style from './style.css';

import individualESCQuery from './individualESC.graphql';
import workforceVariables from './workforceVariables.js';

const Component = props => {
    const adminColor = '#2A83D1';
    const saColor = '#4DACFF';
    const floatColor = '#96CEFF';
    const dsColor = '#D6ECFF';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const reduxStore = useSelector(state => state.app.nasn);
    const deploymentHandle = reduxStore.selectedDeployment.slice(0, 9) + `_individualESC`;
    const variables = {
        metricspawns: [window.cccisd.fortress.user.acting.id],
        deploymentHandle,
        workforce: workforceVariables.publicSchool,
    };
    const query = individualESCQuery;
    const { reportDataLoading } = reduxStore;
    const isFirst = useRef(true);
    const prevDeps = useRef(variables);

    useEffect(() => {
        const isSame = _isEqual(variables, prevDeps.current);

        if (!reportDataLoading) {
            if (isFirst.current || !isSame) {
                (async () => {
                    setLoading(true);

                    const formattedQuery = gql`
                        ${query}
                    `;
                    const response = await taskMasterClient.query({
                        query: formattedQuery,
                        variables,
                        fetchPolicy: 'network-only',
                    });
                    setData(response.data);
                    setLoading(false);
                })();
            }
            isFirst.current = false;
            prevDeps.current = variables;
        }
    }, [reduxStore.selectedDeployment, reportDataLoading]);

    const getData = () => {
        if (!loading) {
            const check = data.roles;
            if (check) {
                let mpSummary = check.anyRoleSummary;
                const _getScores = ({ total = 0, admin = 0, sa = 0, float = 0, ds = 0 }) => {
                    total === null ? (total = 0) : total;
                    if (!total) {
                        return [
                            { title: 'Administrative', score: '?', color: adminColor },
                            { title: 'Special Assignment', score: '?', color: saColor },
                            { title: 'Float', score: '?', color: floatColor },
                            { title: 'Direct Services', score: '?', color: dsColor },
                        ];
                    }
                    return [
                        {
                            title: 'Administrative',
                            score: (admin / total) * 100,
                            color: adminColor,
                        },
                        { title: 'Special Assignment', score: (sa / total) * 100, color: saColor },
                        { title: 'Float', score: (float / total) * 100, color: floatColor },
                        { title: 'Direct Services', score: (ds / total) * 100, color: dsColor },
                    ];
                };
                return {
                    rn: {
                        title: 'RN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalRn,
                            admin: mpSummary.totalRnAdmin,
                            sa: mpSummary.totalRnSpecial,
                            float: mpSummary.totalRnFloat,
                            ds: mpSummary.totalRnCase,
                        }),
                    },
                    lpn: {
                        title: 'LPN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalLpn,
                            admin: mpSummary.totalLpnAdmin,
                            sa: mpSummary.totalLpnSpecial,
                            float: mpSummary.totalLpnFloat,
                            ds: mpSummary.totalLpnCase,
                        }),
                    },
                    aide: {
                        title: 'AIDE',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalAide,
                            admin: mpSummary.totalAideAdmin,
                            sa: mpSummary.totalAideSpecial,
                            float: mpSummary.totalAideFloat,
                            ds: mpSummary.totalAideCase,
                        }),
                    },
                };
            }
        }
        return {
            rn: {
                title: 'RN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            lpn: {
                title: 'LPN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            aide: {
                title: 'AIDE',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
        };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Workforce'}
                        </div>
                    </div>
                    <div className={style.content}>
                        {loading ? (
                            <Skeleton height={141} />
                        ) : (
                            <>
                                <ChartLegend
                                    items={[
                                        { label: 'Administrative', color: adminColor },
                                        { label: 'Special Assignment', color: saColor },
                                        { label: 'Float', color: floatColor },
                                        { label: 'Direct Services', color: dsColor },
                                    ]}
                                />
                                <div className={style.chartWrapper}>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="RN" chartData={getData().rn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="LPN" chartData={getData().lpn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="AIDE" chartData={getData().aide} />
                                    </div>
                                </div>
                                <div className={style.additionalInfo}>
                                    <Html content={props.settings.additionalInfo} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
