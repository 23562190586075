import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';

const SchoolYear = props => {
    return (
        <Field name="schoolYear" label="School Year">
            {({ field, form }) => (
                <CccisdFieldWrapper field={field} form={form} label="School Year">
                    <select {...field} className="form-control">
                        {props.settings.options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </CccisdFieldWrapper>
            )}
        </Field>
    );
};

SchoolYear.propTypes = {
    settings: PropTypes.object,
};

export default SchoolYear;
