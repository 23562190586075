import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import { gql } from 'cccisd-apollo';
import { useGetOverviewReportData, useGetAssignmentProgress, useGetReportMetricsPawnsQuery } from 'js/queries';
import { setReportGroupType, setReportMetricsIds } from 'js/reducers/nasn.js';
import { getWidgetData } from '../../helpers.js';
import HorizontalBar from './HorizontalBar';
import ChartLegend from '../../ChartLegend';
import style from './style.css';

import publicSchoolQuery from './pubSchWorkforce.graphql';
import privateSchoolQuery from './privSchWorkforce.graphql';
import districtQuery from './districtWorkforce.graphql';
import stateQuery from './stateWorkforce.graphql';
import statePrivateQuery from './statePrivSchWorkforce.graphql';
import workforceVariables from './workforceVariables.js';

const Workforce = props => {
    const adminColor = '#2A83D1';
    const saColor = '#4DACFF';
    const floatColor = '#96CEFF';
    const dsColor = '#D6ECFF';

    const dispatch = useDispatch();

    const deploymentHandle = props.filters.schoolYear.slice(0, 9) + `_${props.widgetProps.reportGroupType}`;

    const assignmentProgressQuery = useGetAssignmentProgress(
        [`${props.filters.schoolYear} ${props.widgetProps.reportGroupType} assignmentProgress`],
        props.filters.schoolYear,
        props.widgetProps.reportGroupType,
        true
    );

    const reportGroupType = assignmentProgressQuery?.data?.reportGroupType;

    const reportMetricsPawnsQuery = useGetReportMetricsPawnsQuery(
        [`overview metricspawns ${props.filters.schoolYear} ${props.widgetProps.reportGroupType}`],
        reportGroupType,
        props.filters.schoolYear
    );

    useEffect(() => {
        dispatch(setReportGroupType(reportGroupType));
        dispatch(setReportMetricsIds(reportMetricsPawnsQuery?.data?.reportMetricsIds));
    }, [reportGroupType, reportMetricsPawnsQuery?.data?.reportMetricsIds]);

    const getQueryType = () => {
        let query = stateQuery;
        const formattedDistrictQuery = gql`
            ${districtQuery}
        `;
        const formattedSchoolQuery = gql`
            ${publicSchoolQuery}
        `;
        let variables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            workforce: workforceVariables.state,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_state',
        };
        const districtVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.districtIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_district',
            workforce: workforceVariables.district,
        };
        const schoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.schoolIds,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_publicSchool',
            workforce: workforceVariables.publicSchool,
        };

        const statePrivateVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            workforce: workforceVariables.statePrivate,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_statePrivate',
        };

        const privateSchoolVariables = {
            metricspawns: reportMetricsPawnsQuery?.data?.reportMetricsIds,
            workforce: workforceVariables.privateSchool,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_privateSchool',
        };

        if (reportGroupType === 'districtsAndSchools') {
            return [
                { query: formattedDistrictQuery, variables: districtVariables },
                { query: formattedSchoolQuery, variables: schoolVariables },
            ];
        }
        if (reportGroupType === 'district') {
            query = districtQuery;
            variables = districtVariables;
        }
        if (reportGroupType === 'publicSchool') {
            query = publicSchoolQuery;
            variables = schoolVariables;
        }
        if (reportGroupType === 'statePrivate') {
            query = statePrivateQuery;
            variables = statePrivateVariables;
        }
        if (reportGroupType === 'privateSchool') {
            query = privateSchoolQuery;
            variables = privateSchoolVariables;
        }

        return [{ query, variables }];
    };

    const overviewReportQuery = useGetOverviewReportData('workforce', reportGroupType, getQueryType());

    const queriesLoading =
        overviewReportQuery.some(q => q.isFetching) ||
        reportMetricsPawnsQuery.isFetching ||
        assignmentProgressQuery.isLoading;

    const getData = () => {
        if (!overviewReportQuery.some(q => q.isLoading)) {
            const dataReturned = overviewReportQuery.some(q => q.data);
            if (dataReturned) {
                const data = getWidgetData(overviewReportQuery);
                const mpSummary = data.data.roles.metricspawnSummary;
                const _getScores = ({ total = 0, admin = 0, sa = 0, float = 0, ds = 0 }) => {
                    total === null ? (total = 0) : total;
                    if (!total) {
                        return [
                            { title: 'Administrative', score: '?', color: adminColor },
                            { title: 'Special Assignment', score: '?', color: saColor },
                            { title: 'Float', score: '?', color: floatColor },
                            { title: 'Direct Services', score: '?', color: dsColor },
                        ];
                    }
                    return [
                        {
                            title: 'Administrative',
                            score: (admin / total) * 100,
                            color: adminColor,
                        },
                        { title: 'Special Assignment', score: (sa / total) * 100, color: saColor },
                        { title: 'Float', score: (float / total) * 100, color: floatColor },
                        { title: 'Direct Services', score: (ds / total) * 100, color: dsColor },
                    ];
                };
                return {
                    rn: {
                        title: 'RN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalRn,
                            admin: mpSummary.totalRnAdmin,
                            sa: mpSummary.totalRnSpecial,
                            float: mpSummary.totalRnFloat,
                            ds: mpSummary.totalRnCase,
                        }),
                    },
                    lpn: {
                        title: 'LPN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalLpn,
                            admin: mpSummary.totalLpnAdmin,
                            sa: mpSummary.totalLpnSpecial,
                            float: mpSummary.totalLpnFloat,
                            ds: mpSummary.totalLpnCase,
                        }),
                    },
                    aide: {
                        title: 'AIDE',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: mpSummary.totalAide,
                            admin: mpSummary.totalAideAdmin,
                            sa: mpSummary.totalAideSpecial,
                            float: mpSummary.totalAideFloat,
                            ds: mpSummary.totalAideCase,
                        }),
                    },
                };
            }
        }
        return {
            rn: {
                title: 'RN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            lpn: {
                title: 'LPN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            aide: {
                title: 'AIDE',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
        };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Workforce'}
                        </div>
                    </div>
                    <div className={style.content}>
                        {queriesLoading ? (
                            <Skeleton height={141} />
                        ) : (
                            <>
                                <ChartLegend
                                    items={[
                                        { label: 'Administrative', color: adminColor },
                                        { label: 'Special Assignment', color: saColor },
                                        { label: 'Float', color: floatColor },
                                        { label: 'Direct Services', color: dsColor },
                                    ]}
                                />
                                <div className={style.chartWrapper}>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="RN" chartData={getData().rn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="LPN" chartData={getData().lpn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="AIDE" chartData={getData().aide} />
                                    </div>
                                </div>
                                <div className={style.additionalInfo}>
                                    <Html content={props.settings.additionalInfo} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Workforce.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Workforce.defaultProps = {
    filters: {},
};

export default Workforce;
