import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import CccisdToggle from 'cccisd-toggle';
import { BulkUploader } from 'cccisd-laravel-nexus';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Header from 'js/components/DataEntryHeader/StatePublic';
import DistrictTable from 'js/components/DistrictDataEntryTable';
import StateTable from 'js/components/StateDataEntryTable';
import { getDeployments, getAssignmentProgressData, setBulkDefData, setImportData } from 'js/reducers/nasn.js';
import { useMetricspawnQuery } from 'js/queries';

const PublicSchoolDataEntry = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [modalClosed, setModalClosed] = useState(0);

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();
    const deploymentHandle = reduxStore.selectedDeployment;
    const deploymentId = reduxStore.deploymentId;
    const Boilerplate = window.cccisd.boilerplate;

    const metricspawnQuery = useMetricspawnQuery();

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await dispatch(getDeployments('state'));
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        // Extra check because we don't want this running with deploymentHandle's initial, falsy value
        if (deploymentHandle && !isLoading) {
            // DeploymentPlayer doesn't re-render when deploymentHandle is changed, so we setLoading here so entire page is forced to re-render.
            setIsLoading(true);
            (async () => {
                dispatch(
                    getAssignmentProgressData({
                        groupType: 'state',
                        deploymentHandle,
                    })
                );

                setIsLoading(false);
            })();
        }
    }, [deploymentHandle, modalClosed, isLoading]);

    const handleImportToggle = async importGroup => {
        if (importGroup === 'state') {
            const newImportToggleValue = !reduxStore.importData.statePublic.importStateData;
            if (newImportToggleValue === true) {
                // only save the group as a respondent if toggle is true
                await Axios.post(Boilerplate.route('api.assignmentDeploymentRespondent.store', { deploymentId }), {
                    pawnIds: [metricspawnQuery.data.metricspawn.pawnId],
                });
            }
        }

        await Axios.post(Boilerplate.route('api.assignmentProgress.update'), {
            deploymentId: reduxStore.deploymentId,
            pawnId: metricspawnQuery.data.metricspawn.pawnId,
            flowlist: 'default',
            additional_info: {
                stateEntersPublicData: true,
                importData: {
                    publicSchool: {
                        importStateData:
                            importGroup === 'state' ? !reduxStore.importData.statePublic.importStateData : false,
                        importDistrictData:
                            importGroup === 'district' ? !reduxStore.importData.statePublic.importDistrictData : false,
                        importSchoolData:
                            importGroup === 'school' ? !reduxStore.importData.statePublic.importSchoolData : false,
                    },
                },
            },
        });
        dispatch(
            setImportData({
                statePublic: {
                    importStateData:
                        importGroup === 'state' ? !reduxStore.importData.statePublic.importStateData : false,
                    importDistrictData:
                        importGroup === 'district' ? !reduxStore.importData.statePublic.importDistrictData : false,
                    importSchoolData:
                        importGroup === 'school' ? !reduxStore.importData.statePublic.importSchoolData : false,
                },
            })
        );
    };

    const getBulkUploadDef = async () => {
        if (!deploymentId) {
            setIsLoading(false);
            console.error('No deployment id; could not retrieve bulk upload definition');
            return;
        }
        if (!reduxStore.bulkDefData[deploymentId]) {
            const response = await Axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
                params: { deploymentId },
            });
            const responseBulkDef = _get(response, 'data.result.original.data');

            if (responseBulkDef) {
                dispatch(
                    setBulkDefData({
                        [deploymentId]: responseBulkDef,
                    })
                );
                return responseBulkDef;
            }
            console.error('Could not retrieve bulk upload definition');
        }
        // setIsLoading(false);
        return {};
    };

    const render = () => {
        const { existingAdditionalInfo, stateEntersData, deploymentIsOpen, bulkDefData } = reduxStore;
        const bulkDef = bulkDefData[deploymentId];
        if (!deploymentIsOpen) {
            return (
                <>
                    <Header
                        deploymentId={deploymentId}
                        deploymentHandle={deploymentHandle}
                        stateEntersPublicData={stateEntersData}
                        options={reduxStore.deployments}
                        metricsPawn={metricspawnQuery.data.metricspawn}
                        existingAdditionalInfo={existingAdditionalInfo}
                        deploymentIsOpen={deploymentIsOpen}
                    />
                    <StateTable deploymentIsOpen={deploymentIsOpen} />
                </>
            );
        }
        return (
            <>
                <Header
                    deploymentId={deploymentId}
                    deploymentHandle={deploymentHandle}
                    stateEntersPublicData={stateEntersData}
                    options={reduxStore.deployments}
                    metricsPawn={metricspawnQuery.data.metricspawn}
                    existingAdditionalInfo={existingAdditionalInfo}
                    deploymentIsOpen={deploymentIsOpen}
                />
                {stateEntersData ? (
                    <>
                        <div
                            style={{
                                display: 'grid',
                                gridRowGap: '0.5em',
                                marginBottom: '1em',
                            }}
                        >
                            <CccisdToggle
                                label="Will you import aggregate state data this year?"
                                value={reduxStore.importData.statePublic.importStateData}
                                onChange={() => handleImportToggle('state')}
                            />
                            <CccisdToggle
                                label="Will you import data for each district this year?"
                                value={reduxStore.importData.statePublic.importDistrictData}
                                onChange={() => handleImportToggle('district')}
                            />
                            <CccisdToggle
                                label="Will you import data for each school this year?"
                                value={reduxStore.importData.statePublic.importSchoolData}
                                onChange={() => handleImportToggle('school')}
                            />
                        </div>
                        {reduxStore.importData.statePublic.importStateData ? (
                            <>
                                <div className="alert alert-info" role="alert">
                                    Please reach out to NASN staff at{' '}
                                    <a href="mailto:data@nasn.org" className="alert-link">
                                        data@nasn.org
                                    </a>{' '}
                                    for technical assistance before your first upload.
                                </div>

                                <>
                                    <BulkUploader
                                        handle="deploymentRespondentMetrics"
                                        trigger={
                                            <button type="button" className="btn btn-primary">
                                                Bulk Import State Data
                                            </button>
                                        }
                                        definition={bulkDef ? bulkDef : undefined}
                                        loadDefinition={getBulkUploadDef}
                                        deploymentId={deploymentId}
                                        afterClose={() => setModalClosed(prevVal => prevVal + 1)}
                                    />
                                    {reduxStore.importedInfo.imported && (
                                        <div
                                            style={{
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            {' '}
                                            Last imported on{' '}
                                            {reduxStore.importedInfo.importedDate &&
                                                reduxStore.importedInfo.importedDate.slice(0, 10)}
                                        </div>
                                    )}
                                </>
                            </>
                        ) : (
                            <>
                                {reduxStore.importData.statePublic.importDistrictData ? (
                                    <StateTable
                                        deploymentIsOpen={deploymentIsOpen}
                                        willImportDistrictData={reduxStore.importData.statePublic.importDistrictData}
                                    />
                                ) : (
                                    <>
                                        {reduxStore.importData.statePublic.importSchoolData ? (
                                            <DistrictTable
                                                deploymentIsOpen={deploymentIsOpen}
                                                deploymentHandle={deploymentHandle}
                                                willImportSchoolData={
                                                    reduxStore.importData.statePublic.importSchoolData
                                                }
                                            />
                                        ) : (
                                            <>
                                                <DeploymentPlayer
                                                    deploymentHandle={deploymentHandle}
                                                    pawnId={metricspawnQuery.data.metricspawn.pawnId}
                                                    pawnHash={metricspawnQuery.data.metricspawn.pawnHash}
                                                    disableLayout
                                                    onComplete={() => {
                                                        setIsLoading(true);
                                                        setIsLoading(false);
                                                    }}
                                                    enablePrintMode
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <StateTable />
                )}
            </>
        );
    };

    if (
        isLoading ||
        !reduxStore.assignmentProgressDataLoaded ||
        !reduxStore.deploymentDataLoaded ||
        metricspawnQuery.isLoading
    ) {
        return <Loader loading />;
    }

    return <>{render()}</>;
};

PublicSchoolDataEntry.propTypes = {
    defaultProp: PropTypes.string,
};

export default PublicSchoolDataEntry;
