import React from 'react';
import { Field, CccisdSelect, CccisdFieldWrapper } from 'cccisd-formik';
import { SchoolYear } from './filters';
import FilterHack from './filters/FilterHack';
import filterOptions from './filterOptions.js';

const Fortress = window.cccisd.fortress;

let comparisonOptions = filterOptions.state;
if (Fortress.user.acting.group) {
    if (Fortress.user.acting.group.data_type === 'district') {
        comparisonOptions = filterOptions.district;
    }
    if (Fortress.user.acting.group.data_type === 'publicSchool') {
        comparisonOptions = filterOptions.publicSchool;
    }
    if (Fortress.user.acting.group.data_type === 'privateSchool') {
        comparisonOptions = filterOptions.district;
    }
}

export default [
    {
        name: 'schoolYear',
        label: 'School Year',
        component: props => <SchoolYear {...props} />,
    },
    {
        name: 'comparison1',
        label: 'Comparison Group 1',
        component: () => (
            <Field
                name="comparison1"
                component={CccisdSelect}
                label="Comparison Group 1"
                options={comparisonOptions}
            />
        ),
        initialValue: 'nationalPublic',
    },
    {
        name: 'comparison2',
        label: 'Comparison Group 2',
        component: () => (
            <Field
                name="comparison2"
                component={CccisdSelect}
                label="Comparison Group 2"
                options={comparisonOptions}
            />
        ),
        initialValue: 'nationalAll',
    },
    {
        name: 'filterHack',
        label: 'Enable Apply Filters',
        component: props => {
            return (
                <Field name="filterHack">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <FilterHack form={form} field={field} />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
];
