import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import Skeleton from 'react-loading-skeleton';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { Html } from 'cccisd-wysiwyg';
import MyTable from './table.js';
import style from './style.css';
import comparisonFieldValue from '../../../comparisonFieldValue.js';
import fakeData from './fakeData.js';
import { setComparisonData, setComparisonDataLoaded } from 'js/reducers/nasn.js';

import ancestorGroupsQuery from '../../../ancestorGroups.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;
const userGroup = Fortress.user.acting.group;

const Component = props => {
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);

    useEffect(() => {
        dispatch(setComparisonDataLoaded(false));
        let comparisonType1;
        let comparisonType2;

        (async () => {
            if (props.filters.comparison1 === 'myState') {
                const ancestorGroupResult = await apollo.query({
                    query: ancestorGroupsQuery,
                    fetchPolicy: 'network-only',
                });
                comparisonType1 = {
                    field: 'group',
                    value: ancestorGroupResult.data.groups.state.group.groupId,
                };
            } else if (props.filters.comparison1 === 'myDistrict') {
                const ancestorGroupResult = await apollo.query({
                    query: ancestorGroupsQuery,
                    fetchPolicy: 'network-only',
                });
                comparisonType1 = {
                    field: 'group',
                    value: ancestorGroupResult.data.groups.district.group.groupId,
                };
            } else {
                comparisonType1 = comparisonFieldValue[props.filters.comparison1];
            }

            if (props.filters.comparison2 === 'myState') {
                const ancestorGroupResult = await apollo.query({
                    query: ancestorGroupsQuery,
                    fetchPolicy: 'network-only',
                });
                comparisonType2 = {
                    field: 'group',
                    value: ancestorGroupResult.data.groups.state.group.groupId,
                };
            } else if (props.filters.comparison2 === 'myDistrict') {
                const ancestorGroupResult = await apollo.query({
                    query: ancestorGroupsQuery,
                    fetchPolicy: 'network-only',
                });
                comparisonType2 = {
                    field: 'group',
                    value: ancestorGroupResult.data.groups.district.group.groupId,
                };
            } else {
                comparisonType2 = comparisonFieldValue[props.filters.comparison2];
            }

            const url = Boilerplate.route('app.comparison.report.job');

            const result = await axios.post(url, {
                groupId: userGroup ? userGroup.id : 3,
                schoolYear: props.filters.schoolYear.slice(0, 9) || '2021-2022',
                comparisonType1Field: comparisonType1.field,
                comparisonType1Value: comparisonType1.value,
                comparisonType2Field: comparisonType2.field,
                comparisonType2Value: comparisonType2.value,
            });

            dispatch(setComparisonData(result.data.result));
            dispatch(setComparisonDataLoaded(true));
            return result;
        })();
    }, [hash(props.filters)]);

    return (
        <>
            {props.settings.show && (
                <div style={{ marginBottom: '2em' }}>
                    <div className={style.wrapper}>
                        <div className={style.header}>
                            <div className={style.headerLeft}>
                                <div className={style.headerIcon} />
                                {props.settings.title || 'Workforce'}
                            </div>
                        </div>
                        <Html content={props.settings.additionalInfo} />
                        {!reduxStore.comparisonDataLoaded ? (
                            <Skeleton height={804} />
                        ) : (
                            <MyTable data={reduxStore.comparisonData} filters={props.filters} fakeData={fakeData} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
