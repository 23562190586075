export default {
    state: [
        { value: 'none', label: 'None' },
        { value: 'nationalPublic', label: 'National (Public Schools)' },
        { value: 'nationalPrivate', label: 'National (Private Schools)' },
        { value: 'nationalAll', label: 'National (All Schools)' },
        { value: 'esc1', label: 'ESC Tier 1' },
        { value: 'esc2', label: 'ESC Tier 2' },
        { value: 'esc3', label: 'ESC Tier 3' },
        { value: 'ncesMidwest', label: 'NCES Midwest' },
        { value: 'ncesNortheast', label: 'NCES Northeast' },
        { value: 'ncesSouth', label: 'NCES South' },
        { value: 'ncesWest', label: 'NCES West' },
        { value: 'dhhs1', label: 'DHHS Region 1' },
        { value: 'dhhs2', label: 'DHHS Region 2' },
        { value: 'dhhs3', label: 'DHHS Region 3' },
        { value: 'dhhs4', label: 'DHHS Region 4' },
        { value: 'dhhs5', label: 'DHHS Region 5' },
        { value: 'dhhs6', label: 'DHHS Region 6' },
        { value: 'dhhs7', label: 'DHHS Region 7' },
        { value: 'dhhs8', label: 'DHHS Region 8' },
        { value: 'dhhs9', label: 'DHHS Region 9' },
        { value: 'povertyHigh', label: 'High Poverty' },
        { value: 'povertyMidHigh', label: 'Mid-High Poverty' },
        { value: 'povertyMidLow', label: 'Mid-Low Poverty' },
        { value: 'povertyLow', label: 'Low Poverty' },
        { value: 'ellHigh', label: 'High ELL' },
        { value: 'ellMidHigh', label: 'Mid-High ELL' },
        { value: 'ellMidLow', label: 'Mid-Low ELL' },
        { value: 'ellLow', label: 'Low ELL' },
    ],
    district: [
        { value: 'none', label: 'None' },
        { value: 'nationalPublic', label: 'National (Public Schools)' },
        { value: 'nationalPrivate', label: 'National (Private Schools)' },
        { value: 'nationalAll', label: 'National (All Schools)' },
        { value: 'myState', label: 'My State' },
        { value: 'povertyHigh', label: 'High Poverty' },
        { value: 'povertyMidHigh', label: 'Mid-High Poverty' },
        { value: 'povertyMidLow', label: 'Mid-Low Poverty' },
        { value: 'povertyLow', label: 'Low Poverty' },
        { value: 'ellHigh', label: 'High ELL' },
        { value: 'ellMidHigh', label: 'Mid-High ELL' },
        { value: 'ellMidLow', label: 'Mid-Low ELL' },
        { value: 'ellLow', label: 'Low ELL' },
        { value: 'cityLocale', label: 'City Locale' },
        { value: 'suburbLocale', label: 'Suburb Locale' },
        { value: 'townLocale', label: 'Town Locale' },
        { value: 'ruralLocale', label: 'Rural Locale' },
    ],
    publicSchool: [
        { value: 'none', label: 'None' },
        { value: 'nationalPublic', label: 'National (Public Schools)' },
        { value: 'nationalPrivate', label: 'National (Private Schools)' },
        { value: 'nationalAll', label: 'National (All Schools)' },
        { value: 'myState', label: 'My State' },
        { value: 'myDistrict', label: 'My District' },
        { value: 'povertyHigh', label: 'High Poverty' },
        { value: 'povertyMidHigh', label: 'Mid-High Poverty' },
        { value: 'povertyMidLow', label: 'Mid-Low Poverty' },
        { value: 'povertyLow', label: 'Low Poverty' },
        { value: 'ellHigh', label: 'High ELL' },
        { value: 'ellMidHigh', label: 'Mid-High ELL' },
        { value: 'ellMidLow', label: 'Mid-Low ELL' },
        { value: 'ellLow', label: 'Low ELL' },
        { value: 'cityLocale', label: 'City Locale' },
        { value: 'suburbLocale', label: 'Suburb Locale' },
        { value: 'townLocale', label: 'Town Locale' },
        { value: 'ruralLocale', label: 'Rural Locale' },
    ],
    nonState: [
        { value: 'none', label: 'None' },
        { value: 'nationalPublic', label: 'National (Public Schools)' },
        { value: 'nationalPrivate', label: 'National (Private Schools)' },
        { value: 'nationalAll', label: 'National (All Schools)' },
        { value: 'povertyHigh', label: 'High Poverty' },
        { value: 'povertyMidHigh', label: 'Mid-High Poverty' },
        { value: 'povertyMidLow', label: 'Mid-Low Poverty' },
        { value: 'povertyLow', label: 'Low Poverty' },
        { value: 'ellHigh', label: 'High ELL' },
        { value: 'ellMidHigh', label: 'Mid-High ELL' },
        { value: 'ellMidLow', label: 'Mid-Low ELL' },
        { value: 'ellLow', label: 'Low ELL' },
        { value: 'cityLocale', label: 'City Locale' },
        { value: 'suburbLocale', label: 'Suburb Locale' },
        { value: 'townLocale', label: 'Town Locale' },
        { value: 'ruralLocale', label: 'Rural Locale' },
    ],
};
