import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { setReportDataLoading } from 'js/reducers/nasn.js';
import { useMetricspawnQuery, useDeploymentsQuery } from 'js/queries';

const Component = props => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);

    const metricspawnQuery = useMetricspawnQuery();
    const deploymentsQuery = useDeploymentsQuery(
        [`deployments ${metricspawnQuery.data.groupType}`],
        metricspawnQuery.data.groupType
    );

    const queriesLoading = metricspawnQuery.isLoading || deploymentsQuery.isLoading;

    useEffect(() => {
        if (!props.groupsToCompare) {
            setLoading(true);
            dispatch(setReportDataLoading(true));
            (async () => {
                dispatch(setReportDataLoading(false));
                setLoading(false);
            })();
        }
        setLoading(false);
    }, []);

    const getClosedDeployments = () => {
        const closedDeployments = deploymentsQuery.data?.deploymentsInfo.filter(deployment => {
            return deployment.closed === true || deployment.isOpen === false;
        });
        return closedDeployments;
    };

    const getDeploymentOptions = () => {
        const options = getClosedDeployments().map(deployment => ({
            value: deployment.deploymentHandle,
            label: deployment.label,
        }));
        return options;
    };

    const initialFilterValues = {
        schoolYear: getDeploymentOptions()[0] ? getDeploymentOptions()[0].value : '',
        comparison1: 'nationalPublic',
        comparison2: 'nationalAll',
        filterHack: true,
    };

    if (loading || queriesLoading) {
        return <Loader loading />;
    }

    if (getDeploymentOptions().length < 1) {
        return (
            <div className="alert alert-info">There are currently no completed assessment periods for comparison.</div>
        );
    }

    return (
        <Player
            {...props}
            options={getDeploymentOptions()}
            groupType={props.groupType || metricspawnQuery.data.groupType}
            group={props.group || metricspawnQuery.data.group}
            schoolYear={reduxStore.selectedDeployment}
            initialFilterValues={initialFilterValues}
        />
    );
};

export default Component;
