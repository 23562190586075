export default {
    domains: [
        {
            title: 'State Overview Page',
            messages: [
                'stateSubtitle',
                'statePublicSchoolDE',
                'statePrivateSchoolDE',
                'stateReports',
                'stateResourceCenter',
                'stateManage',
            ],
        },
        {
            title: 'District Overview Page',
            messages: ['districtSubtitle', 'districtDE', 'districtReports', 'districtResourceCenter', 'districtManage'],
        },
        {
            title: 'Public School Overview Page',
            messages: [
                'publicSchoolSubtitle',
                'publicSchoolDE',
                'publicSchoolSurveillance',
                'publicSchoolSurveillanceESC',
                'publicSchoolReports',
                'publicSchoolResourceCenter',
            ],
        },
        {
            title: 'Private School Overview Page',
            messages: [
                'privateSchoolSubtitle',
                'privateSchoolDE',
                'privateSchoolSurveillance',
                'privateSchoolSurveillanceESC',
                'privateSchoolReports',
                'privateSchoolResourceCenter',
            ],
        },
    ],
    messages: {
        stateSubtitle: {
            title: 'Page Subtitle',
            message: 'Welcome! State lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
        },
        statePublicSchoolDE: {
            title: 'Public School Data Entry Description',
            message: `<p><strong>Public School Data Entry</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        statePrivateSchoolDE: {
            title: 'Private School Data Entry Description',
            message: `<p><strong>Private School Data Entry</strong> dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        stateReports: {
            title: 'Reports Description',
            message: `
            <p><strong>Reports</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        stateResourceCenter: {
            title: 'Resource Center Description',
            message: `
            <p><strong>Resource Center</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        stateManage: {
            title: 'Manage Description',
            message: `
            <p><strong>Manage</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        districtSubtitle: {
            title: 'Page Subtitle',
            message:
                'Welcome! District lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
        },
        districtDE: {
            title: 'Data Entry Description',
            message: `<p><strong>Data Entry</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        districtReports: {
            title: 'Reports Description',
            message: `
            <p><strong>Reports</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        districtResourceCenter: {
            title: 'Resource Center Description',
            message: `
            <p><strong>Resource Center</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        districtManage: {
            title: 'Manage Description',
            message: `
            <p><strong>Manage</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        publicSchoolSubtitle: {
            title: 'Page Subtitle',
            message:
                'Welcome! Public School lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
        },
        publicSchoolDE: {
            title: 'Data Entry Description',
            message: `<p><strong>Data Entry</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        publicSchoolSurveillance: {
            title: 'Surveillance Description',
            message: `<p><strong>Surveillance</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        publicSchoolSurveillanceESC: {
            title: 'Surveillance ESC Description',
            message: `<p><strong>Surveillance ESC</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        publicSchoolReports: {
            title: 'Reports Description',
            message: `
            <p><strong>Reports</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        publicSchoolResourceCenter: {
            title: 'Resource Center Description',
            message: `
            <p><strong>Resource Center</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        privateSchoolSubtitle: {
            title: 'Page Subtitle',
            message:
                'Welcome! Private School lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
        },
        privateSchoolDE: {
            title: 'Data Entry Description',
            message: `<p><strong>Data Entry</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        privateSchoolSurveillance: {
            title: 'Surveillance Description',
            message: `<p><strong>Surveillance</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        privateSchoolSurveillanceESC: {
            title: 'Surveillance ESC Description',
            message: `<p><strong>Surveillance ESC</strong> dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
        },
        privateSchoolReports: {
            title: 'Reports Description',
            message: `
            <p><strong>Reports</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
        privateSchoolResourceCenter: {
            title: 'Resource Center Description',
            message: `
            <p><strong>Resource Center</strong> dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat.</p>`,
        },
    },
};
