export default {
    publicSchool: [
        'PUB_SCH_TOTAL_RN_VISITS',
        'PUB_SCH_TOTAL_RN_VISITS_CLASS',
        'PUB_SCH_TOTAL_RN_VISITS_HOME',
        'PUB_SCH_TOTAL_RN_VISITS_911',
        'PUB_SCH_TOTAL_LPN_VISITS',
        'PUB_SCH_TOTAL_LPN_VISITS_CLASS',
        'PUB_SCH_TOTAL_LPN_VISITS_HOME',
        'PUB_SCH_TOTAL_LPN_VISITS_911',
        'PUB_SCH_TOTAL_AIDE_VISITS',
        'PUB_SCH_TOTAL_AIDE_VISITS_CLASS',
        'PUB_SCH_TOTAL_AIDE_VISITS_HOME',
        'PUB_SCH_TOTAL_AIDE_VISITS_911',
        'PUB_SCH_ANN_AGG_RN_CLASS',
        'PUB_SCH_ANN_AGG_RN_911',
        'PUB_SCH_ANN_AGG_RN_HOME',
        'PUB_SCH_ANN_AGG_LPN_CLASS',
        'PUB_SCH_ANN_AGG_LPN_911',
        'PUB_SCH_ANN_AGG_LPN_HOME',
        'PUB_SCH_ANN_AGG_AIDE_CLASS',
        'PUB_SCH_ANN_AGG_AIDE_911',
        'PUB_SCH_ANN_AGG_AIDE_HOME',
    ],
    privateSchool: [
        'PRIV_SCH_TOTAL_RN_VISITS',
        'PRIV_SCH_TOTAL_RN_VISITS_CLASS',
        'PRIV_SCH_TOTAL_RN_VISITS_HOME',
        'PRIV_SCH_TOTAL_RN_VISITS_911',
        'PRIV_SCH_TOTAL_LPN_VISITS',
        'PRIV_SCH_TOTAL_LPN_VISITS_CLASS',
        'PRIV_SCH_TOTAL_LPN_VISITS_HOME',
        'PRIV_SCH_TOTAL_LPN_VISITS_911',
        'PRIV_SCH_TOTAL_AIDE_VISITS',
        'PRIV_SCH_TOTAL_AIDE_VISITS_CLASS',
        'PRIV_SCH_TOTAL_AIDE_VISITS_HOME',
        'PRIV_SCH_TOTAL_AIDE_VISITS_911',
        'PRIV_SCH_ANN_AGG_RN_CLASS',
        'PRIV_SCH_ANN_AGG_RN_911',
        'PRIV_SCH_ANN_AGG_RN_HOME',
        'PRIV_SCH_ANN_AGG_LPN_CLASS',
        'PRIV_SCH_ANN_AGG_LPN_911',
        'PRIV_SCH_ANN_AGG_LPN_HOME',
        'PRIV_SCH_ANN_AGG_AIDE_CLASS',
        'PRIV_SCH_ANN_AGG_AIDE_911',
        'PRIV_SCH_ANN_AGG_AIDE_HOME',
    ],
    district: [
        'DIST_TOTAL_RN_VISITS',
        'DIST_TOTAL_RN_VISITS_CLASS',
        'DIST_TOTAL_RN_VISITS_HOME',
        'DIST_TOTAL_RN_VISITS_911',
        'DIST_TOTAL_LPN_VISITS',
        'DIST_TOTAL_LPN_VISITS_CLASS',
        'DIST_TOTAL_LPN_VISITS_HOME',
        'DIST_TOTAL_LPN_VISITS_911',
        'DIST_TOTAL_AIDE_VISITS',
        'DIST_TOTAL_AIDE_VISITS_CLASS',
        'DIST_TOTAL_AIDE_VISITS_HOME',
        'DIST_TOTAL_AIDE_VISITS_911',
        'DIST_ANN_AGG_RN_CLASS',
        'DIST_ANN_AGG_RN_911',
        'DIST_ANN_AGG_RN_HOME',
        'DIST_ANN_AGG_LPN_CLASS',
        'DIST_ANN_AGG_LPN_911',
        'DIST_ANN_AGG_LPN_HOME',
        'DIST_ANN_AGG_AIDE_CLASS',
        'DIST_ANN_AGG_AIDE_911',
        'DIST_ANN_AGG_AIDE_HOME',
    ],
    state: [
        'ST_TOTAL_RN_VISITS',
        'ST_TOTAL_RN_VISITS_CLASS',
        'ST_TOTAL_RN_VISITS_HOME',
        'ST_TOTAL_RN_VISITS_911',
        'ST_TOTAL_LPN_VISITS',
        'ST_TOTAL_LPN_VISITS_CLASS',
        'ST_TOTAL_LPN_VISITS_HOME',
        'ST_TOTAL_LPN_VISITS_911',
        'ST_TOTAL_AIDE_VISITS',
        'ST_TOTAL_AIDE_VISITS_CLASS',
        'ST_TOTAL_AIDE_VISITS_HOME',
        'ST_TOTAL_AIDE_VISITS_911',
        'ST_ANN_AGG_RN_CLASS',
        'ST_ANN_AGG_RN_911',
        'ST_ANN_AGG_RN_HOME',
        'ST_ANN_AGG_LPN_CLASS',
        'ST_ANN_AGG_LPN_911',
        'ST_ANN_AGG_LPN_HOME',
        'ST_ANN_AGG_AIDE_CLASS',
        'ST_ANN_AGG_AIDE_911',
        'ST_ANN_AGG_AIDE_HOME',
    ],
    statePrivate: [
        'ST_PRIV_SCH_TOTAL_RN_VISITS',
        'ST_PRIV_SCH_TOTAL_RN_VISITS_CLASS',
        'ST_PRIV_SCH_TOTAL_RN_VISITS_HOME',
        'ST_PRIV_SCH_TOTAL_RN_VISITS_911',
        'ST_PRIV_SCH_TOTAL_LPN_VISITS',
        'ST_PRIV_SCH_TOTAL_LPN_VISITS_CLASS',
        'ST_PRIV_SCH_TOTAL_LPN_VISITS_HOME',
        'ST_PRIV_SCH_TOTAL_LPN_VISITS_911',
        'ST_PRIV_SCH_TOTAL_AIDE_VISITS',
        'ST_PRIV_SCH_TOTAL_AIDE_VISITS_CLASS',
        'ST_PRIV_SCH_TOTAL_AIDE_VISITS_HOME',
        'ST_PRIV_SCH_TOTAL_AIDE_VISITS_911',
        'ST_PRIV_SCH_ANN_AGG_RN_CLASS',
        'ST_PRIV_SCH_ANN_AGG_RN_911',
        'ST_PRIV_SCH_ANN_AGG_RN_HOME',
        'ST_PRIV_SCH_ANN_AGG_LPN_CLASS',
        'ST_PRIV_SCH_ANN_AGG_LPN_911',
        'ST_PRIV_SCH_ANN_AGG_LPN_HOME',
        'ST_PRIV_SCH_ANN_AGG_AIDE_CLASS',
        'ST_PRIV_SCH_ANN_AGG_AIDE_911',
        'ST_PRIV_SCH_ANN_AGG_AIDE_HOME',
    ],
};
