import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import { setSelectedDeployment } from 'js/reducers/nasn.js';

const SchoolYear = props => {
    const dispatch = useDispatch();

    const handleChangeDeployment = async (e, form) => {
        form.setFieldValue('schoolYear', e.target.value);
        await dispatch(setSelectedDeployment(e.target.value));
    };

    return (
        <Field name="schoolYear" label="School Year">
            {({ field, form }) => (
                <div style={props.settings.hidden ? { display: 'none' } : {}}>
                    <CccisdFieldWrapper field={field} form={form} label="School Year">
                        <select {...field} className="form-control" onChange={e => handleChangeDeployment(e, form)}>
                            {props.settings.options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </CccisdFieldWrapper>
                </div>
            )}
        </Field>
    );
};

SchoolYear.propTypes = {
    settings: PropTypes.object,
};

export default SchoolYear;
