import React from 'react';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from '../Footer';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const MainLayout = ({ className = 'container', showSecondaryNav = true, children }) => {
    // Only public and private school users with surveillance turned on should
    // see the surveillance and surveillance ESC pages
    if (
        !['publicSchoolUser', 'privateSchoolUser'].includes(Fortress.user.acting.role.handle) ||
        !Fortress.user.acting.role_data.enableSurveillance
    ) {
        AppDefs.routes = AppDefs.routes.filter(
            route => route.handle !== 'surveillance' && route.handle !== 'surveillanceESC'
        );
        AppDefs.navs[1].nav = AppDefs.navs[1].nav.filter(
            nav => nav.handle !== 'surveillance' && nav.handle !== 'surveillanceESC'
        );
    }

    // Turn off Data Entry tab for surveillance users
    if (
        ['publicSchoolUser', 'privateSchoolUser'].includes(Fortress.user.acting.role.handle) &&
        Fortress.user.acting.role_data.enableSurveillance === 1
    ) {
        AppDefs.routes = AppDefs.routes.filter(route => route.handle !== 'data_entry');
        AppDefs.navs[1].nav = AppDefs.navs[1].nav.filter(nav => nav.handle !== 'data_entry');
    }

    return (
        <div className={style.wrapper}>
            <div className={style.body}>
                {showSecondaryNav && Fortress.auth() && (
                    <SecondaryNav className={className} navs={AppDefs.navs} routes={AppDefs.routes} />
                )}
                <div className={className}>{children}</div>
            </div>

            <div className={style.footer}>
                <Footer className={className} />
            </div>
        </div>
    );
};

export default MainLayout;
