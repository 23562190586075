import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

const ChartLegend = props => {
    return (
        <div className={style.container}>
            <div className={style.left}>KEY</div>
            <div className={style.right}>
                {props.items.map(item => {
                    return (
                        <div key={item.label} className={style.itemContainer}>
                            <div className={style.colorBox} style={{ backgroundColor: item.color }} />
                            <div style={{ fontWeight: 'bold' }}>{item.label}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ChartLegend.propTypes = {
    items: PropTypes.array,
};

ChartLegend.defaultProps = {
    items: [
        { label: 'Administrative', color: '#a3d4ff' },
        { label: 'Special Assignment', color: '#a3d4ff' },
        { label: 'Float', color: '#a3d4ff' },
        { label: 'Direct Services', color: '#a3d4ff' },
    ],
};

export default ChartLegend;
