import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Table from 'cccisd-graphql-table';
import Tooltip from 'cccisd-tooltip';
import Modal from 'cccisd-modal';
import { BulkUploader } from 'cccisd-laravel-nexus';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import schoolsQuery from './schoolsQuery.graphql';
import IconCheck from 'cccisd-icons/stack-check';
import IconInProgress from 'cccisd-icons/stack4';
import IconPencil from 'cccisd-icons/pencil5';
import deploymentsQuery from './allDeploymentsQuery.graphql';
import { setBulkDefData } from 'js/reducers/nasn.js';

const StateDataEntryTable = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [schoolDeploymentId, setSchoolDeploymentId] = useState('');

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const Boilerplate = window.cccisd.boilerplate;

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const result = await apollo.query({
                query: deploymentsQuery,
                fetchPolicy: 'network-only',
            });

            const currentSelectedYear = reduxStore.selectedDeployment.slice(0, 9);

            const schoolDeploymentData = result.data.flows.deploymentList.find(d => d.label === currentSelectedYear);
            if (schoolDeploymentData) {
                setSchoolDeploymentId(schoolDeploymentData.deploymentId);
            }
            setIsLoading(false);
        })();
    }, []);

    const getBulkUploadDef = async () => {
        if (!schoolDeploymentId) {
            setIsLoading(false);
            console.error('No deployment id; could not retrieve bulk upload definition');
            return;
        }
        const deploymentId = schoolDeploymentId;

        setDeploymentRespondents();

        if (!reduxStore.bulkDefData[deploymentId]) {
            const response = await Axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
                params: { deploymentId },
            });
            const responseBulkDef = _get(response, 'data.result.original.data');
            if (responseBulkDef) {
                dispatch(
                    setBulkDefData({
                        [deploymentId]: responseBulkDef,
                    })
                );
                return responseBulkDef;
            }
            console.error('Could not retrieve bulk upload definition');
        }
        setIsLoading(false);
        return {};
    };

    const setDeploymentRespondents = async () => {
        // only save the group as a respondent if toggle is true
        if (schoolDeploymentId && schoolDeploymentId !== '' && props.willImportSchoolData) {
            await Axios.post(Boilerplate.route('app.set.deployment.respondents'), {
                groupType: 'privateSchool',
                deploymentId: schoolDeploymentId,
            });
        }
    };

    const schoolDeploymentHandle = props.deploymentHandle.slice(0, 9) + '_privateSchool';

    const renderSurveyProgress = (row, value, flow) => {
        if (value === null) {
            return (
                <div
                    style={{
                        padding: '0 1em',
                        color: '#aaa',
                        fontStyle: 'italic',
                    }}
                >
                    Incomplete
                </div>
            );
        }

        if (props.willImportSchoolData && row[`childRoles.metricspawn.${flow}.completedDate`] !== null) {
            return (
                <div>
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#5cb85c',
                            fontStyle: 'italic',
                        }}
                    >
                        Imported
                    </div>
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#5cb85c',
                            fontStyle: 'italic',
                        }}
                    >
                        {row[`childRoles.metricspawn.${flow}.completedDate`]}
                    </div>
                </div>
            );
        }
        return (
            <>
                {value === 'Complete' ? (
                    <>
                        <div
                            style={{
                                padding: '0 1em',
                                color: '#5cb85c',
                            }}
                        >
                            <IconCheck /> {value}
                        </div>
                        <div
                            style={{
                                padding: '0 1em',
                                color: '#5cb85c',
                                fontStyle: 'italic',
                            }}
                        >
                            {row['childRoles.metricspawn.absenteeism.completedDate']}
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#11385a',
                        }}
                    >
                        <IconInProgress /> {value}
                    </div>
                )}
            </>
        );
    };

    const renderButton = schoolDeploymentIsOpen => {
        if (!props.deploymentIsOpen || (typeof schoolDeploymentIsOpen !== 'object' && !schoolDeploymentIsOpen)) {
            return (
                <Tooltip title="Data entry for this school year is now closed.">
                    <button type="button" className="btn btn-xs btn-primary" disabled>
                        <IconPencil spaceRight />
                        Enter Data
                    </button>
                </Tooltip>
            );
        }

        return (
            <button type="button" className="btn btn-xs btn-primary">
                <IconPencil spaceRight />
                Enter Data
            </button>
        );
    };

    let columns = [
        {
            name: 'group.label',
            label: 'School',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.pawn.pawnId',
            label: 'Data Entry',
            sort: true,
            className: 'text-center',
            render: ({ value, row }) => {
                return (
                    <Modal
                        trigger={renderButton(row['childRoles.metricspawn.assignmentProgress.deployment.isOpen'])}
                        size="large"
                        title={row['group.label']}
                    >
                        <DeploymentPlayer
                            deploymentHandle={schoolDeploymentHandle}
                            pawnId={value}
                            pawnHash={row['childRoles.metricspawn.pawn.pawnHash']}
                            disableLayout
                        />
                    </Modal>
                );
            },
        },
        {
            name: 'childRoles.metricspawn.workforce.status',
            label: 'Workforce',
            sort: true,
            render: ({ value, row }) => renderSurveyProgress(row, value, 'workforce'),
        },
        {
            name: 'childRoles.metricspawn.chronicConditions.status',
            label: 'Chronic Conditions',
            sort: true,
            render: ({ value, row }) => renderSurveyProgress(row, value, 'chronicConditions'),
        },
        {
            name: 'childRoles.metricspawn.absenteeism.status',
            label: 'Absenteeism',
            sort: true,
            render: ({ value, row }) => renderSurveyProgress(row, value, 'absenteeism'),
        },
        {
            name: 'childRoles.metricspawn.healthVisits.status',
            label: 'Health Office Visit',
            sort: true,
            render: ({ value, row }) => renderSurveyProgress(row, value, 'healthVisits'),
        },
    ];

    if (props.willImportSchoolData) columns = columns.filter(x => x.label !== 'Data Entry');

    if (isLoading) {
        return <Loader loading />;
    }

    const bulkDef = reduxStore.bulkDefData[schoolDeploymentId];

    return (
        <>
            {props.willImportSchoolData && (
                <>
                    <div className="alert alert-info" role="alert">
                        Please reach out to NASN staff at{' '}
                        <a href="mailto:data@nasn.org" className="alert-link">
                            data@nasn.org
                        </a>{' '}
                        for technical assistance before your first upload.
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <BulkUploader
                            handle="deploymentRespondentMetrics"
                            trigger={
                                <button type="button" className="btn btn-primary">
                                    Bulk Import School Data
                                </button>
                            }
                            definition={bulkDef ? bulkDef : undefined}
                            loadDefinition={getBulkUploadDef}
                            deploymentId={schoolDeploymentId}
                            afterClose={() => tableRef.current.loadData()}
                            modalTitle="Bulk Import School Data"
                        />
                    </div>
                </>
            )}

            <Table
                ref={tableRef}
                rowKey="group.groupId"
                query={schoolsQuery}
                graphqlVariables={{
                    deploymentHandle: schoolDeploymentHandle,
                    deploymentId: schoolDeploymentId,
                }}
                columns={columns}
                useTaskMaster
            />
        </>
    );
};

StateDataEntryTable.propTypes = {
    deploymentIsOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    deploymentHandle: PropTypes.string,
    willImportSchoolData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default StateDataEntryTable;
