import React from 'react';
import PropTypes from 'prop-types';
import { updateParams, getSavedParams } from 'js/reducers/params.js';
import { connect } from 'react-redux';
import Form from './form.js';
import Loader from 'cccisd-loader';
import reportFields from 'js/reducers/siteFields/reportFields.js';

class Params extends React.Component {
    static propTypes = {
        params: PropTypes.object,
        updateParams: PropTypes.func,
        getSavedParams: PropTypes.func,
    };

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        await this.props.getSavedParams(reportFields, 'report');
        this.setState({ loading: false });
    };

    onSubmit = async values => {
        await this.props.updateParams(values, 'report');
    };

    render() {
        if (this.state.loading) {
            return <Loader loading />;
        }
        return (
            <Form
                initialValues={this.props.params}
                onSubmit={this.onSubmit}
                fields={Object.keys(this.props.params)}
            />
        );
    }
}

const mapStateToProps = state => ({
    params: state.app.params.reportFields,
});

export default connect(mapStateToProps, { updateParams, getSavedParams })(Params);
