/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import style from './style.css';
import _round from 'lodash/round';
import _isPlainObject from 'lodash/isPlainObject';

const Table = props => {
    const data = Object.keys(props.data).length > 0 ? props.data : props.fakeData;

    const comparisonGroupTitle1 = props.groupLabels[0];
    const comparisonGroupTitle2 = props.groupLabels[1];

    const { currentGroup } = data;
    const comparisonGroupKeys = Object.keys(data).filter(x => x !== 'currentGroup');
    const comparisonGroup1 = data[comparisonGroupKeys[0]];
    const comparisonGroup2 = data[comparisonGroupKeys[1]];

    const currentGroupHasData =
        _isPlainObject(currentGroup) && Object.keys(currentGroup).length > 0;
    const comparisonGroup1ExistsWithData =
        _isPlainObject(comparisonGroup1) && Object.keys(comparisonGroup1).length > 0;
    const comparisonGroup2ExistsWithData =
        _isPlainObject(comparisonGroup2) && Object.keys(comparisonGroup2).length > 0;

    if (!currentGroupHasData) {
        return (
            <div className="alert alert-info">
                Could not find data for your group for this time period.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ tableLayout: 'fixed', width: '100%' }}>
            <colgroup>
                <col style={{ width: '60px' }} />
                <col style={{ width: '60px' }} />
                {comparisonGroup1ExistsWithData && <col style={{ width: '60px' }} />}
                {comparisonGroup2ExistsWithData && <col style={{ width: '60px' }} />}
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading}>Domain/Factor</th>
                    <th className={style.tgHeading}>{currentGroup.State_Name || 'Self'}</th>
                    {comparisonGroup1ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle1}</th>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle2}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Student Population</td>
                    <td className={style.tgCell}>
                        {currentGroup.Student_Population.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Student_Population.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Student_Population.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Total Visits Anually (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        <div>{currentGroup.TOTAL_HEALTH_VISITS.toLocaleString()}</div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup1.TOTAL_HEALTH_VISITS.toLocaleString()}</div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>{comparisonGroup2.TOTAL_HEALTH_VISITS.toLocaleString()}</div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Visits managed by RN
                        <ul>
                            <li>Sent back to class</li>
                            <li>Sent home</li>
                            <li>911</li>
                        </ul>
                    </td>
                    <td className={style.tgCell}>
                        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                            <li style={{ fontWeight: 'bold' }}>
                                {currentGroup.Proportion_RN_VISITS &&
                                    _round(currentGroup.Proportion_RN_VISITS * 100) +
                                        '% of visits were managed by RN'}
                            </li>
                            <li>
                                {currentGroup.Proportion_RN_CLASS &&
                                    _round(currentGroup.Proportion_RN_CLASS * 100) +
                                        '% back to class'}
                            </li>
                            <li>
                                {currentGroup.Proportion_RN_HOME &&
                                    _round(currentGroup.Proportion_RN_HOME * 100) + '% sent home'}
                            </li>
                            <li>
                                {currentGroup.Proportion_RN_911 &&
                                    _round(currentGroup.Proportion_RN_911 * 100) + '% 911'}
                            </li>
                        </ul>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup1.Proportion_RN_VISITS &&
                                        _round(comparisonGroup1.Proportion_RN_VISITS * 100) +
                                            '% of visits were managed by RN'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_RN_CLASS &&
                                        _round(comparisonGroup1.Proportion_RN_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_RN_HOME &&
                                        _round(comparisonGroup1.Proportion_RN_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_RN_911 &&
                                        _round(comparisonGroup1.Proportion_RN_911 * 100) + '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup2.Proportion_RN_VISITS &&
                                        _round(comparisonGroup2.Proportion_RN_VISITS * 100) +
                                            '% of visits were managed by RN'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_RN_CLASS &&
                                        _round(comparisonGroup2.Proportion_RN_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_RN_HOME &&
                                        _round(comparisonGroup2.Proportion_RN_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_RN_911 &&
                                        _round(comparisonGroup2.Proportion_RN_911 * 100) + '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Visits managed by LPN/LVN
                        <ul>
                            <li>Sent back to class</li>
                            <li>Sent home</li>
                            <li>911</li>
                        </ul>
                    </td>
                    <td className={style.tgCell}>
                        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                            <li style={{ fontWeight: 'bold' }}>
                                {currentGroup.Proportion_LPN_VISITS &&
                                    _round(currentGroup.Proportion_LPN_VISITS * 100) +
                                        '% of visits were managed by LPN'}
                            </li>
                            <li>
                                {currentGroup.Proportion_LPN_CLASS &&
                                    _round(currentGroup.Proportion_LPN_CLASS * 100) +
                                        '% back to class'}
                            </li>
                            <li>
                                {currentGroup.Proportion_LPN_HOME &&
                                    _round(currentGroup.Proportion_LPN_HOME * 100) + '% sent home'}
                            </li>
                            <li>
                                {currentGroup.Proportion_LPN_911 &&
                                    _round(currentGroup.Proportion_LPN_911 * 100) + '% 911'}
                            </li>
                        </ul>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup1.Proportion_LPN_VISITS &&
                                        _round(comparisonGroup1.Proportion_LPN_VISITS * 100) +
                                            '% of visits were managed by LPN'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_LPN_CLASS &&
                                        _round(comparisonGroup1.Proportion_LPN_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_LPN_HOME &&
                                        _round(comparisonGroup1.Proportion_LPN_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_LPN_911 &&
                                        _round(comparisonGroup1.Proportion_LPN_911 * 100) + '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup2.Proportion_LPN_VISITS &&
                                        _round(comparisonGroup2.Proportion_LPN_VISITS * 100) +
                                            '% of visits were managed by LPN'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_LPN_CLASS &&
                                        _round(comparisonGroup2.Proportion_LPN_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_LPN_HOME &&
                                        _round(comparisonGroup2.Proportion_LPN_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_LPN_911 &&
                                        _round(comparisonGroup2.Proportion_LPN_911 * 100) + '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Visits managed by Health Aide
                        <ul>
                            <li>Sent back to class</li>
                            <li>Sent home</li>
                            <li>911</li>
                        </ul>
                    </td>
                    <td className={style.tgCell}>
                        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                            <li style={{ fontWeight: 'bold' }}>
                                {currentGroup.Proportion_AIDE_VISITS &&
                                    _round(currentGroup.Proportion_AIDE_VISITS * 100) +
                                        '% of visits were managed by Health Aide'}
                            </li>
                            <li>
                                {currentGroup.Proportion_AIDE_CLASS &&
                                    _round(currentGroup.Proportion_AIDE_CLASS * 100) +
                                        '% back to class'}
                            </li>
                            <li>
                                {currentGroup.Proportion_AIDE_HOME &&
                                    _round(currentGroup.Proportion_AIDE_HOME * 100) + '% sent home'}
                            </li>
                            <li>
                                {currentGroup.Proportion_AIDE_911 &&
                                    _round(currentGroup.Proportion_AIDE_911 * 100) + '% 911'}
                            </li>
                        </ul>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup1.Proportion_AIDE_VISITS &&
                                        _round(comparisonGroup1.Proportion_AIDE_VISITS * 100) +
                                            '% of visits were managed by Health Aide'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_AIDE_CLASS &&
                                        _round(comparisonGroup1.Proportion_AIDE_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_AIDE_HOME &&
                                        _round(comparisonGroup1.Proportion_AIDE_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup1.Proportion_AIDE_911 &&
                                        _round(comparisonGroup1.Proportion_AIDE_911 * 100) +
                                            '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup2.Proportion_AIDE_VISITS &&
                                        _round(comparisonGroup2.Proportion_AIDE_VISITS * 100) +
                                            '% of visits were managed by Health Aide'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_AIDE_CLASS &&
                                        _round(comparisonGroup2.Proportion_AIDE_CLASS * 100) +
                                            '% back to class'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_AIDE_HOME &&
                                        _round(comparisonGroup2.Proportion_AIDE_HOME * 100) +
                                            '% sent home'}
                                </li>
                                <li>
                                    {comparisonGroup2.Proportion_AIDE_911 &&
                                        _round(comparisonGroup2.Proportion_AIDE_911 * 100) +
                                            '% 911'}
                                </li>
                            </ul>
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default Table;
