export default {
    publicSchool: [
        'PUB_SCH_TOTAL_RN_VISITS',
        'PUB_SCH_TOTAL_RN_VISITS_CLASS',
        'PUB_SCH_TOTAL_RN_VISITS_HOME',
        'PUB_SCH_TOTAL_RN_VISITS_911',
        'PUB_SCH_TOTAL_LPN_VISITS',
        'PUB_SCH_TOTAL_LPN_VISITS_CLASS',
        'PUB_SCH_TOTAL_LPN_VISITS_HOME',
        'PUB_SCH_TOTAL_LPN_VISITS_911',
        'PUB_SCH_TOTAL_AIDE_VISITS',
        'PUB_SCH_TOTAL_AIDE_VISITS_CLASS',
        'PUB_SCH_TOTAL_AIDE_VISITS_HOME',
        'PUB_SCH_TOTAL_AIDE_VISITS_911',
        'PUB_SCH_ANN_AGG_RN_CLASS',
        'PUB_SCH_ANN_AGG_RN_911',
        'PUB_SCH_ANN_AGG_RN_HOME',
        'PUB_SCH_ANN_AGG_LPN_CLASS',
        'PUB_SCH_ANN_AGG_LPN_911',
        'PUB_SCH_ANN_AGG_LPN_HOME',
        'PUB_SCH_ANN_AGG_AIDE_CLASS',
        'PUB_SCH_ANN_AGG_AIDE_911',
        'PUB_SCH_ANN_AGG_AIDE_HOME',
    ],
};
