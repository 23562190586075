import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { getDeployments, setReportDataLoading } from 'js/reducers/nasn.js';

const Component = props => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);
    const { childReportOpen, tabSelected } = props;

    useEffect(() => {
        setLoading(true);
        dispatch(setReportDataLoading(true));
        (async () => {
            await dispatch(getDeployments('individualESC'));
            dispatch(setReportDataLoading(false));
            setLoading(false);
        })();
    }, [childReportOpen, tabSelected]);

    return loading ? (
        <Loader loading />
    ) : (
        <Player
            {...props}
            options={reduxStore.deployments}
            schoolYear={reduxStore.selectedDeployment}
            selectedDeployment={reduxStore.selectedDeployment}
        />
    );
};

export default Component;
