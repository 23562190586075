import React, { useEffect } from 'react';
import OverviewReport from 'js/vendor/reports/reportTemplates/Overview';
import Loader from 'cccisd-loader';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDeployment } from 'js/reducers/nasn.js';
import { useMetricspawnQuery, useDeploymentsQuery, useGetAssignmentProgress } from 'js/queries';
import PublicSchoolsTable from './PublicSchoolsTable';
import DistrictsTable from './DistrictsTable';

const appDefs = window.cccisd.appDefs;

const StateOverview = props => {
    const { tabSelected } = props;
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);
    const metricspawnQuery = useMetricspawnQuery();
    const reportType = 'state';
    const deploymentsQuery = useDeploymentsQuery([`deployments ${reportType}`], reportType);
    const selectedDeployment = deploymentsQuery.data?.selectedDeployment;
    const assignmentProgressQuery = useGetAssignmentProgress(
        [`${selectedDeployment} state assignmentProgress`],
        selectedDeployment,
        'state'
    );
    const deploymentHandle = deploymentsQuery.data?.selectedDeployment;

    const queriesLoading =
        metricspawnQuery.isLoading || deploymentsQuery.isLoading || assignmentProgressQuery.isLoading;

    const reportList = appDefs.reports.list;
    const reportSettings = reportList.find(r => r.reportTemplateHandle === 'overview').reportTemplateSettings;

    useEffect(() => {
        if (deploymentHandle) {
            dispatch(setSelectedDeployment(deploymentHandle));
        }
    }, [deploymentHandle]);

    if (queriesLoading) {
        return <Loader loading />;
    }

    return (
        <>
            <div style={{ maxWidth: '870px' }}>
                {reportSettings ? (
                    <OverviewReport.player
                        settings={reportSettings}
                        reportGroupType="state"
                        tabSelected={tabSelected}
                        deploymentHandle={deploymentHandle}
                        deployments={deploymentsQuery.data}
                        overviewType="Public Schools"
                    />
                ) : (
                    <div>Could not find an overview report.</div>
                )}
            </div>
            <>
                {(!assignmentProgressQuery.data?.importData.stateEntersPublicData ||
                    assignmentProgressQuery.data?.importData.importData.publicSchool.importDistrictData === true ||
                    assignmentProgressQuery.data?.importData.importData.publicSchool.importSchoolData === true) && (
                    <div style={{ marginTop: '1em' }}>
                        <h3>My Districts</h3>
                        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                            This table shows reports if districts entered aggregate data.
                        </span>
                        <DistrictsTable
                            reportSettings={reportSettings}
                            deploymentHandle={reduxStore.selectedDeployment}
                        />
                        <h3>My Public Schools</h3>
                        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                            This table shows reports if schools entered their own data.
                        </span>
                        <PublicSchoolsTable
                            reportSettings={reportSettings}
                            deploymentHandle={reduxStore.selectedDeployment}
                        />
                    </div>
                )}
            </>
        </>
    );
};

export default StateOverview;
