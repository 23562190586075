export default {
    none: { field: '', value: '' },
    nationalPublic: { field: 'national', value: 'Public Schools' },
    nationalPrivate: { field: 'national', value: 'Private Schools' },
    nationalAll: { field: 'national', value: 'All Schools' },
    esc1: { field: 'ESC_Tier', value: 'Tier 1' },
    esc2: { field: 'ESC_Tier', value: 'Tier 2' },
    esc3: { field: 'ESC_Tier', value: 'Tier 3' },
    ncesMidwest: { field: 'NCES_Region', value: 'Midwest' },
    ncesNortheast: { field: 'NCES_Region', value: 'Northeast' },
    ncesSouth: { field: 'NCES_Region', value: 'South' },
    ncesWest: { field: 'NCES_Region', value: 'West' },
    dhhs1: { field: 'DHHS_Region', value: 'Region 1' },
    dhhs2: { field: 'DHHS_Region', value: 'Region 2' },
    dhhs3: { field: 'DHHS_Region', value: 'Region 3' },
    dhhs4: { field: 'DHHS_Region', value: 'Region 4' },
    dhhs5: { field: 'DHHS_Region', value: 'Region 5' },
    dhhs6: { field: 'DHHS_Region', value: 'Region 6' },
    dhhs7: { field: 'DHHS_Region', value: 'Region 7' },
    dhhs8: { field: 'DHHS_Region', value: 'Region 8' },
    dhhs9: { field: 'DHHS_Region', value: 'Region 9' },
    popHigh: { field: 'population', value: 'High' },
    popMidHigh: { field: 'population', value: 'Mid-High' },
    popMidLow: { field: 'population', value: 'Mid-Low' },
    popLow: { field: 'population', value: 'Low' },
    povertyHigh: { field: 'Poverty_Category', value: 'High' },
    povertyMidHigh: { field: 'Poverty_Category', value: 'Mid-High' },
    povertyMidLow: { field: 'Poverty_Category', value: 'Mid-Low' },
    povertyLow: { field: 'Poverty_Category', value: 'Low' },
    ellHigh: { field: 'English_Language_Proficiency', value: 'High' },
    ellMidHigh: { field: 'English_Language_Proficiency', value: 'Mid-High' },
    ellMidLow: { field: 'English_Language_Proficiency', value: 'Mid-Low' },
    ellLow: { field: 'English_Language_Proficiency', value: 'Low' },
    cityLocale: { field: 'NCES_Locale_Designation', value: 'City' },
    suburbLocale: { field: 'NCES_Locale_Designation', value: 'Suburb' },
    townLocale: { field: 'NCES_Locale_Designation', value: 'Town' },
    ruralLocale: { field: 'NCES_Locale_Designation', value: 'Rural' },
};
