import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import axios from 'cccisd-axios';
import Toggle from 'cccisd-toggle';
import Loader from 'cccisd-loader';
import Tooltip from 'cccisd-tooltip';
import { Html } from 'cccisd-wysiwyg';
import { getSavedParams } from 'js/reducers/params.js';
import { setSelectedDeployment, setDistrictEntersData, setDeploymentId, setImportData } from 'js/reducers/nasn.js';
import dataEntryFields from 'js/reducers/siteFields/dataEntryFields.js';
import style from './style.css';
import IconQuestion from 'cccisd-icons/question4';
import { useQueryClient } from 'cccisd-react-query';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const DistrictDataEntryHeader = props => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);
    const queryClient = useQueryClient();

    useEffect(() => {
        (async () => {
            if (!props.dataEntryFields) {
                await props.getSavedParams(dataEntryFields, 'dataEntry');
            }
            setIsLoading(false);
        })();
    }, []);

    const handleDistrictEntersDataToggle = async () => {
        await axios.post(Boilerplate.route('api.assignmentProgress.update'), {
            deploymentId: props.deploymentId,
            pawnId: props.metricsPawn.pawnId,
            flowlist: 'default',
            additional_info: {
                districtEntersData: !props.districtEntersData,
                importData: {
                    importDistrictData: false,
                    importSchoolData: false,
                },
            },
        });
        queryClient.resetQueries();

        dispatch(setDistrictEntersData(!props.districtEntersData));
        dispatch(
            setImportData({
                district: {
                    importDistrictData: false,
                    importSchoolData: false,
                },
            })
        );
    };

    const getPageTitle = () => {
        if (Fortress.user.acting.group) {
            if (Fortress.user.acting.group.data_type === 'state') {
                const stateName = Fortress.user.acting.group.type_data.stateName;
                return stateName;
            }
            return Fortress.user.acting.group.label;
        }
        return 'Individual Account';
    };

    const handleChangeDeployment = async e => {
        const currentDeployment = reduxStore.deploymentsInfo.find(d => d.deploymentHandle === e.target.value);
        await dispatch(setSelectedDeployment(e.target.value));
        const currentDeploymentId = currentDeployment.deploymentId;
        await dispatch(setDeploymentId(currentDeploymentId));
    };

    if (isLoading) {
        return <Loader />;
    }

    const render = () => {
        if (props.reportHeader) {
            if (props.stateEntersData === true) {
                return (
                    <div>
                        Data entry is being managed by your state this school year. This report reflects data entered by
                        the state.
                    </div>
                );
            }

            if (props.districtEntersData === false) {
                return (
                    <div>
                        Data entry is being managed by your schools this school year. This report reflects an average of
                        data entered by them.
                    </div>
                );
            }
            return (
                <div>This report reflects data your district has entered on behalf of schools in your district.</div>
            );
        }

        if (!props.deploymentIsOpen) {
            return <div>Data entry for this school year is now closed.</div>;
        }

        if (props.stateEntersData) {
            return (
                <div>
                    Data entry is being managed by your state this school year. If you have any questions, please
                    contact your state administrator.
                </div>
            );
        }

        return (
            <>
                <Toggle
                    value={props.districtEntersData}
                    onChange={() => handleDistrictEntersDataToggle()}
                    label={<Html content={props.dataEntryFields.districtPublicSchoolTitle} />}
                />
                <div style={{ fontSize: '16px', marginTop: '1em' }}>
                    <Html
                        content={
                            props.dataEntryFields.districtPublicSchoolDescription
                                ? props.dataEntryFields.districtPublicSchoolDescription
                                : ''
                        }
                    />
                </div>
            </>
        );
    };

    return (
        <div className={style.header}>
            <div className={style.headerUpper}>
                <h3>{getPageTitle()}</h3>
                {!props.reportHeader && (
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 'bold', marginRight: '0.5em', minWidth: '93px' }}>School Year</div>
                        <select
                            className="form-control"
                            value={reduxStore.selectedDeployment}
                            onChange={e => handleChangeDeployment(e)}
                        >
                            {props.options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div className={style.headerLower}>
                <div style={{ fontSize: '20px' }}>{render()}</div>
            </div>
            <div className={style.schoolYearCallout}>
                Data Entry for <span style={{ fontWeight: 'bold' }}>{reduxStore.selectedDeployment.slice(0, 9)}</span>
                <span className={style.tooltip}>
                    <Tooltip
                        title="This can be changed using the School Year dropdown at the top of the page."
                        type="info"
                    >
                        <IconQuestion />
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

DistrictDataEntryHeader.propTypes = {
    pageType: PropTypes.string,
    path: PropTypes.string,
    deploymentId: PropTypes.number,
    deploymentHandle: PropTypes.string,
    districtEntersData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    options: PropTypes.array,
    metricsPawn: PropTypes.object,
    stateEntersData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    deploymentIsOpen: PropTypes.bool,
    getSavedParams: PropTypes.func,
    dataEntryFields: PropTypes.object,
    reportHeader: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        dataEntryFields: state.app.params.dataEntryFields,
    };
};

export default connect(mapStateToProps, { getSavedParams })(DistrictDataEntryHeader);
