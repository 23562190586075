import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Report, Page } from 'cccisd-laravel-report';
import widgets from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import filterFields from '../filterFields.js';

const Component = props => {
    const { workforce, chronicConditions, absenteeism, healthOfficeVisits, statesTable } = props.widgets;

    const reduxStore = useSelector(state => state.app.nasn);

    return (
        <Report downloadFilename="ESC_National_Report">
            <Page>
                <ReportHeader
                    settings={props.settings}
                    filters={props.filters}
                    isPreview={props.isPreview}
                    data={props.data}
                    group={props.group}
                    groupType={props.groupType}
                    schoolsEnteringData={reduxStore.schoolsEnteringData}
                    districtsEnteringData={reduxStore.districtsEnteringData}
                    reportDataLoading={props.reportDataLoading}
                />
                {workforce}
                {chronicConditions}
                {absenteeism}
                {healthOfficeVisits}
                <ReportFooter />
            </Page>
            <Page>{statesTable}</Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    options: PropTypes.array, // Deployment handles for school year filter
    groupType: PropTypes.string,
    group: PropTypes.object,
    schoolsEnteringData: PropTypes.array,
    districtsEnteringData: PropTypes.array,
    reportDataLoading: PropTypes.bool,
    childReportOpen: PropTypes.bool,
    tabSelected: PropTypes.string,
};

Component.defaultProps = {
    tabSelected: 'overview',
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: {
                data: props.data,
                groupType: props.groupType,
                group: props.group,
                reportDataLoading: props.reportDataLoading,
                thisGroupReport: props.thisGroupReport,
                metricspawns: props.metricspawns,
            },
        })),
    getFilterFields: props =>
        filterFields.map(field => {
            if (field.name === 'schoolYear') {
                return {
                    ...field,
                    // reportType: 'publicSchool',
                    options: props.options,
                    initialValue: props.options[props.options.length - 1]
                        ? props.options[props.options.length - 1].value
                        : '',
                    hidden: props.thisGroupReport,
                };
            }
            return field;
        }),
})(Component);
