export default {
    domains: [
        {
            title: 'State Report',
            messages: ['stateComparisonReportDescription'],
        },
        {
            title: 'District Report',
            messages: ['districtComparisonReportDescription'],
        },
        {
            title: 'Public School Report',
            messages: ['publicSchoolComparisonReportDescription'],
        },
        {
            title: 'Private School Report',
            messages: ['privateSchoolComparisonReportDescription'],
        },
    ],
    messages: {
        stateComparisonReportDescription: {
            title: 'Comparison Report Description',
            message: 'Compare your state with other states, districts, and schools.',
        },

        districtComparisonReportDescription: {
            title: 'Comparison Report Description',
            message: 'Compare your district with your state, other districts, and schools.',
        },
        publicSchoolComparisonReportDescription: {
            title: 'Comparison Report Description',
            message: 'Compare your school with your state, and other schools.',
        },
        privateSchoolComparisonReportDescription: {
            title: 'Comparison Report Description',
            message: 'Compare your private school with your state, and other private schools.',
        },
    },
};
