import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import CccisdToggle from 'cccisd-toggle';
import { BulkUploader } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Header from '../../../components/DataEntryHeader/PublicSchools';
import { getDeployments, getAssignmentProgressData, setBulkDefData, setImportData } from 'js/reducers/nasn.js';
import { useMetricspawnQuery } from 'js/queries';
import _get from 'lodash/get';
import style from './style.css';

var Boilerplate = window.cccisd.boilerplate;

const PublicSchoolDataEntry = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [modalClosed, setModalClosed] = useState(0);

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();

    const metricspawnQuery = useMetricspawnQuery();

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await dispatch(getDeployments('publicSchool'));
            setIsLoading(false);
        })();
    }, []);

    const deploymentHandle = reduxStore.selectedDeployment;
    const deploymentId = reduxStore.deploymentId;

    useEffect(() => {
        // Extra check because we don't want this running with deploymentHandle's initial, falsy value
        if (deploymentHandle && !isLoading) {
            // DeploymentPlayer doesn't re-render when deploymentHandle is changed, so we setLoading here so entire page is forced to re-render.
            setIsLoading(true);
            (async () => {
                const districtDeploymentHandle = deploymentHandle.slice(0, 9) + '_district';
                const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_state';
                dispatch(
                    getAssignmentProgressData({
                        groupType: 'publicSchool',
                        deploymentHandle,
                        districtDeploymentHandle,
                        stateDeploymentHandle,
                    })
                );

                setIsLoading(false);
            })();
        }
    }, [deploymentHandle, modalClosed, isLoading]);

    const handleImportDataToggle = async () => {
        const newImportToggleValue = !reduxStore.importData.publicSchool;
        if (newImportToggleValue === true) {
            // only save the group as a respondent if toggle is true
            await axios.post(Boilerplate.route('api.assignmentDeploymentRespondent.store', { deploymentId }), {
                pawnIds: [metricspawnQuery.data.metricspawn.pawnId],
            });
        }

        await axios.post(Boilerplate.route('api.assignmentProgress.update'), {
            deploymentId: reduxStore.deploymentId,
            pawnId: metricspawnQuery.data.metricspawn.pawnId,
            flowlist: 'default',
            additional_info: {
                publicSchoolImportData: !reduxStore.importData.publicSchool,
            },
        });
        dispatch(
            setImportData({
                publicSchool: !reduxStore.importData.publicSchool,
            })
        );
    };

    const getBulkUploadDef = async () => {
        if (!deploymentId) {
            setIsLoading(false);
            console.error('No deployment id; could not retrieve bulk upload definition');
            return;
        }
        if (!reduxStore.bulkDefData[deploymentId]) {
            const response = await axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
                params: { deploymentId },
            });
            const responseBulkDef = _get(response, 'data.result.original.data');
            if (responseBulkDef) {
                dispatch(
                    setBulkDefData({
                        [deploymentId]: responseBulkDef,
                    })
                );
                return responseBulkDef;
            }
            console.error('Could not retrieve bulk upload definition');
        }
        setIsLoading(false);
        return {};
    };

    const render = () => {
        const bulkDef = reduxStore.bulkDefData[deploymentId];

        if (reduxStore.deploymentIsOpen === false) {
            return (
                <>
                    <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                    <div className={style.notification}>Data entry for this school year is now closed.</div>
                </>
            );
        }
        if (reduxStore.stateEntersData) {
            return (
                <>
                    <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                    <div className={style.notification}>
                        Data entry is being managed by your state this school year. If you have any questions, please
                        contact your state administrator.
                    </div>
                </>
            );
        }
        if (reduxStore.districtEntersData) {
            return (
                <>
                    <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                    <div className={style.notification}>
                        Data entry is being managed by your district this school year. If you have any questions, please
                        contact your district administrator.
                    </div>
                </>
            );
        }
        return (
            <>
                <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                <div
                    style={{
                        display: 'grid',
                        gridRowGap: '0.5em',
                        marginBottom: '1em',
                    }}
                >
                    <CccisdToggle
                        label="Will you import data this year?"
                        value={reduxStore.importData.publicSchool}
                        onChange={() => handleImportDataToggle()}
                    />
                </div>
                {reduxStore.importData.publicSchool ? (
                    <>
                        <div className="alert alert-info" role="alert">
                            Please reach out to NASN staff at{' '}
                            <a href="mailto:data@nasn.org" className="alert-link">
                                data@nasn.org
                            </a>{' '}
                            for technical assistance before your first upload.
                        </div>
                        <>
                            <BulkUploader
                                handle="deploymentRespondentMetrics"
                                trigger={
                                    <button type="button" className="btn btn-primary">
                                        Bulk Import School Data
                                    </button>
                                }
                                definition={bulkDef ? bulkDef : undefined}
                                loadDefinition={getBulkUploadDef}
                                deploymentId={deploymentId}
                                afterClose={() => setModalClosed(prevVal => prevVal + 1)}
                                modalTitle="Bulk Import School Data"
                            />
                        </>
                        <>
                            {reduxStore.importedInfo.imported && (
                                <div
                                    style={{
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {' '}
                                    Last imported on{' '}
                                    {reduxStore.importedInfo.importedDate &&
                                        reduxStore.importedInfo.importedDate.slice(0, 10)}
                                </div>
                            )}
                        </>
                    </>
                ) : (
                    <>
                        <DeploymentPlayer
                            deploymentHandle={deploymentHandle}
                            pawnId={metricspawnQuery.data.metricspawn.pawnId}
                            pawnHash={metricspawnQuery.data.metricspawn.pawnHash}
                            onComplete={() => {
                                setIsLoading(true);
                                setIsLoading(false);
                            }}
                            disableLayout
                            enablePrintMode
                        />
                    </>
                )}
            </>
        );
    };

    if (
        isLoading ||
        !reduxStore.assignmentProgressDataLoaded ||
        !reduxStore.deploymentDataLoaded ||
        metricspawnQuery.isLoading
    ) {
        return <Loader loading />;
    }

    return <>{render()}</>;
};

PublicSchoolDataEntry.propTypes = {
    defaultProp: PropTypes.string,
};

export default PublicSchoolDataEntry;
