import React from 'react';
import { Field, CccisdSelect } from 'cccisd-formik';
import { SchoolYear } from './filters';

export default [
    {
        name: 'schoolYear',
        label: 'School Year',
        component: (props) => <SchoolYear {...props} />,
        // initialValue: '2020-2021_state',
    },
    {
        name: 'schoolType',
        label: 'Type',
        component: () => (
            <Field
                name="schoolType"
                component={CccisdSelect}
                label="Type"
                options={[
                    { value: 'national_All Schools', label: 'All Schools (Public and Private)' },
                    { value: 'national_Public Schools', label: 'All Public Schools' },
                ]}
            />
        ),
        initialValue: 'national_All Schools',
    },
];
