import React from 'react';
import Tabs from 'cccisd-tabs';
import OverviewFields from './OverviewFields';
import DataEntryFields from './DataEntryFields';
import ReportFields from './ReportFields';

const tabList = [
    { name: 'overviewFields', title: 'Overview Pages', content: <OverviewFields /> },
    { name: 'dataEntryFields', title: 'Data Entry Pages', content: <DataEntryFields /> },
    { name: 'reportFields', title: 'Report Pages', content: <ReportFields /> },
];

export default class SiteContent extends React.Component {
    render() {
        return (
            <div className="container">
                <h1>Edit Site Content</h1>
                <Tabs tabList={tabList} />
            </div>
        );
    }
}
