import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import filterFields from '../filterFields.js';
import widgets from '../widgets.js';
import { useIsFetching } from 'cccisd-react-query';

const Component = props => {
    const { workforce, chronicConditions, absenteeism, healthOfficeVisits } = props.widgets;
    const reduxStore = useSelector(state => state.app.nasn);
    const isFetching = useIsFetching();

    return (
        <Report disableDownload={!!isFetching} disableDownloadMessage="Loading">
            <Page>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ marginBottom: '1em' }}>
                        <ReportHeader
                            settings={props.settings}
                            filters={props.filters}
                            isPreview={props.isPreview}
                            data={props.data}
                            group={props.group}
                            reportGroupType={props.reportGroupType}
                            schoolsEnteringData={reduxStore.schoolsEnteringData}
                            districtsEnteringData={reduxStore.districtsEnteringData}
                        />
                    </div>
                    {workforce}
                    {chronicConditions}
                    {absenteeism}
                    {healthOfficeVisits}
                    <ReportFooter />
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    options: PropTypes.array, // Deployment handles for school year filter
    reportGroupType: PropTypes.string,
    group: PropTypes.object,
    schoolYear: PropTypes.string,
    schoolsEnteringData: PropTypes.array,
    districtsEnteringData: PropTypes.array,
    childReportOpen: PropTypes.bool,
    tabSelected: PropTypes.string,
};

Component.defaultProps = {
    tabSelected: 'overview',
};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: {
                data: props.data,
                reportGroupType: props.reportGroupType,
                group: props.group,
                thisGroupReport: props.thisGroupReport,
                metricspawns: props.metricspawns,
            },
        })),
    getFilterFields: props => {
        return filterFields.map(field => {
            if (field.name === 'schoolYear') {
                return {
                    ...field,
                    options: props.options,
                    initialValue: props.options[props.options.length - 1]
                        ? props.options[props.options.length - 1].value
                        : '',
                    hidden: props.thisGroupReport,
                };
            }
            return field;
        });
    },
})(Component);
