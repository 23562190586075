import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import widgets from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import ReportHeader from '../ReportHeader';
import filterFields from '../filterFields.js';
import hash from 'object-hash';

const Component = props => {
    const { workforce, modelsOfPractice, chronicConditions, healthOfficeVisits } = props.widgets;

    const filtersChanged = hash(props.initialFilterValues) !== hash(props.filters);

    if (filtersChanged || !props.settings.filterFields.includes('filterHack')) {
        return (
            <Report downloadFilename="ESC_Comparison_Report">
                <Page>
                    <div style={{ marginBottom: '2em' }}>
                        <ReportHeader
                            settings={props.settings}
                            filters={props.filters}
                            isPreview={props.isPreview}
                            data={props.data}
                            group={props.group}
                        />
                    </div>
                    {workforce}
                </Page>
                <Page>
                    <div style={{ marginBottom: '2em' }}>
                        <ReportHeader
                            settings={props.settings}
                            filters={props.filters}
                            isPreview={props.isPreview}
                            data={props.data}
                            group={props.group}
                        />
                    </div>
                    {modelsOfPractice}
                </Page>
                <Page>
                    <div style={{ marginBottom: '2em' }}>
                        <ReportHeader
                            settings={props.settings}
                            filters={props.filters}
                            isPreview={props.isPreview}
                            data={props.data}
                            group={props.group}
                        />
                    </div>
                    {chronicConditions}
                </Page>
                <Page>
                    <div style={{ marginBottom: '2em' }}>
                        <ReportHeader
                            settings={props.settings}
                            filters={props.filters}
                            isPreview={props.isPreview}
                            data={props.data}
                            group={props.group}
                        />
                    </div>
                    {healthOfficeVisits}
                </Page>
            </Report>
        );
    }

    return (
        <div className="alert alert-info">
            Please make a selection in the report filters above. Then click the Apply Filters button to generate a
            report.
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    initialFilterValues: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    group: PropTypes.object,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
    getFilterFields: props =>
        filterFields.map(field => {
            if (field.name === 'schoolYear') {
                return {
                    ...field,
                    reportType: 'publicSchool',
                    options: props.options || [{ value: '2021-2022', label: '2021-2022' }],
                    initialValue: props.options[props.options.length - 1]
                        ? props.options[props.options.length - 1].value
                        : '',
                };
            }
            if (field.name === 'filterHack') {
                return {
                    ...field,
                    initialValue: true,
                };
            }
            return field;
        }),
})(Component);
