import React from 'react';
// import PropTypes from 'prop-types';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import style from './style.css';

const ComponentWrapper = props => {
    const handleCollapse = id => {
        if (props.collapsed.includes(id)) {
            return props.setCollapsed(props.collapsed.filter(tabId => tabId !== id));
        }
        return props.setCollapsed([...props.collapsed, id]);
    };

    return (
        <div className={style.wrapper}>
            <div className={style.header} onClick={() => handleCollapse(props.id)}>
                {props.collapsed.includes(props.id) ? (
                    <IconArrowRight spaceRight />
                ) : (
                    <IconArrowDown spaceRight />
                )}
                <span className={style.headerText}>{props.title}</span>
            </div>
            <div
                className={
                    props.collapsed.includes(props.id) ? style.contentClosed : style.contentOpen
                }
                // style={
                //     props.collapsed.includes(props.id)
                //         ? {
                //               display: 'none',
                //               height: '0',
                //               transition: 'height 1s ease-in-out',
                //           }
                //         : {
                //               display: 'block',
                //               height: '100%',
                //               transition: 'height 1s ease-in-out',
                //           }
                // }
            >
                {props.children}
            </div>
        </div>
    );
};

ComponentWrapper.defaultProps = {
    title: 'Resource Center Title',
    id: '1',
    collapsed: [],
    setCollapsed: () => {},
};

export default ComponentWrapper;
