import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const {
        header: { title, createdDateText, schoolYearText },
    } = props.settings;

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <div className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>
                        {Fortress.user.acting.group ? Fortress.user.acting.group.label : 'Public School'}
                    </div>
                    <div className={style.reportType}>{title}</div>
                </div>
                <div className={style.info}>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                        {moment(new Date()).format('MMMM Do YYYY')}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{schoolYearText} </span>
                        <span style={{ textTransform: 'capitalize' }}>{props.filters.schoolYear.slice(0, 9)}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Reported By: </span>
                        <span>
                            {(Fortress.user.firstName && Fortress.user.firstName) +
                                ' ' +
                                (Fortress.user.lastName && Fortress.user.lastName)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    schoolType: PropTypes.string,
    group: PropTypes.object,
    schoolYear: PropTypes.string,
    groupType: PropTypes.string,
    districtsEnteringData: PropTypes.array,
    schoolsEnteringData: PropTypes.array,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'Overview Report',
            createdDateText: 'Report Created On:',
            schoolYearText: 'School Year:',
            schoolTypeText: 'School Type:',
        },
    },
    group: { groupType: 'state', label: 'My Group' },
    groupType: 'state',
};

export default ReportHeader;
