import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { setReportDataLoading } from 'js/reducers/nasn.js';
import { useMetricspawnQuery, useDeploymentsQuery } from 'js/queries';

const Component = props => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const metricspawnQuery = useMetricspawnQuery();
    const deploymentsQuery = useDeploymentsQuery(
        [`deployments ${metricspawnQuery.data.groupType}`],
        metricspawnQuery.data.groupType
    );

    const queriesLoading = metricspawnQuery.isLoading || deploymentsQuery.isLoading;

    const { childReportOpen, tabSelected } = props;

    useEffect(() => {
        setLoading(true);
        dispatch(setReportDataLoading(true));
        (async () => {
            dispatch(setReportDataLoading(false));
            setLoading(false);
        })();
    }, [childReportOpen, tabSelected]);

    if (loading || queriesLoading) {
        return <Loader loading />;
    }

    return (
        <Player
            {...props}
            metricspawns={props.metricspawns}
            options={deploymentsQuery.data?.deployments}
            groupType={props.groupType || metricspawnQuery.data.groupType}
            group={props.group || metricspawnQuery.data.group}
            tabSelected={props.tabSelected}
            thisGroupReport={props.thisGroupReport}
        />
    );
};

export default Component;
