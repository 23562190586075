import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BrowserOnly } from 'cccisd-laravel-report';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import _pick from 'lodash/pick';
import _round from 'lodash/round';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);

    const { nationalData, nationalDataLoaded } = reduxStore;

    const nationalDataKey = props.filters.schoolType; // 'national_All Schools', 'national_Public Schools'

    const getReportedStatesCount = () => {
        if (nationalDataLoaded) {
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];
                const absenteeismData = _pick(data, ['Chronically_Absent_Students_Per_1000']);

                if (Object.values(absenteeismData).some(x => x !== '0') && data.Reported_Absenteeism !== '0') {
                    return (
                        <>
                            {'('} {data.Reported_Absenteeism} {' states represented )'}
                        </>
                    );
                }
                return '';
            }
        }
        return '';
    };

    const getData = () => {
        if (nationalDataLoaded) {
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];
                const per1000 = data.Chronically_Absent_Students_Per_1000;

                return { absenteeism: _round(per1000, 2) };
            }
        }
        return { absenteeism: '?' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Absenteeism'}
                        </div>

                        <div className={style.stateInfo}>{getReportedStatesCount()}</div>
                        {nationalDataLoaded &&
                            nationalData !== null &&
                            nationalData[nationalDataKey] &&
                            !nationalData[nationalDataKey].Student_Population && (
                                <BrowserOnly>
                                    <div
                                        style={{
                                            fontSize: '0.58em',
                                            color: 'red',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        Data for this section cannot be calculated because a value for total students
                                        was not found
                                    </div>
                                </BrowserOnly>
                            )}
                    </div>

                    <div className={style.content}>
                        {!nationalDataLoaded ? (
                            <Skeleton height={84} />
                        ) : (
                            <div className={style.box}>
                                <div className={style.boxIcon} />
                                <div className={style.boxText}>
                                    <div style={{ fontWeight: 'bold' }}>Students chronically absent per month</div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{getData().absenteeism}</span> / 1000
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
