import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'cccisd-graphql-table';
import { client as apollo } from 'cccisd-apollo';
import districtsQuery from './districtsQuery.graphql';
import IconCheck from 'cccisd-icons/stack-check';
import IconInProgress from 'cccisd-icons/stack4';
import { BulkUploader } from 'cccisd-laravel-nexus';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import style from './style.css';
import deploymentsQuery from './allDeploymentsQuery.graphql';
import { setBulkDefData } from 'js/reducers/nasn.js';

const StateDataEntryTable = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [districtDeploymentId, setDistrictDeploymentId] = useState('');
    const [schoolDeploymentId, setSchoolDeploymentId] = useState('');

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();
    const tableRef = useRef();
    const Boilerplate = window.cccisd.boilerplate;

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const result = await apollo.query({
                query: deploymentsQuery,
                fetchPolicy: 'network-only',
            });

            const currentSelectedYear = reduxStore.selectedDeployment.slice(0, 9);
            const districtDeploymentData = result.data.flows.districts.find(d => d.label === currentSelectedYear);
            if (districtDeploymentData) {
                setDistrictDeploymentId(districtDeploymentData.deploymentId);
            }
            const schoolDeploymentData = result.data.flows.schools.find(d => d.label === currentSelectedYear);
            if (schoolDeploymentData) {
                setSchoolDeploymentId(schoolDeploymentData.deploymentId);
            }
            setIsLoading(false);
        })();
    }, []);

    const getBulkUploadDef = async () => {
        if (!districtDeploymentId) {
            setIsLoading(false);
            console.error('No deployment id; could not retrieve bulk upload definition');
            return;
        }
        const deploymentId = districtDeploymentId;

        setDeploymentRespondents();

        if (!reduxStore.bulkDefData[deploymentId]) {
            const response = await Axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
                params: { deploymentId },
            });
            const responseBulkDef = _get(response, 'data.result.original.data');
            if (responseBulkDef) {
                dispatch(setBulkDefData({ [deploymentId]: responseBulkDef }));
                return responseBulkDef;
            }
            console.error('Could not retrieve bulk upload definition');
        }

        setIsLoading(false);
        return {};
    };

    const setDeploymentRespondents = async () => {
        // only save the group as a respondent if toggle is true
        if (districtDeploymentId && districtDeploymentId !== '' && props.willImportDistrictData) {
            await Axios.post(Boilerplate.route('app.set.deployment.respondents'), {
                groupType: 'district',
                deploymentId: districtDeploymentId,
            });
        }
    };

    /*
     *   For districts in table, show imported status, or number of schools that have started/completed data entry
     */
    const renderSurveyProgress = (row, value, flow) => {
        const districtEntersData = row['childRoles.metricspawn.assignmentProgress.additionalInfo.districtEntersData'];
        const allSchools = row['childGroups.publicSchoolList'];
        const childGroupsEnteredData = allSchools.some(school => {
            const check = school.childRoles.metricspawn;
            return (
                check.absenteeism.status ||
                check.healthVisits.status ||
                check.chronicConditions.status ||
                check.workforce.status
            );
        });

        // Show imported status if state is importing data and there is a completedDate
        if (props.willImportDistrictData && row[`childRoles.metricspawn.${flow}.completedDate`] !== null) {
            return (
                <div>
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#5cb85c',
                            fontStyle: 'italic',
                        }}
                    >
                        Imported
                    </div>
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#5cb85c',
                            fontStyle: 'italic',
                        }}
                    >
                        {row[`childRoles.metricspawn.${flow}.completedDate`]}
                    </div>
                </div>
            );
        }
        /*
         *   The districtEntersData additionalInfo value will only exist if a District person has logged in & visited
         *   the data entry page.
         */
        if (
            districtEntersData === false ||
            (districtEntersData === null && childGroupsEnteredData) ||
            (typeof districtEntersData === 'undefined' && childGroupsEnteredData)
        ) {
            const totalStarted = allSchools.filter(
                school => school.childRoles.metricspawn[flow].status === 'In Progress'
            );
            const totalCompleted = allSchools.filter(
                school => school.childRoles.metricspawn[flow].status === 'Complete'
            );
            const totalNotCompleted = allSchools.filter(
                school => school.childRoles.metricspawn[flow].status !== 'Complete'
            );
            return (
                <div style={{ padding: '0 1em' }}>
                    {totalCompleted.length === allSchools.length ? (
                        <div style={{ color: '#5cb85c' }}>
                            Completed:{' '}
                            <span className={style.valueDisplay}>
                                {totalCompleted.length} / {allSchools.length}
                            </span>
                        </div>
                    ) : (
                        <div>
                            Completed:{' '}
                            <span className={style.valueDisplay}>
                                {totalCompleted.length} / {allSchools.length}
                            </span>
                        </div>
                    )}

                    {totalNotCompleted.length > 0 && (
                        <div>
                            Started: <span className={style.valueDisplay}>{totalStarted.length}</span>
                        </div>
                    )}
                </div>
            );
        }
        if (value === null) {
            return (
                <div
                    style={{
                        padding: '0 1em',
                        color: '#aaa',
                        fontStyle: 'italic',
                    }}
                >
                    Incomplete
                </div>
            );
        }
        return (
            <>
                {value === 'Complete' ? (
                    <>
                        <div
                            style={{
                                padding: '0 1em',
                                color: '#5cb85c',
                            }}
                        >
                            <IconCheck /> {value}
                        </div>
                        <div
                            style={{
                                padding: '0 1em',
                                color: '#5cb85c',
                                fontStyle: 'italic',
                            }}
                        >
                            {row['childRoles.metricspawn.absenteeism.completedDate']}
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            padding: '0 1em',
                            color: '#11385a',
                        }}
                    >
                        <IconInProgress /> {value}
                    </div>
                )}
            </>
        );
    };

    let columns = [
        {
            name: 'group.label',
            label: 'District',
            sort: true,
            filter: true,
        },
        {
            name: 'childRoles.metricspawn.assignmentProgress.additionalInfo.districtEntersData',
            label: 'Data Entry',
            sort: true,
            className: 'text-center',
            tooltip: (
                <div>
                    <b>Aggregate:</b> District enters data. <br />
                    <b>By School:</b> Schools enter data.
                </div>
            ),
            render: ({ value, row }) => {
                const allSchools = row['childGroups.publicSchoolList'];
                const childGroupsEnteredData = allSchools.some(school => {
                    const check = school.childRoles.metricspawn;
                    return (
                        check.absenteeism.status ||
                        check.healthVisits.status ||
                        check.chronicConditions.status ||
                        check.workforce.status
                    );
                });
                if (typeof value !== 'undefined') {
                    return value ? 'Aggregate' : 'By School';
                }
                if (typeof value === 'undefined' && childGroupsEnteredData) {
                    return 'By School';
                }
                return '';
            },
        },
        {
            name: 'childRoles.metricspawn.workforce.status',
            label: 'Workforce',
            sort: true,
            tooltip: (
                <div>
                    <b>Completed:</b> Completed / Total Schools <br />
                    <b>Started:</b> Out of schools that have not completed, how many have started.
                </div>
            ),
            render: ({ value, row }) => renderSurveyProgress(row, value, 'workforce'),
        },
        {
            name: 'childRoles.metricspawn.chronicConditions.status',
            label: 'Chronic Conditions',
            sort: true,
            tooltip: (
                <div>
                    <b>Completed:</b> Completed / Total Schools <br />
                    <b>Started:</b> Out of schools that have not completed, how many have started.
                </div>
            ),
            render: ({ value, row }) => renderSurveyProgress(row, value, 'chronicConditions'),
        },
        {
            name: 'childRoles.metricspawn.absenteeism.status',
            label: 'Absenteeism',
            sort: true,
            tooltip: (
                <div>
                    <b>Completed:</b> Completed / Total Schools <br />
                    <b>Started:</b> Out of schools that have not completed, how many have started.
                </div>
            ),
            render: ({ value, row }) => renderSurveyProgress(row, value, 'absenteeism'),
        },
        {
            name: 'childRoles.metricspawn.healthVisits.status',
            label: 'Health Office Visit',
            sort: true,
            tooltip: (
                <div>
                    <b>Completed:</b> Completed / Total Schools <br />
                    <b>Started:</b> Out of schools that have not completed, how many have started.
                </div>
            ),
            render: ({ value, row }) => renderSurveyProgress(row, value, 'healthVisits'),
        },
    ];

    if (props.willImportDistrictData) {
        columns = columns.filter(column => column.label !== 'Data Entry');
    }

    if (isLoading) {
        return <Loader loading />;
    }

    const bulkDef = reduxStore.bulkDefData[districtDeploymentId];

    return (
        <>
            {props.willImportDistrictData && (
                <>
                    <div className="alert alert-info" role="alert">
                        Please reach out to NASN staff at{' '}
                        <a href="mailto:data@nasn.org" className="alert-link">
                            data@nasn.org
                        </a>{' '}
                        for technical assistance before your first upload.
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <BulkUploader
                            handle="deploymentRespondentMetrics"
                            trigger={
                                <button type="button" className="btn btn-primary">
                                    Bulk Import District Data
                                </button>
                            }
                            definition={bulkDef ? bulkDef : undefined}
                            loadDefinition={getBulkUploadDef}
                            deploymentId={districtDeploymentId}
                            afterClose={() => tableRef.current.loadData()}
                            modalTitle="Bulk Import District Data"
                        />
                    </div>
                </>
            )}

            <Table
                ref={tableRef}
                rowKey="group.groupId"
                query={districtsQuery}
                columns={columns}
                graphqlVariables={{
                    districtDeploymentId,
                    schoolDeploymentId,
                }}
                useTaskMaster
            />
        </>
    );
};

export default StateDataEntryTable;
