import React from 'react';
import { Field, CccisdInput, CccisdToggle, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Absenteeism',
    additionalInfo: '',
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Absenteeism?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="additionalInfo" component={CccisdWysiwyg} label="Additional Info" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
