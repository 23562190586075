import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from 'cccisd-react-query';
import Tabs from 'cccisd-tabs';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import OverviewReport from 'js/vendor/reports/reportTemplates/Overview';
import ComparisonReport from 'js/vendor/reports/reportTemplates/Comparison';
import NationalReport from 'js/vendor/reports/reportTemplates/NationalInfographic';
import PublicSchoolsTable from './PublicSchoolsTable';
import DistrictComparisonTable from './DistrictComparisonTable';
import ComponentWrapper from 'js/components/ComponentWrapper';
import { getSavedParams } from 'js/reducers/params.js';
import reportFields from 'js/reducers/siteFields/reportFields.js';
import { getDeployments } from 'js/reducers/nasn.js';
import { useMetricspawnQuery, useDeploymentsQuery } from 'js/queries';

const appDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const DistrictReports = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tabSelected, setTabSelected] = useState('overview');
    const [collapsed, setCollapsed] = useState(['2']);
    const queryClient = useQueryClient();
    const reduxStore = useSelector(state => state.app.nasn);
    const params = useSelector(state => state.app.params);
    const dispatch = useDispatch();

    /*
     *  Queries
     */
    const metricspawnQuery = useMetricspawnQuery();
    const deploymentsQuery = useDeploymentsQuery([`deployments district`], 'district');
    const deploymentHandle = reduxStore.selectedDeployment
        ? reduxStore.selectedDeployment
        : deploymentsQuery.data?.selectedDeployment;

    const queriesLoading = metricspawnQuery.isLoading || deploymentsQuery.isLoading;

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (Object.keys(params.reportFields).length < 1) {
                await dispatch(getSavedParams(reportFields, 'report'));
            }
            dispatch(getDeployments('district'));
            setIsLoading(false);
        })();
        return () => queryClient.cancelQueries();
    }, []);

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;
        if (tabSelected !== 'comparison') {
            const districtInfographic = reportList.find(r => r.id === 'DistrictInfographic');
            if (districtInfographic) {
                return districtInfographic.reportTemplateSettings;
            }
            const anyOverview = reportList.find(r => r.reportTemplateHandle === 'overview');
            if (anyOverview) {
                return anyOverview.reportTemplateSettings;
            }
        }
        const districtComparison = reportList.find(r => r.id === 'DistrictComparison');
        if (districtComparison) {
            return districtComparison.reportTemplateSettings;
        }
        const anyComparison = reportList.find(r => r.reportTemplateHandle === 'comparison');
        if (anyComparison) {
            return anyComparison.reportTemplateSettings;
        }
    };

    const getNationalReportSettings = () => {
        const report = appDefs.reports.list.find(r => r.reportTemplateHandle === 'national');
        return report.reportTemplateSettings;
    };

    const reportSettings = getReportSettings();

    const getReportType = () => {
        return (
            <OverviewReport.player
                settings={reportSettings}
                deployments={deploymentsQuery.data}
                reportGroupType="district"
                deploymentHandle={deploymentHandle}
            />
        );
    };

    const getPageTitle = () => {
        if (Fortress.user.acting.group) {
            return Fortress.user.acting.group.label;
        }
        return 'Individual Account';
    };

    if (queriesLoading || isLoading) {
        return <Loader loading />;
    }

    return (
        <div>
            <h3>{getPageTitle()}</h3>
            <Tabs
                onTabSelect={tabName => {
                    setTabSelected(tabName);
                    queryClient.cancelQueries();
                }}
                tabList={[
                    {
                        name: 'overview',
                        title: 'Overview',
                        content: (
                            <>
                                <div style={{ maxWidth: '870px' }}>
                                    {reportSettings ? (
                                        <>{getReportType()}</>
                                    ) : (
                                        <div>Could not find an overview report.</div>
                                    )}
                                </div>
                                {(!reduxStore.districtEntersData ||
                                    reduxStore.importData.district.importSchoolData === true) && (
                                    <div style={{ marginTop: '1em' }}>
                                        <h3>My Schools</h3>
                                        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                                            This table shows reports if schools entered their own data.
                                        </span>
                                        <PublicSchoolsTable
                                            reportSettings={reportSettings}
                                            deploymentHandle={reduxStore.selectedDeployment}
                                        />
                                    </div>
                                )}
                            </>
                        ),
                    },
                    {
                        name: 'comparison',
                        title: 'Comparison',
                        content: (
                            <>
                                {reportSettings ? (
                                    <>
                                        <div style={{ marginBottom: '0.5em' }}>
                                            <Html content={params.reportFields.districtComparisonReportDescription} />
                                        </div>
                                        <ComponentWrapper
                                            title="Compare using data sets"
                                            id="1"
                                            collapsed={collapsed}
                                            setCollapsed={setCollapsed}
                                        >
                                            <div style={{ maxWidth: '870px' }}>
                                                <ComparisonReport.player settings={reportSettings} />
                                            </div>
                                        </ComponentWrapper>
                                        <ComponentWrapper
                                            title="Compare with schools in my district"
                                            id="2"
                                            collapsed={collapsed}
                                            setCollapsed={setCollapsed}
                                        >
                                            <DistrictComparisonTable
                                                deploymentHandle={reduxStore.selectedDeployment}
                                                deployments={reduxStore.deploymentsInfo}
                                            />
                                        </ComponentWrapper>
                                    </>
                                ) : (
                                    <div>Could not find a comparison report.</div>
                                )}
                            </>
                        ),
                    },
                    {
                        name: 'national',
                        title: 'National Overview',
                        content: (
                            <div style={{ maxWidth: '870px' }}>
                                {reportSettings ? (
                                    <NationalReport.player settings={getNationalReportSettings()} />
                                ) : (
                                    <div>Could not find an overview report.</div>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default DistrictReports;
