import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TextBlock, FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import style from './style.css';

export const fields = ['password', 'repeatPassword', 'termsandconds', 'nasn1', 'nasn2', 'nasn3'];

const validate = (values, props) => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Password is required';
    } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = 'The passwords must match';
    }

    if (!values.termsandconds) {
        errors.termsandconds = 'The privacy policy and terms of use must be accepted to continue';
    }

    if (!values.nasn1) {
        errors.nasn1 = 'This NASN agreement must be accepted to continue';
    }

    if (!values.nasn2) {
        errors.nasn2 = 'This NASN agreement must be accepted to continue';
    }

    if (!values.nasn3) {
        errors.nasn3 = 'This NASN agreement must be accepted to continue';
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        username: PropTypes.string,
        fullName: PropTypes.string,
    };

    render() {
        const {
            fields: { password, repeatPassword, termsandconds, nasn1, nasn2, nasn3 },
            handleSubmit,
            submitting,
            username,
            fullName,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <h3>Welcome, {fullName || username}!</h3>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span style={{ fontSize: '20px' }}>
                            Create a password for your account with username{' '}
                            <strong>{username}</strong>
                        </span>
                    </div>
                    <div className="panel-body">
                        <div style={{ maxWidth: '350px' }}>
                            <TextBlock field={password} label="Password" type="password" />
                        </div>
                        <div style={{ maxWidth: '350px' }}>
                            <TextBlock
                                field={repeatPassword}
                                label="Confirm Password"
                                type="password"
                            />
                        </div>
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                            NASN User Agreement
                        </span>
                    </div>
                    <div className="panel-body">
                        <p>
                            This project was reviewed by an Institutional Review Board (IRB) and
                            found to be exempt. NASN will only use data submitted here at the
                            national level in aggregate form. Decisions on displaying state or
                            district level data is decided at the state/local level. Please check
                            with your state and district to learn how data will be displayed.
                        </p>
                        <p>
                            The data you submit is linked to your school or district for trending
                            purposes only. Within the system, it is linked to a code that cannot be
                            traced back to your school or district so that students cannot be
                            traced.
                        </p>
                        <p>
                            FERPA indicates that properly de-identified data can be submitted
                            without parent consent. However, various districts and states have
                            additional data privacy laws, and school districts may have relevant
                            privacy policies. Compliance with any applicable state laws and school
                            district policies is the sole responsibility of each individual state
                            submitting data to NASN.
                        </p>
                    </div>

                    <div className="panel-footer">
                        <div className={style.nasnTerms}>
                            <FieldBlock field={nasn1}>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" {...domOnlyProps(nasn1)} />I am aware
                                        NASN will be using the data in aggregate only
                                    </label>
                                </div>
                            </FieldBlock>

                            <FieldBlock field={nasn2}>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" {...domOnlyProps(nasn2)} />
                                        My submission complies with all applicable state laws and
                                        school district policies regarding data privacy and security
                                        standards (de-identified data as outlined in FERPA)
                                    </label>
                                </div>
                            </FieldBlock>

                            <FieldBlock field={nasn3}>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" {...domOnlyProps(nasn3)} />I have the
                                        appropriate permissions needed to submit this data
                                    </label>
                                </div>
                            </FieldBlock>
                        </div>
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                            3C Privacy Policy and Terms of Use
                        </span>
                    </div>
                    <div className="panel-body">
                        <div className={style.terms}>
                            <ul>
                                <li>
                                    <a
                                        href="https://www.3cisd.com/privacy-policy?page_id=3110"
                                        target="blank"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.3cisd.com/terms-of-use" target="blank">
                                        Terms of Use
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <FieldBlock field={termsandconds}>
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" {...domOnlyProps(termsandconds)} />I
                                    accept the privacy policy and terms of use.
                                </label>
                            </div>
                        </FieldBlock>
                    </div>
                </div>

                <ClickButton
                    className="btn btn-primary"
                    title="Activate"
                    isLoading={submitting}
                    onClick={handleSubmit}
                />
            </form>
        );
    }
}

export default reduxForm({
    fields,
    initialValues: {
        password: '',
        repeatPassword: '',
        termsandconds: false,
        nasn1: false,
        nasn2: false,
        nasn3: false,
    },
    validate,
})(Form);
