export default {
    publicSchool: [
        'PUB_SCH_AGG_CHRONIC_ABS',
        'PUB_SCH_AN_AGG_ABSENT',
        'PUB_SCH_AN_K3_ABSENT',
        'PUB_SCH_AN_K4_ABSENT',
        'PUB_SCH_AN_K_ABSENT',
        'PUB_SCH_AN_1_ABSENT',
        'PUB_SCH_AN_2_ABSENT',
        'PUB_SCH_AN_3_ABSENT',
        'PUB_SCH_AN_4_ABSENT',
        'PUB_SCH_AN_5_ABSENT',
        'PUB_SCH_AN_6_ABSENT',
        'PUB_SCH_AN_7_ABSENT',
        'PUB_SCH_AN_8_ABSENT',
        'PUB_SCH_AN_9_ABSENT',
        'PUB_SCH_AN_10_ABSENT',
        'PUB_SCH_AN_11_ABSENT',
        'PUB_SCH_AN_12_ABSENT',
    ],
};
