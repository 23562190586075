import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';
import filterFields from '../filterFields.js';

export const initialValues = {
    title: 'Overview Report',
    header: {
        title: 'Overview Report',
        createdDateText: 'Report Created On:',
        schoolYearText: 'School Year:',
        schoolTypeText: 'School Type:',
    },
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = () => {
    return (
        <BuilderBlock title="Header">
            <Field name="header.title" component={CccisdInput} label="Title" />
            <Field name="header.createdDateText" component={CccisdInput} label="Report Created Text" />
            <Field name="header.schoolYearText" component={CccisdInput} label="School Year Text" />
            <Field name="header.schoolTypeText" component={CccisdInput} label="School Type Text" />
        </BuilderBlock>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({ filterFields, widgets })(Component);
