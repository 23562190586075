import React from 'react';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import style from '../style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export default class RolePickerGroup extends React.Component {
    static propTypes = {
        groupPawns: PropTypes.array,
        backgroundImage: PropTypes.string,
    };

    loginAs = async user => {
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), user);
        if (response.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }
    };

    getAccountType = accountLevel => {
        if (accountLevel === 'districtAdmin' || accountLevel === 'stateAdmin') {
            return 'Admin';
        }
        if (accountLevel === 'districtTeamMember' || accountLevel === 'stateTeamMember') {
            return 'Team Member';
        }
        return 'User';
    };

    render() {
        const { groupPawns, backgroundImage } = this.props;
        return (
            <div className={style.groupSelect}>
                <div
                    className={style.image}
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                />
                <div className={style.flagWrap}>
                    <div className={style.flag} />
                </div>
                <div className={style.groupText}>
                    {groupPawns[0].group ? groupPawns[0].group.group_type.label : 'Site Admin'}{' '}
                    Accounts
                </div>
                {groupPawns &&
                    groupPawns.map((pawn, index) => {
                        return (
                            <button
                                key={index}
                                type="button"
                                className={`btn btn-lg btn-primary ${style.button}`}
                                onClick={() =>
                                    this.loginAs({
                                        pawn: pawn.id,
                                        pawnHash: pawn.random_hash,
                                    })
                                }
                            >
                                {pawn.group ? pawn.group.label : 'Site Admin'}
                                {pawn.data_type !== 'uberadmin' && (
                                    <div style={{ fontSize: '14px' }}>
                                        {'('} {this.getAccountType(pawn.data_type)} {')'}
                                    </div>
                                )}
                            </button>
                        );
                    })}
            </div>
        );
    }
}
