import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDeployment, setDeploymentId } from 'js/reducers/nasn.js';
import style from './style.css';
import IconQuestion from 'cccisd-icons/question4';

const Fortress = window.cccisd.fortress;

const PublicSchoolsDataEntryHeader = props => {
    const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.nasn);

    const getPageTitle = () => {
        if (Fortress.user.acting.group) {
            if (Fortress.user.acting.group.data_type === 'state') {
                const stateName = Fortress.user.acting.group.type_data.stateName;
                return stateName;
            }
            return Fortress.user.acting.group.label;
        }
        return 'Individual Account';
    };

    const handleChangeDeployment = async e => {
        const currentDeployment = reduxStore.deploymentsInfo.find(
            d => d.deploymentHandle === e.target.value
        );
        await dispatch(setSelectedDeployment(e.target.value));
        const currentDeploymentId = currentDeployment.deploymentId;
        await dispatch(setDeploymentId(currentDeploymentId));
    };

    return (
        <div className={style.header}>
            <div className={style.headerUpper}>
                <h3>{getPageTitle()}</h3>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontWeight: 'bold', marginRight: '0.5em', minWidth: '93px' }}>
                        School Year
                    </div>
                    <select
                        className="form-control"
                        value={reduxStore.selectedDeployment}
                        onChange={e => handleChangeDeployment(e)}
                    >
                        {props.options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={style.schoolYearCallout}>
                Data Entry for{' '}
                <span style={{ fontWeight: 'bold' }}>
                    {reduxStore.selectedDeployment.slice(0, 9)}
                </span>
                <span className={style.tooltip}>
                    <Tooltip
                        title="This can be changed using the School Year dropdown at the top of the page."
                        type="info"
                    >
                        <IconQuestion />
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};

PublicSchoolsDataEntryHeader.propTypes = {
    deploymentHandle: PropTypes.string,
    setDeploymentHandle: PropTypes.func,
    options: PropTypes.array,
};

export default PublicSchoolsDataEntryHeader;
