import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import hash from 'object-hash';
import axios from 'cccisd-axios';
import { Html } from 'cccisd-wysiwyg';
import HorizontalBar from './HorizontalBar';
import ChartLegend from '../../ChartLegend';
import { setNationalData, setNationalDataLoaded } from 'js/reducers/nasn.js';
import style from './style.css';
import _pick from 'lodash/pick';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;
const userGroup = Fortress.user.acting.group;

const Component = props => {
    const adminColor = '#2A83D1';
    const saColor = '#4DACFF';
    const floatColor = '#96CEFF';
    const dsColor = '#D6ECFF';

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setNationalDataLoaded(false));

        (async () => {
            const url = Boilerplate.route('app.comparison.report.job');
            const result = await axios.post(url, {
                groupId: userGroup ? userGroup.id : 3,
                schoolYear: props.filters.schoolYear.slice(0, 9) || '2020-2021',
                comparisonType1Field: 'national',
                comparisonType1Value: 'Public Schools',
                comparisonType2Field: 'national',
                comparisonType2Value: 'All Schools',
                type: 'national',
            });
            dispatch(setNationalData(result.data.result));
            dispatch(setNationalDataLoaded(true));
            return result;
        })();
    }, [hash(props.filters)]);

    const getReportedStatesCount = () => {
        if (reduxStore.nationalDataLoaded) {
            const nationalData = reduxStore.nationalData;
            const nationalDataKey = props.filters.schoolType;
            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];

                const workforceData = _pick(data, [
                    'RN_Total_FTE',
                    'RN_Administrative',
                    'RN_Special_Assignment',
                    'RN_Float',
                    'RN_Assigned_Caseload',
                    'LPN_LVN_Total_FTE',
                    'LPN_LVN_Administrative',
                    'LPN_LVN_Special_Assignment',
                    'LPN_LVN_Float',
                    'LPN_LVN_Assigned_Caseload',
                    'Health_Aide_Total_FTE',
                    'Health_Aide_Administrative',
                    'Health_Aide_Special_Assignment',
                    'Health_Aide_Float',
                    'Health_Aide_Assigned_Caseload',
                ]);

                if (Object.values(workforceData).some(x => x !== '0') && data.Reported_Staffing !== '0') {
                    return (
                        <>
                            {'('} {data.Reported_Staffing} {' states represented )'}
                        </>
                    );
                }
                return '';
            }
        }
        return '';
    };

    const getData = () => {
        if (reduxStore.nationalDataLoaded) {
            const nationalData = reduxStore.nationalData;
            const nationalDataKey = props.filters.schoolType;

            if (nationalData !== null && Object.keys(nationalData).includes(nationalDataKey)) {
                const data = nationalData[nationalDataKey];

                const _getScores = ({ total = 0, admin = 0, sa = 0, float = 0, ds = 0 }) => {
                    total === null ? (total = 0) : total;
                    if (!total) {
                        return [
                            { title: 'Administrative', score: '?', color: adminColor },
                            { title: 'Special Assignment', score: '?', color: saColor },
                            { title: 'Float', score: '?', color: floatColor },
                            { title: 'Direct Services', score: '?', color: dsColor },
                        ];
                    }
                    return [
                        {
                            title: 'Administrative',
                            score: (admin / total) * 100,
                            color: adminColor,
                        },
                        { title: 'Special Assignment', score: (sa / total) * 100, color: saColor },
                        { title: 'Float', score: (float / total) * 100, color: floatColor },
                        { title: 'Direct Services', score: (ds / total) * 100, color: dsColor },
                    ];
                };
                return {
                    rn: {
                        title: 'RN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: data.RN_Total_FTE,
                            admin: data.RN_Administrative,
                            sa: data.RN_Special_Assignment,
                            float: data.RN_Float,
                            ds: data.RN_Assigned_Caseload,
                        }),
                    },
                    lpn: {
                        title: 'LPN',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: data.LPN_LVN_Total_FTE,
                            admin: data.LPN_LVN_Administrative,
                            sa: data.LPN_LVN_Special_Assignment,
                            float: data.LPN_LVN_Float,
                            ds: data.LPN_LVN_Assigned_Caseload,
                        }),
                    },
                    aide: {
                        title: 'AIDE',
                        scoreSuffix: '%',
                        data: _getScores({
                            total: data.Health_Aide_Total_FTE,
                            admin: data.Health_Aide_Administrative,
                            sa: data.Health_Aide_Special_Assignment,
                            float: data.Health_Aide_Float,
                            ds: data.Health_Aide_Assigned_Caseload,
                        }),
                    },
                    stateInfo: {
                        reported: data.Reported_Staffing,
                    },
                };
            }
        }
        return {
            rn: {
                title: 'RN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            lpn: {
                title: 'LPN',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
            aide: {
                title: 'AIDE',
                scoreSuffix: '%',
                data: [
                    { title: 'Administrative', score: 0, color: adminColor },
                    { title: 'Special Assignment', score: 0, color: saColor },
                    { title: 'Float', score: 0, color: floatColor },
                    { title: 'Direct Services', score: 0, color: dsColor },
                ],
            },
        };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Workforce'}
                        </div>
                        <div className={style.stateInfo}>{getReportedStatesCount()}</div>
                    </div>
                    <div className={style.content}>
                        {!reduxStore.nationalDataLoaded ? (
                            <Skeleton height={141} />
                        ) : (
                            <>
                                <ChartLegend
                                    items={[
                                        { label: 'Administrative', color: adminColor },
                                        { label: 'Special Assignment', color: saColor },
                                        { label: 'Float', color: floatColor },
                                        { label: 'Direct Services', color: dsColor },
                                    ]}
                                />
                                <div className={style.chartWrapper}>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="RN" chartData={getData().rn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="LPN" chartData={getData().lpn} />
                                    </div>
                                    <div className={style.box}>
                                        <HorizontalBar yAxisLabel="AIDE" chartData={getData().aide} />
                                    </div>
                                </div>
                                <div className={style.additionalInfo}>
                                    <Html content={props.settings.additionalInfo} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
