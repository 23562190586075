// import { Field, CccisdSelect } from 'cccisd-formik';
import { SchoolYear } from './filters';
import React from 'react';

export default [
    // {
    //     name: 'schoolType',
    //     label: 'School Type',
    //     component: () => (
    //         <Field
    //             name="schoolType"
    //             component={CccisdSelect}
    //             label="School Type"
    //             options={[
    //                 { value: 'all', label: 'All' },
    //                 { value: 'public', label: 'Public' },
    //                 { value: 'private', label: 'Private' },
    //             ]}
    //         />
    //     ),
    //     initialValue: 'all',
    // },
    {
        name: 'schoolYear',
        label: 'School Year',
        component: props => <SchoolYear {...props} />,
        // initialValue: '2020-2021_state',
    },
];
