import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage, Dashboard as AppdefDashboard } from 'cccisd-laravel-appdefs';
import { Quest, ViewData, Progress } from 'cccisd-laravel-assignment';

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';

// Layouts
import MainLayout from './layouts/MainLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import SignUp from './pages/SignUp'; // Include it into main bundle
import AccountActivate from './pages/AccountActivate'; // Include it into main bundle
import Overview from './pages/Overview';
import StatePublicSchoolDataEntry from './pages/DataEntry/StatePublic';
import StatePrivateSchoolDataEntry from './pages/DataEntry/StatePrivate';
import DistrictDataEntry from './pages/DataEntry/District';
import PublicSchoolDataEntry from './pages/DataEntry/PublicSchools';
import PrivateSchoolDataEntry from './pages/DataEntry/PrivateSchools';
import SurveillanceDataEntry from './pages/DataEntry/Surveillance';
import SurveillanceESCDataEntry from './pages/DataEntry/IndividualESC';
import StateReports from './pages/Reports/State';
import DistrictReports from './pages/Reports/District';
import PublicSchoolReports from './pages/Reports/PublicSchool';
import PrivateSchoolReports from './pages/Reports/PrivateSchool';
import Reports from './pages/Reports';
import SiteContent from './pages/SiteContent';
import RolePicker from './pages/RolePicker';
import ExportData from './pages/ExportData';
import StateViewData from './pages/StateViewData';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefDashboard,
    AppdefManage,
    SignUp,
    AccountActivate,
    Overview,
    StatePublicSchoolDataEntry,
    StatePrivateSchoolDataEntry,
    DistrictDataEntry,
    PublicSchoolDataEntry,
    PrivateSchoolDataEntry,
    SurveillanceDataEntry,
    SurveillanceESCDataEntry,
    ViewData,
    Progress,
    Reports,
    SiteContent,
    ResourceCenter,
    ResourceManager,
    RolePicker,
    DistrictReports,
    PublicSchoolReports,
    StateReports,
    PrivateSchoolReports,
    ExportData,
    StateViewData,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            if (route.url === '/surveillance' || route.url === '/surveillanceESC') {
                const enableSurveillance = Fortress.user.acting.role_data.enableSurveillance === 1;

                if (
                    ['publicSchoolUser', 'privateSchoolUser'].includes(Fortress.user.acting.role.handle) &&
                    enableSurveillance
                ) {
                    return (
                        <AppRoute
                            key={route.handle}
                            path={route.url}
                            component={Component}
                            componentProps={route.componentProps}
                            exact
                            layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                        />
                    );
                }

                if (
                    !['publicSchoolUser', 'privateSchoolUser'].includes(Fortress.user.acting.role.handle) ||
                    !enableSurveillance
                ) {
                    return (
                        <AppRoute
                            key={route.handle}
                            path={Fortress.settings.config.after_login_url}
                            component={AuthRedirect}
                            exact
                        />
                    );
                }
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={AuthRedirect} layout={MainLayout} exact />
        <AppRoute path="/AuthRedirect" component={AuthRedirect} layout={MainLayout} exact />
        <AppRoute path="/welcome" component={SignUp} />

        <AppRoute path="/SignUp/:pathName?" component={SignUp} />

        <AppRoute path="/account/login" component={SignUp} />
        <AppRoute path="/account/activate/:activationHash?" component={AccountActivate} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={MainLayoutFluid} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/quest" component={Quest} layout={MainLayoutFluid} />
        )}
        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/Progress" component={Progress} />
        )}

        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/resources/admin" component={ResourceManager} layout={MainLayoutFluid} exact />
        )}

        {Fortress.auth() && (
            <AppRoute
                path="/rolePicker"
                component={RolePicker}
                layout={Fortress.isSuperUser() ? MainLayoutFluid : MainLayout}
            />
        )}

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
