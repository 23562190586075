import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import CccisdToggle from 'cccisd-toggle';
import { BulkUploader } from 'cccisd-laravel-nexus';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import Header from '../../../components/DataEntryHeader/District';
import DistrictTable from '../../../components/DistrictDataEntryTable';
import { getDeployments, getAssignmentProgressData, setBulkDefData, setImportData } from 'js/reducers/nasn.js';
import { useMetricspawnQuery } from 'js/queries';

const DistrictDataEntry = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [modalClosed, setModalClosed] = useState(0);

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();

    const metricspawnQuery = useMetricspawnQuery();

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await dispatch(getDeployments('district'));
            setIsLoading(false);
        })();
    }, []);

    const deploymentHandle = reduxStore.selectedDeployment;
    const deploymentId = reduxStore.deploymentId;
    const Boilerplate = window.cccisd.boilerplate;

    useEffect(() => {
        // Extra check because we don't want this running with deploymentHandle's initial, falsy value
        if (deploymentHandle && !isLoading) {
            // DeploymentPlayer doesn't re-render when deploymentHandle is changed, so we setLoading here so entire page is forced to re-render.
            setIsLoading(true);
            (async () => {
                const stateDeploymentHandle = deploymentHandle.slice(0, 9) + '_state';

                dispatch(
                    getAssignmentProgressData({
                        groupType: 'district',
                        deploymentHandle,
                        stateDeploymentHandle,
                    })
                );
                setIsLoading(false);
            })();
        }
    }, [deploymentHandle, modalClosed, isLoading]);

    const getBulkUploadDef = async () => {
        if (!deploymentId) {
            setIsLoading(false);
            console.error('No deployment id; could not retrieve bulk upload definition');
            return;
        }
        if (!reduxStore.bulkDefData[deploymentId]) {
            const response = await Axios.get(Boilerplate.route('api.nexus.bulkupload.deploymentRespondentMetricsDef'), {
                params: { deploymentId },
            });
            const responseBulkDef = _get(response, 'data.result.original.data');
            if (responseBulkDef) {
                dispatch(setBulkDefData({ [deploymentId]: responseBulkDef }));
                return responseBulkDef;
            }

            console.error('Could not retrieve bulk upload definition');
        }
        return {};
    };

    const handleImportToggle = async importGroup => {
        if (importGroup === 'district') {
            const newImportToggleValue = !reduxStore.importData.district.importDistrictData;
            if (newImportToggleValue === true) {
                // only save the group as a respondent if toggle is true
                await Axios.post(Boilerplate.route('api.assignmentDeploymentRespondent.store', { deploymentId }), {
                    pawnIds: [metricspawnQuery.data.metricspawn.pawnId],
                });
            }
        }

        await Axios.post(Boilerplate.route('api.assignmentProgress.update'), {
            deploymentId: reduxStore.deploymentId,
            pawnId: metricspawnQuery.data.metricspawn.pawnId,
            flowlist: 'default',
            additional_info: {
                districtEntersData: true,
                importData: {
                    importDistrictData:
                        importGroup === 'district' ? !reduxStore.importData.district.importDistrictData : false,
                    importSchoolData:
                        importGroup === 'school' ? !reduxStore.importData.district.importSchoolData : false,
                },
            },
        });
        dispatch(
            setImportData({
                district: {
                    importDistrictData:
                        importGroup === 'district' ? !reduxStore.importData.district.importDistrictData : false,
                    importSchoolData:
                        importGroup === 'school' ? !reduxStore.importData.district.importSchoolData : false,
                },
            })
        );
    };

    const render = () => {
        const { districtEntersData, stateEntersData, deploymentIsOpen, bulkDefData } = reduxStore;
        const bulkDef = bulkDefData[deploymentId];
        if (!deploymentIsOpen) {
            return (
                <>
                    <Header
                        deploymentId={deploymentId}
                        deploymentHandle={deploymentHandle}
                        districtEntersData={reduxStore.districtEntersData}
                        options={reduxStore.deployments}
                        metricsPawn={metricspawnQuery.data.metricspawn}
                        stateEntersData={stateEntersData}
                        deploymentIsOpen={deploymentIsOpen}
                    />
                    <DistrictTable deploymentIsOpen={deploymentIsOpen} deploymentHandle={deploymentHandle} />
                </>
            );
        }
        if (stateEntersData) {
            return (
                <Header
                    deploymentId={deploymentId}
                    deploymentHandle={deploymentHandle}
                    districtEntersData={districtEntersData}
                    options={reduxStore.deployments}
                    metricsPawn={metricspawnQuery.data.metricspawn}
                    stateEntersData={stateEntersData}
                    deploymentIsOpen={deploymentIsOpen}
                />
            );
        }
        return (
            <>
                <Header
                    deploymentId={deploymentId}
                    deploymentHandle={deploymentHandle}
                    districtEntersData={districtEntersData}
                    options={reduxStore.deployments}
                    metricsPawn={metricspawnQuery.data.metricspawn}
                    stateEntersData={stateEntersData}
                    deploymentIsOpen={deploymentIsOpen}
                />
                {districtEntersData ? (
                    <>
                        <div style={{ display: 'grid', gridRowGap: '0.5em', marginBottom: '1em' }}>
                            <CccisdToggle
                                label="Will you import aggregate district data this year?"
                                value={reduxStore.importData.district.importDistrictData}
                                onChange={() => handleImportToggle('district')}
                            />
                            <CccisdToggle
                                label="Will you import data for each school this year?"
                                value={reduxStore.importData.district.importSchoolData}
                                onChange={() => handleImportToggle('school')}
                            />
                        </div>
                        {!reduxStore.importData.district.importDistrictData ? (
                            <>
                                {!reduxStore.importData.district.importSchoolData ? (
                                    <DeploymentPlayer
                                        deploymentHandle={deploymentHandle}
                                        pawnId={metricspawnQuery.data.metricspawn.pawnId}
                                        pawnHash={metricspawnQuery.data.metricspawn.pawnHash}
                                        onComplete={() => {
                                            setIsLoading(true);
                                            setIsLoading(false);
                                        }}
                                        disableLayout
                                        enablePrintMode
                                    />
                                ) : (
                                    <DistrictTable
                                        deploymentIsOpen={deploymentIsOpen}
                                        deploymentHandle={deploymentHandle}
                                        willImportSchoolData={reduxStore.importData.district.importSchoolData}
                                        bulkDef={bulkDef}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <div className="alert alert-info" role="alert">
                                    Please reach out to NASN staff at{' '}
                                    <a href="mailto:data@nasn.org" className="alert-link">
                                        data@nasn.org
                                    </a>{' '}
                                    for technical assistance before your first upload.
                                </div>

                                <>
                                    <BulkUploader
                                        handle="deploymentRespondentMetrics"
                                        trigger={
                                            <button type="button" className="btn btn-primary">
                                                Bulk Import District Data
                                            </button>
                                        }
                                        definition={bulkDef ? bulkDef : undefined}
                                        loadDefinition={getBulkUploadDef}
                                        deploymentId={deploymentId}
                                        afterClose={() => setModalClosed(prevVal => prevVal + 1)}
                                    />
                                    {reduxStore.importedInfo.imported && (
                                        <div style={{ fontStyle: 'italic' }}>
                                            {' '}
                                            Last imported on{' '}
                                            {reduxStore.importedInfo.importedDate &&
                                                reduxStore.importedInfo.importedDate.slice(0, 10)}
                                        </div>
                                    )}
                                </>
                            </>
                        )}
                    </>
                ) : (
                    <DistrictTable deploymentIsOpen={deploymentIsOpen} deploymentHandle={deploymentHandle} />
                )}
            </>
        );
    };

    if (
        isLoading ||
        !reduxStore.assignmentProgressDataLoaded ||
        !reduxStore.deploymentDataLoaded ||
        metricspawnQuery.isLoading
    ) {
        return <Loader loading />;
    }

    return <>{render()}</>;
};

DistrictDataEntry.propTypes = {
    defaultProp: PropTypes.string,
    willImportSchoolData: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default DistrictDataEntry;
