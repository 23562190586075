import React from 'react';
import ConstructionIcon from 'cccisd-icons/construction';

const Report = () => {
    return (
        <div className="alert alert-info" role="alert">
            <ConstructionIcon spaceRight />
            Coming Soon
            <ConstructionIcon spaceLeft />
        </div>
    );
};

export default Report;
