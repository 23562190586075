import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FieldBlock } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Editor from 'cccisd-wysiwyg';
import Accordion from 'js/components/Accordion';
import reportFields from 'js/reducers/siteFields/reportFields.js';
import style from '../style.css';

const Form = props => {
    Form.propTypes = {
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
    };

    const domainTitles = reportFields.domains.map(domain => domain.title);

    // The first accordion is open initially
    const [collapsed, setCollapsed] = useState(domainTitles.slice(1));

    const collapseAll = () => {
        setCollapsed(domainTitles);
    };

    const expandAll = () => {
        setCollapsed([]);
    };

    const handleCollapse = id => {
        if (collapsed.includes(id)) {
            return setCollapsed(collapsed.filter(tabId => tabId !== id));
        }
        return setCollapsed([...collapsed, id]);
    };

    const { fields } = props;

    return (
        <>
            <div className={style.pageMenu}>
                <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={collapseAll}
                    style={{ marginRight: '1em' }}
                >
                    Collapse All
                </button>
                <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={expandAll}
                    style={{ marginRight: '1em' }}
                >
                    Expand All
                </button>
                <div>
                    <ClickButton
                        title="Save"
                        isLoading={props.submitting}
                        onClick={props.handleSubmit}
                        className="btn btn-primary"
                    />
                </div>
            </div>
            <form onSubmit={props.handleSubmit}>
                {reportFields.domains.map(domain => (
                    <Accordion
                        key={domain.title}
                        title={domain.title}
                        id={domain.title}
                        handleCollapse={handleCollapse}
                        collapsed={collapsed}
                    >
                        {domain.messages.map(handle => (
                            <FieldBlock
                                key={handle}
                                field={fields[handle]}
                                label={reportFields.messages[handle].title}
                            >
                                <Editor
                                    content={fields[handle].value}
                                    onChange={fields[handle].onChange}
                                />
                            </FieldBlock>
                        ))}
                    </Accordion>
                ))}
            </form>
        </>
    );
};

export default reduxForm({ form: 'MessagesForm' })(Form);
