import React from 'react';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import style from './style.css';

export default props => {
    return (
        <div className={style.wrapper}>
            <div className={style.header} onClick={() => props.handleCollapse(props.id)}>
                {props.collapsed.includes(props.id) ? (
                    <IconArrowRight spaceRight />
                ) : (
                    <IconArrowDown spaceRight />
                )}
                <span className={style.headerText}>{props.title}</span>
            </div>
            {!props.collapsed.includes(props.id) && (
                <div className={style.content}>{props.children}</div>
            )}
        </div>
    );
};
