import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { taskMasterClient, gql } from 'cccisd-apollo';
import { BrowserOnly } from 'cccisd-laravel-report';
import _isEqual from 'lodash/isEqual';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';

import query from './absenteeism.graphql';
import absenteeismVariables from './absenteeismVariables.js';

import _round from 'lodash/round';

const Component = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const reduxStore = useSelector(state => state.app.nasn);
    const deploymentHandle = reduxStore.selectedDeployment.slice(0, 9) + `_individualESC`;
    const variables = {
        metricspawns: [window.cccisd.fortress.user.acting.id],
        deploymentHandle,
        absenteeism: absenteeismVariables.publicSchool,
    };
    const { reportDataLoading } = reduxStore;
    const isFirst = useRef(true);
    const prevDeps = useRef(variables);

    useEffect(() => {
        const isSame = _isEqual(variables, prevDeps.current);
        if (!reportDataLoading) {
            if (isFirst.current || !isSame) {
                (async () => {
                    setLoading(true);
                    const formattedQuery = gql`
                        ${query}
                    `;
                    const response = await taskMasterClient.query({
                        query: formattedQuery,
                        variables,
                        fetchPolicy: 'network-only',
                    });
                    setData(response.data);
                    setLoading(false);
                })();
            }
            isFirst.current = false;
            prevDeps.current = variables;
        }
    }, [reduxStore.selectedDeployment, reportDataLoading]);

    const getData = () => {
        if (!loading) {
            const check = data.roles;
            if (check) {
                let totalAbsent = data.roles.anyRoleSummary.totalabsent;
                totalAbsent ? totalAbsent : (totalAbsent = data.roles.anyRoleSummary.totalAnnAbsent);
                const totalStudents = data.roles.anyRoleSummary.totalstudents;
                const per1000 = (totalAbsent / totalStudents) * 1000;

                if (!totalStudents) {
                    return {
                        absenteeism: '?',
                    };
                }
                return { absenteeism: _round(per1000, 2) || '?' };
            }
        }
        return { absenteeism: '?' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Absenteeism'}
                        </div>
                        {!loading && !data.roles.anyRoleSummary.totalstudents && (
                            <BrowserOnly>
                                <div
                                    style={{
                                        fontSize: '0.58em',
                                        color: 'red',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    Data for this section cannot be calculated without entering total student
                                    population.
                                </div>
                            </BrowserOnly>
                        )}
                    </div>

                    <div className={style.content}>
                        {loading ? (
                            <Skeleton height={84} />
                        ) : (
                            <div className={style.box}>
                                <div className={style.boxIcon} />
                                <div className={style.boxText}>
                                    <div style={{ fontWeight: 'bold' }}>Students chronically absent per month</div>
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{getData().absenteeism}</span> / 1000
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
