import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const { group } = props;

    const [message, setMessage] = useState('');
    const reduxStore = useSelector(state => state.app.nasn);
    const firstUpdate = useRef(true);

    const { schoolsHaveData, districtsHaveData } = reduxStore;
    const {
        header: { title, createdDateText, schoolYearText },
    } = props.settings;

    const getGroupName = () => {
        const user = Fortress.user.acting;
        if (['state', 'statePrivate'].includes(group.groupType)) {
            return user.group.type_data.stateName;
        }
        if (group.label) {
            return group.label;
        }
        return 'Group Name';
    };

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (reduxStore.reportMetricsIdsLoaded) {
            getAggregateCompletionMessage();
        }
    }, [schoolsHaveData, districtsHaveData, reduxStore.selectedDeployment, reduxStore.reportMetricsIdsLoaded]);

    const getAggregateCompletionMessage = () => {
        if (props.group.groupType === 'district') {
            if (schoolsHaveData && districtsHaveData) {
                setMessage('Data entered at the district and school level');
                return;
            }
            if (schoolsHaveData && !districtsHaveData) {
                setMessage('Data entered at the public school level.');
                return;
            }
            if (districtsHaveData && !schoolsHaveData) {
                setMessage('');
                return;
            }
            if (!districtsHaveData && !schoolsHaveData) {
                setMessage('Data entered at the state level.');
                return;
            }
        }

        if (props.group.groupType === 'state') {
            if (schoolsHaveData && districtsHaveData) {
                setMessage('Data entered at the district and school level.');
                return;
            }
            if (districtsHaveData && !schoolsHaveData) {
                setMessage('Data entered at the district level.');
                return;
            }
            if (schoolsHaveData && !districtsHaveData) {
                setMessage('Data entered at the public school level.');
                return;
            }
        }

        if (props.group.groupType === 'publicSchool') {
            if (!districtsHaveData && !schoolsHaveData) {
                setMessage('Data entered at the state level.');
                return;
            }
            if (schoolsHaveData && !districtsHaveData) {
                setMessage('');
                return;
            }
            if (districtsHaveData && !schoolsHaveData) {
                setMessage('Data entered at the district level.');
                return;
            }
        }
        return message;
    };

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <div className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>{getGroupName()}</div>
                    <div className={style.reportType}>{title}</div>
                    <div className={style.reportType}>{message}</div>
                </div>
                <div className={style.info}>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                        {moment(new Date()).format('MMMM Do YYYY')}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{schoolYearText} </span>
                        <span style={{ textTransform: 'capitalize' }}>{reduxStore.selectedDeployment.slice(0, 9)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    schoolType: PropTypes.string,
    group: PropTypes.object,
    schoolYear: PropTypes.string,
    reportGroupType: PropTypes.string,
    districtsEnteringData: PropTypes.array,
    schoolsEnteringData: PropTypes.array,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'Overview Report',
            createdDateText: 'Report Created On:',
            schoolYearText: 'School Year:',
            schoolTypeText: 'School Type:',
        },
    },
    group: { groupType: 'state', label: 'My Group' },
    reportGroupType: 'state',
};

export default ReportHeader;
