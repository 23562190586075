import React from 'react';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { useDeploymentsQuery } from 'js/queries';

const Component = props => {
    const deploymentsQuery = useDeploymentsQuery([`deployments ${props.group.groupType}`], props.group.groupType);

    if (deploymentsQuery.isLoading) {
        return <Loader loading />;
    }

    return (
        <Player
            {...props}
            metricspawns={props.metricspawns}
            options={deploymentsQuery.data?.deployments}
            reportGroupType={props.groupType}
            group={props.group}
            schoolYear={deploymentsQuery.data?.selectedDeployment}
            tabSelected={props.tabSelected}
            thisGroupReport={props.thisGroupReport}
            selectedDeployment={deploymentsQuery.data?.selectedDeployment}
        />
    );
};

export default Component;
