import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import { getSavedParams } from 'js/reducers/params.js';
import overviewFields from 'js/reducers/siteFields/overviewFields.js';
import style from './style.css';

// icons
import IconPublic from 'cccisd-icons/pencil5';
import IconPrivate from 'cccisd-icons/pencil7';
import IconReports from 'cccisd-icons/file-presentation';
import IconResourceCenter from 'cccisd-icons/image2';
import IconManage from 'cccisd-icons/cog';

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.role.handle;
const userGroup = Fortress.user.acting.group;
const enableSurveillance = Fortress.user.acting.role_data.enableSurveillance === 1;

const Overview = props => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await props.getSavedParams(overviewFields, 'overview');
            setIsLoading(false);
        })();
    }, []);

    const getPageTitle = () => {
        if (userGroup) {
            if (userGroup.data_type === 'state') {
                const stateName = userGroup.type_data.stateName;
                return stateName;
            }
            return userGroup.label;
        }
        return 'Individual Account';
    };

    if (isLoading) {
        return <Loader />;
    }

    const getPageSubtitle = () => {
        if (userGroup) {
            if (userGroup.data_type === 'state') {
                return <Html content={props.overviewFields.stateSubtitle} />;
            }
            if (userGroup.data_type === 'district') {
                return <Html content={props.overviewFields.districtSubtitle} />;
            }
            if (userGroup.data_type === 'privateSchool') {
                return <Html content={props.overviewFields.privateSchoolSubtitle} />;
            }
            return <Html content={props.overviewFields.publicSchoolSubtitle} />;
        }
    };

    const stateCallouts = [
        {
            link: '/publicSchools',
            icon: <IconPublic />,
            content: <Html content={props.overviewFields.statePublicSchoolDE} />,
        },
        {
            link: '/privateSchools',
            icon: <IconPrivate />,
            content: <Html content={props.overviewFields.statePrivateSchoolDE} />,
        },
        {
            link: '/reports',
            icon: <IconReports />,
            content: <Html content={props.overviewFields.stateReports} />,
        },
        {
            link: '/resourceCenter',
            icon: <IconResourceCenter />,
            content: <Html content={props.overviewFields.stateResourceCenter} />,
        },
        {
            link: '/manage',
            icon: <IconManage />,
            content: <Html content={props.overviewFields.stateManage} />,
        },
    ];

    const districtCallouts = [
        {
            link: '/dataEntry',
            icon: <IconPublic />,
            content: <Html content={props.overviewFields.districtDE} />,
        },
        {
            link: '/reports',
            icon: <IconReports />,
            content: <Html content={props.overviewFields.districtReports} />,
        },
        {
            link: '/resourceCenter',
            icon: <IconResourceCenter />,
            content: <Html content={props.overviewFields.districtResourceCenter} />,
        },
        {
            link: '/manage',
            icon: <IconManage />,
            content: <Html content={props.overviewFields.districtManage} />,
        },
    ];

    const publicSchoolCallouts = [
        {
            link: '/dataEntry',
            icon: <IconPublic />,
            content: <Html content={props.overviewFields.publicSchoolDE} />,
        },
        {
            link: '/reports',
            icon: <IconReports />,
            content: <Html content={props.overviewFields.publicSchoolReports} />,
        },
        {
            link: '/resourceCenter',
            icon: <IconResourceCenter />,
            content: <Html content={props.overviewFields.publicSchoolResourceCenter} />,
        },
    ];

    const privateSchoolCallouts = [
        {
            link: '/dataEntry',
            icon: <IconPublic />,
            content: <Html content={props.overviewFields.privateSchoolDE} />,
        },
        {
            link: '/reports',
            icon: <IconReports />,
            content: <Html content={props.overviewFields.privateSchoolReports} />,
        },
        {
            link: '/resourceCenter',
            icon: <IconResourceCenter />,
            content: <Html content={props.overviewFields.privateSchoolResourceCenter} />,
        },
    ];

    let callouts = stateCallouts;

    if (['districtAdmin', 'districtTeamMember'].includes(userRole)) {
        callouts = districtCallouts;
    }

    if (userRole === 'publicSchoolUser') {
        if (enableSurveillance) {
            publicSchoolCallouts.splice(
                1,
                0,
                ...[
                    {
                        link: '/surveillance',
                        icon: <IconPublic />,
                        content: <Html content={props.overviewFields.publicSchoolSurveillance} />,
                    },
                    {
                        link: '/surveillanceESC',
                        icon: <IconPublic />,
                        content: <Html content={props.overviewFields.publicSchoolSurveillanceESC} />,
                    },
                ]
            );
            callouts = publicSchoolCallouts.filter(x => x.link !== '/dataEntry');
        } else {
            callouts = publicSchoolCallouts;
        }
    }

    if (userRole === 'privateSchoolUser') {
        if (enableSurveillance) {
            privateSchoolCallouts.splice(
                1,
                0,
                ...[
                    {
                        link: '/surveillance',
                        icon: <IconPublic />,
                        content: <Html content={props.overviewFields.privateSchoolSurveillance} />,
                    },
                    {
                        link: '/surveillanceESC',
                        icon: <IconPublic />,
                        content: <Html content={props.overviewFields.privateSchoolSurveillanceESC} />,
                    },
                ]
            );
            callouts = privateSchoolCallouts.filter(x => x.link !== '/dataEntry');
        } else {
            callouts = privateSchoolCallouts;
        }
    }

    return (
        <div>
            <div className={style.pageHeader}>
                <h3>{getPageTitle()}</h3>
                <div className={style.welcomeText}>{getPageSubtitle()}</div>
            </div>
            <div className={style.callouts}>
                {callouts.map(callout => (
                    <div className={style.calloutBoxWrapper} key={callout.link}>
                        <NavLink to={callout.link}>
                            <div className={style.calloutBox}>
                                <div className={style.iconBox}>{callout.icon}</div>
                                <div className={style.calloutText}>{callout.content}</div>
                            </div>
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        overviewFields: state.app.params.overviewFields,
    };
};

export default connect(mapStateToProps, { getSavedParams })(Overview);
