import { handleActions, createAction } from 'redux-actions';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import _pick from 'lodash/pick';

import deploymentsQuery from './deploymentsQuery.graphql';
import publicSchoolAssignmentProgressQuery from './publicSchoolAssignmentProgress.graphql';
import privateSchoolAssignmentProgressQuery from './privateSchoolAssignmentProgress.graphql';
import districtAssignmentProgressQuery from './districtAssignmentProgress.graphql';
import stateAssignmentProgressQuery from './stateAssignmentProgress.graphql';
import statePrivateAssignmentProgressQuery from './statePrivateAssignmentProgress.graphql';

const Boilerplate = window.cccisd.boilerplate;

export const initialState = {
    userGroups: [],
    metricspawn: {},
    deployments: [{ label: '', value: '' }],
    deploymentsInfo: [],
    selectedDeployment: '',
    deploymentId: '',
    districtEntersData: '',
    stateEntersData: '',
    stateEntersPrivateData: '',
    deploymentIsOpen: '',
    existingAdditionalInfo: {},
    reportDataLoading: false,
    reportMetricsIds: [],
    reportMetricsIdsLoaded: false,
    schoolsEnteringData: [],
    districtsEnteringData: [],
    assignmentProgressDataLoaded: false,
    deploymentDataLoaded: false,
    comparisonData: {},
    comparisonDataLoaded: false,
    nationalData: {},
    nationalDataLoaded: false,
    bulkDefData: {},
    importData: {
        statePublic: { importStateData: false, importDistrictData: false, importSchoolData: false },
        statePrivate: { importStateData: false, importSchoolData: false },
        district: { importDistrictData: false, importSchoolData: false },
        publicSchool: false,
        privateSchool: false,
    },
    importedInfo: {
        imported: false,
        importedDate: '',
    },
    districtsHaveData: false,
    schoolsHaveData: false,
    reportGroupType: '',
};

// Actions
const SET_USER_GROUPS = 'app/nasn/SET_USER_GROUPS';
const SET_METRICSPAWN = 'app/nasn/SET_METRICSPAWN';
const SET_DEPLOYMENTS = 'app/nasn/SET_DEPLOYMENTS';
const SET_DEPLOYMENTS_INFO = 'app/nasn/SET_DEPLOYMENTS_INFO';
const SET_SELECTED_DEPLOYMENT = 'app/nasn/SET_SELECTED_DEPLOYMENT';
const SET_DISTRICT_ENTERS_DATA = 'app/nasn/SET_DISTRICT_ENTERS_DATA';
const SET_STATE_ENTERS_DATA = 'app/nasn/SET_STATE_ENTERS_DATA';
const SET_STATE_ENTERS_PRIVATE_DATA = 'app/nasn/SET_STATE_ENTERS_PRIVATE_DATA';
const SET_DEPLOYMENT_IS_OPEN = 'app/nasn/SET_DEPLOYMENT_IS_OPEN';
const SET_DEPLOYMENT_ID = 'app/nasn/SET_DEPLOYMENT_ID';
const SET_EXISTING_ADDITIONAL_INFO = 'app/nasn/SET_EXISTING_ADDITIONAL_INFO';
const SET_REPORT_DATA_LOADING = 'app/nasn/SET_REPORT_DATA_LOADING';
const SET_REPORT_METRICS_IDS = 'app/nasn/SET_REPORT_METRICS_IDS';
const SET_REPORT_METRICS_IDS_LOADED = 'app/nasn/SET_REPORT_METRICS_IDS_LOADED';
const SET_SCHOOLS_ENTERING_DATA = 'app/nasn/SET_SCHOOLS_ENTERING_DATA';
const SET_DISTRICTS_ENTERING_DATA = 'app/nasn/SET_DISTRICTS_ENTERING_DATA';
const SET_ASSIGNMENT_PROGRESS_DATA_LOADED = 'app/nasn/SET_ASSIGNMENT_PROGRESS_DATA_LOADED';
const SET_DEPLOYMENT_DATA_LOADED = 'app/nasn/SET_DEPLOYMENT_DATA_LOADED';
const SET_COMPARISON_DATA = 'app/nasn/SET_COMPARISON_DATA';
const SET_COMPARISON_DATA_LOADED = 'app/nasn/SET_COMPARISON_DATA_LOADED';
const SET_NATIONAL_DATA = 'app/nasn/SET_NATIONAL_DATA';
const SET_NATIONAL_DATA_LOADED = 'app/nasn/SET_NATIONAL_DATA_LOADED';
const SET_BULK_DEF_DATA = 'app/nasn/SET_BULK_DEF_DATA';
const SET_IMPORT_DATA = 'app/nasn/SET_IMPORT_DATA';
const SET_IMPORTED_INFO = 'app/nasn/SET_IMPORTED_INFO';
const SET_DISTRICTS_HAVE_DATA = 'app/nasn/SET_DISTRICTS_HAVE_DATA';
const SET_SCHOOLS_HAVE_DATA = 'app/nasn/SET_SCHOOLS_HAVE_DATA';
const SET_REPORT_GROUP_TYPE = 'app/nasn/SET_REPORT_GROUP_TYPE';

// Action Creators
export const setUserGroups = createAction(SET_USER_GROUPS);
export const setMetricsPawn = createAction(SET_METRICSPAWN);
export const setDeployments = createAction(SET_DEPLOYMENTS);
export const setDeploymentsInfo = createAction(SET_DEPLOYMENTS_INFO);
export const setSelectedDeployment = createAction(SET_SELECTED_DEPLOYMENT);
export const setDistrictEntersData = createAction(SET_DISTRICT_ENTERS_DATA);
export const setStateEntersData = createAction(SET_STATE_ENTERS_DATA);
export const setStateEntersPrivateData = createAction(SET_STATE_ENTERS_PRIVATE_DATA);
export const setDeploymentIsOpen = createAction(SET_DEPLOYMENT_IS_OPEN);
export const setDeploymentId = createAction(SET_DEPLOYMENT_ID);
export const setExistingAdditionalInfo = createAction(SET_EXISTING_ADDITIONAL_INFO);
export const setReportDataLoading = createAction(SET_REPORT_DATA_LOADING);
export const setReportMetricsIds = createAction(SET_REPORT_METRICS_IDS);
export const setReportMetricsIdsLoaded = createAction(SET_REPORT_METRICS_IDS_LOADED);
export const setSchoolsEnteringData = createAction(SET_SCHOOLS_ENTERING_DATA);
export const setDistrictsEnteringData = createAction(SET_DISTRICTS_ENTERING_DATA);
export const setAssignmentProgressDataLoaded = createAction(SET_ASSIGNMENT_PROGRESS_DATA_LOADED);
export const setDeploymentDataLoaded = createAction(SET_DEPLOYMENT_DATA_LOADED);
export const setComparisonData = createAction(SET_COMPARISON_DATA);
export const setComparisonDataLoaded = createAction(SET_COMPARISON_DATA_LOADED);
export const setNationalData = createAction(SET_NATIONAL_DATA);
export const setNationalDataLoaded = createAction(SET_NATIONAL_DATA_LOADED);
export const setBulkDefData = createAction(SET_BULK_DEF_DATA);
export const setImportData = createAction(SET_IMPORT_DATA);
export const setImportedInfo = createAction(SET_IMPORTED_INFO);
export const setDistrictsHaveData = createAction(SET_DISTRICTS_HAVE_DATA);
export const setSchoolsHaveData = createAction(SET_SCHOOLS_HAVE_DATA);
export const setReportGroupType = createAction(SET_REPORT_GROUP_TYPE);

export const getUserGroups = () => {
    return async (dispatch, getState) => {
        if (!getState().app.nasn.userGroups.length > 0) {
            let response = await axios.get(Boilerplate.route('all.pawn.roles'));
            const userGroupData = response.data.roles.map(role => {
                return _pick(role, ['id', 'random_hash', 'data_type', 'group']);
            });
            await dispatch(setUserGroups(userGroupData));
        }
    };
};

export const getDeployments = userType => {
    return async (dispatch, getState) => {
        // dispatch(setDeploymentDataLoaded(false));
        const result = await apollo.query({
            query: deploymentsQuery,
            fetchPolicy: 'network-only',
            variables: {
                assignmentHandle: userType,
            },
        });

        const deploymentList = result.data.flows.deploymentList;

        const openDeployments = deploymentList.filter(i => i.closed === false);
        const closedDeployments = deploymentList.filter(i => i.closed === true);
        const schoolYearList = [...closedDeployments, ...openDeployments];

        if (deploymentList.length > 0) {
            await dispatch(setDeployments(schoolYearList.map(x => ({ value: x.deploymentHandle, label: x.label }))));
            await dispatch(setDeploymentsInfo(schoolYearList));
            await dispatch(setSelectedDeployment(schoolYearList[schoolYearList.length - 1].deploymentHandle));
            await dispatch(setDeploymentId(schoolYearList[schoolYearList.length - 1].deploymentId));
        }
        dispatch(setDeploymentDataLoaded(true));
    };
};

const getImportedInfo = (assignmentProgress, flowProgress) => {
    // console.log('imported info', assignmentProgress, flowProgress);
    const assignmentFinished = assignmentProgress.status === 'Complete';
    const allSurveysFinished = flowProgress.every(f => f.status === 'Complete');
    const importedDate = assignmentProgress.completedAt || assignmentProgress.lastVisitedAt;
    return async (dispatch, getState) => {
        // console.log(assignmentFinished, allSurveysFinished, importedDate);
        if (assignmentFinished && !allSurveysFinished) {
            dispatch(setImportedInfo({ imported: true, importedDate }));
        } else {
            dispatch(setImportedInfo({ imported: false, importedDate: '' }));
        }
    };
};

export const getAssignmentProgressData = ({
    groupType,
    deploymentHandle,
    districtDeploymentHandle,
    stateDeploymentHandle,
}) => {
    return async (dispatch, getState) => {
        dispatch(setAssignmentProgressDataLoaded(false));
        if (groupType === 'publicSchool') {
            const result = await apollo.query({
                query: publicSchoolAssignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                    districtDeploymentHandle,
                    stateDeploymentHandle,
                },
            });

            if (!result.data.flows.deploymentList.length > 0) {
                return;
            }

            const publicSchoolMetricsPawn = result.data.groups.publicSchool.childRoles.metricspawn;
            const districtMetricsPawn = result.data.groups.publicSchool.parentGroup.district.childRoles.metricspawn;
            const stateMetricsPawn = result.data.groups.publicSchool.ancestorGroups.state.childRoles.metricspawn;

            const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;
            dispatch(setDeploymentIsOpen(isOpenDeployment));

            /*
             *  PUBLIC SCHOOL - Import Data
             */
            if (publicSchoolMetricsPawn.assignmentProgressList.length > 0) {
                dispatch(
                    getImportedInfo(
                        publicSchoolMetricsPawn.assignmentProgressList[0],
                        publicSchoolMetricsPawn.flowProgressList.filter(
                            f => f.responseSet === result.data.flows.deploymentList[0].deploymentId
                        )
                    )
                );
                const additionalInfo = publicSchoolMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (
                    typeof additionalInfo === 'object' &&
                    Object.keys(additionalInfo).includes('publicSchoolImportData')
                ) {
                    dispatch(setImportData({ publicSchool: additionalInfo.publicSchoolImportData }));
                }
            } else {
                _setAdditionalInfo(publicSchoolMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                    publicSchoolImportData: getState().app.nasn.importData.publicSchool,
                });
            }

            /*
             *  PUBLIC SCHOOL - District or State enter data
             */
            if (districtMetricsPawn.assignmentProgressList.length > 0) {
                const districtAdditionalInfo = districtMetricsPawn.assignmentProgressList[0].additionalInfo;

                if (typeof districtAdditionalInfo === 'object') {
                    if (Object.keys(districtAdditionalInfo).includes('districtEntersData')) {
                        dispatch(setDistrictEntersData(districtAdditionalInfo.districtEntersData));
                    } else {
                        dispatch(setDistrictEntersData(false));
                    }

                    if (Object.keys(districtAdditionalInfo).includes('importData')) {
                        dispatch(setImportData({ district: districtAdditionalInfo.importData }));
                    }
                }
            }
            if (stateMetricsPawn.assignmentProgressList.length > 0) {
                const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (typeof stateAdditionalInfo === 'object') {
                    if (Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')) {
                        dispatch(setStateEntersData(stateAdditionalInfo.stateEntersPublicData));
                    } else {
                        dispatch(setStateEntersData(false));
                    }
                    if (Object.keys(stateAdditionalInfo).includes('importData')) {
                        dispatch(setImportData({ statePublic: stateAdditionalInfo.importData }));
                    }
                }
            }
            dispatch(setAssignmentProgressDataLoaded(true));
        }

        if (groupType === 'district') {
            const result = await apollo.query({
                query: districtAssignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                    stateDeploymentHandle,
                },
            });
            // console.log('district', result.data);
            dispatch(setDeploymentId(Number(result.data.flows.deploymentList[0].deploymentId)));

            const districtMetricsPawn = result.data.groups.district.childRoles.metricspawn;
            const stateMetricsPawn = result.data.groups.district.parentGroup.state.childRoles.metricspawn;

            const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;
            dispatch(setDeploymentIsOpen(isOpenDeployment));

            /*
             *  DISTRICT - Import district or school data
             */
            if (districtMetricsPawn.assignmentProgressList.length > 0) {
                dispatch(
                    getImportedInfo(
                        districtMetricsPawn.assignmentProgressList[0],
                        districtMetricsPawn.flowProgressList.filter(
                            f => f.responseSet === result.data.flows.deploymentList[0].deploymentId
                        )
                    )
                );
                const additionalInfo = districtMetricsPawn.assignmentProgressList[0].additionalInfo;

                if (Object.keys(additionalInfo).includes('districtEntersData')) {
                    dispatch(setDistrictEntersData(additionalInfo.districtEntersData));
                }
                if (Object.keys(additionalInfo).includes('importData')) {
                    dispatch(
                        setImportData({
                            district: {
                                importDistrictData: additionalInfo.importData.importDistrictData,
                                importSchoolData: additionalInfo.importData.importSchoolData,
                            },
                        })
                    );
                }

                if (
                    !Object.keys(additionalInfo).includes('importData') ||
                    !Object.keys(additionalInfo).includes('districtEntersData')
                ) {
                    _setAdditionalInfo(districtMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                        districtEntersData: getState().app.nasn.districtEntersData,
                        importData: {
                            importDistrictData: getState().app.nasn.importData.district.importDistrictData,
                            importSchoolData: getState().app.nasn.importData.district.importSchoolData,
                        },
                    });
                }
            } else {
                _setAdditionalInfo(districtMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                    districtEntersData: getState().app.nasn.districtEntersData,
                    importData: {
                        importDistrictData: getState().app.nasn.importData.district.importDistrictData,
                        importSchoolData: getState().app.nasn.importData.district.importSchoolData,
                    },
                });
            }

            /*
             *  DISTRICT - State enters data
             */
            if (stateMetricsPawn.assignmentProgressList.length > 0) {
                const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (typeof stateAdditionalInfo === 'object') {
                    if (Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')) {
                        dispatch(setStateEntersData(stateAdditionalInfo.stateEntersPublicData));
                    } else {
                        dispatch(setStateEntersData(false));
                    }

                    if (Object.keys(stateAdditionalInfo).includes('importData')) {
                        dispatch(setImportData({ statePublic: stateAdditionalInfo.importData }));
                    }
                }
            } else {
                dispatch(setStateEntersData(false));
            }
            dispatch(setAssignmentProgressDataLoaded(true));
        }

        if (groupType === 'state') {
            const result = await apollo.query({
                query: stateAssignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                },
            });
            // console.log('state', result.data);
            const stateMetricsPawn = result.data.groups.state.childRoles.metricspawn;
            const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;

            dispatch(setDeploymentIsOpen(isOpenDeployment));
            /*
             *  STATE - State enters public data
             */
            if (stateMetricsPawn.assignmentProgressList.length > 0) {
                dispatch(
                    getImportedInfo(
                        stateMetricsPawn.assignmentProgressList[0],
                        stateMetricsPawn.flowProgressList.filter(
                            f => f.responseSet === result.data.flows.deploymentList[0].deploymentId
                        )
                    )
                );
                const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (
                    typeof stateAdditionalInfo === 'object' &&
                    Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')
                ) {
                    dispatch(
                        setStateEntersData(
                            stateMetricsPawn.assignmentProgressList[0].additionalInfo.stateEntersPublicData
                        )
                    );
                    dispatch(setExistingAdditionalInfo(stateMetricsPawn.assignmentProgressList[0].additionalInfo));
                }

                /*
                 *  STATE - State imports data
                 */
                if (Object.keys(stateAdditionalInfo).includes('importData')) {
                    dispatch(
                        setImportData({
                            statePublic: {
                                importStateData: stateAdditionalInfo.importData.publicSchool.importStateData,
                                importDistrictData: stateAdditionalInfo.importData.publicSchool.importDistrictData,
                                importSchoolData: stateAdditionalInfo.importData.publicSchool.importSchoolData,
                            },
                        })
                    );
                }

                if (
                    !Object.keys(stateAdditionalInfo).includes('importData') ||
                    !Object.keys(stateAdditionalInfo).includes('stateEntersPublicData')
                ) {
                    _setAdditionalInfo(stateMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                        stateEntersPublicData: getState().app.nasn.stateEntersData,
                        importData: {
                            publicSchool: getState().app.nasn.importData.statePublic,
                            privateSchool: getState().app.nasn.importData.statePrivate,
                        },
                    });
                }
            } else {
                _setAdditionalInfo(stateMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                    stateEntersPublicData: getState().app.nasn.stateEntersData,
                    importData: {
                        publicSchool: getState().app.nasn.importData.statePublic,
                        privateSchool: getState().app.nasn.importData.statePrivate,
                    },
                });
            }
            dispatch(setAssignmentProgressDataLoaded(true));
        }

        if (groupType === 'statePrivate') {
            const result = await apollo.query({
                query: statePrivateAssignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                },
            });

            const stateMetricsPawn = result.data.groups.state.childRoles.metricspawn;
            const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;
            dispatch(setDeploymentIsOpen(isOpenDeployment));

            if (stateMetricsPawn.assignmentProgressList.length > 0) {
                dispatch(
                    getImportedInfo(
                        stateMetricsPawn.assignmentProgressList[0],
                        stateMetricsPawn.flowProgressList.filter(
                            f => f.responseSet === result.data.flows.deploymentList[0].deploymentId
                        )
                    )
                );
                /*
                 *  STATE Priv - State enters private school data
                 */
                const additionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (Object.keys(additionalInfo).includes('stateEntersPrivateData')) {
                    dispatch(setStateEntersPrivateData(additionalInfo.stateEntersPrivateData));
                    dispatch(setExistingAdditionalInfo(additionalInfo));
                }
                /*
                 *  STATE Priv - State imports private school data
                 */
                if (Object.keys(additionalInfo).includes('importData')) {
                    dispatch(
                        setImportData({
                            statePrivate: {
                                importStateData: additionalInfo.importData.privateSchool.importStateData,
                                importSchoolData: additionalInfo.importData.privateSchool.importSchoolData,
                            },
                        })
                    );
                }

                if (
                    !Object.keys(additionalInfo).includes('importData') ||
                    !Object.keys(additionalInfo).includes('stateEntersPrivateData')
                ) {
                    _setAdditionalInfo(stateMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                        stateEntersPrivateData: getState().app.nasn.stateEntersPrivateData,
                        importData: {
                            publicSchool: getState().app.nasn.importData.statePublic,
                            privateSchool: getState().app.nasn.importData.statePrivate,
                        },
                    });
                }
            } else {
                _setAdditionalInfo(stateMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                    stateEntersPrivateData: getState().app.nasn.stateEntersPrivateData,
                    importData: {
                        publicSchool: getState().app.nasn.importData.statePublic,
                        privateSchool: getState().app.nasn.importData.statePrivate,
                    },
                });
            }
            dispatch(setAssignmentProgressDataLoaded(true));
        }
        if (groupType === 'privateSchool') {
            const result = await apollo.query({
                query: privateSchoolAssignmentProgressQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHandle,
                    stateDeploymentHandle,
                },
            });

            const schoolMetricsPawn = result.data.groups.privateSchool.childRoles.metricspawn;
            const stateMetricsPawn = result.data.groups.privateSchool.ancestorGroups.state.childRoles.metricspawn;
            dispatch(setMetricsPawn(schoolMetricsPawn.pawn));

            const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;
            dispatch(setDeploymentIsOpen(isOpenDeployment));

            /*
             *  PRIVATE SCHOOL - Import Data
             */
            if (schoolMetricsPawn.assignmentProgressList.length > 0) {
                dispatch(
                    getImportedInfo(
                        schoolMetricsPawn.assignmentProgressList[0],
                        schoolMetricsPawn.flowProgressList.filter(
                            f => f.responseSet === result.data.flows.deploymentList[0].deploymentId
                        )
                    )
                );
                const additionalInfo = schoolMetricsPawn.assignmentProgressList[0].additionalInfo;
                const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (
                    typeof additionalInfo === 'object' &&
                    Object.keys(additionalInfo).includes('privateSchoolImportData')
                ) {
                    dispatch(setImportData({ privateSchool: additionalInfo.privateSchoolImportData }));
                }

                if (
                    typeof stateAdditionalInfo === 'object' &&
                    Object.keys(stateAdditionalInfo).includes('stateEntersPrivateData')
                ) {
                    dispatch(
                        setImportData({
                            statePrivate: {
                                importSchoolData: stateAdditionalInfo.stateEntersPrivateData,
                            },
                        })
                    );
                }
            } else {
                _setAdditionalInfo(schoolMetricsPawn.pawn, result.data.flows.deploymentList[0].deploymentId, {
                    privateSchoolImportData: getState().app.nasn.importData.privateSchool,
                });
            }

            /*
             *  PRIVATE SCHOOL - State enters data
             */
            if (stateMetricsPawn.assignmentProgressList.length > 0) {
                const stateAdditionalInfo = stateMetricsPawn.assignmentProgressList[0].additionalInfo;
                if (typeof stateAdditionalInfo === 'object') {
                    if (Object.keys(stateAdditionalInfo).includes('stateEntersPrivateData')) {
                        dispatch(setStateEntersPrivateData(stateAdditionalInfo.stateEntersPrivateData));
                    } else {
                        dispatch(setStateEntersPrivateData(false));
                    }

                    if (Object.keys(stateAdditionalInfo).includes('importData')) {
                        dispatch(
                            setImportData({
                                statePrivate: stateAdditionalInfo.importData.privateSchool,
                            })
                        );
                    }
                }
            }
            dispatch(setAssignmentProgressDataLoaded(true));
        }
    };
};

const _setAdditionalInfo = async (pawn, dId, additionalInfo) => {
    return async (dispatch, getState) => {
        // console.log('setAdditionalInfo', pawn, dId, additionalInfo);
        await axios.post(Boilerplate.route('api.assignmentProgress.update'), {
            deploymentId: dId,
            pawnId: pawn.pawnId,
            flowlist: 'default',
            additional_info: additionalInfo,
        });
    };
};

// Reducers
export default handleActions(
    {
        [SET_USER_GROUPS]: (state, action) => ({
            ...state,
            userGroups: action.payload,
        }),
        [SET_METRICSPAWN]: (state, action) => ({
            ...state,
            metricspawn: action.payload,
        }),
        [SET_DEPLOYMENTS]: (state, action) => ({
            ...state,
            deployments: action.payload,
        }),
        [SET_DEPLOYMENTS_INFO]: (state, action) => ({
            ...state,
            deploymentsInfo: action.payload,
        }),
        [SET_SELECTED_DEPLOYMENT]: (state, action) => ({
            ...state,
            selectedDeployment: action.payload,
        }),
        [SET_DISTRICT_ENTERS_DATA]: (state, action) => ({
            ...state,
            districtEntersData: action.payload,
        }),
        [SET_STATE_ENTERS_DATA]: (state, action) => ({
            ...state,
            stateEntersData: action.payload,
        }),
        [SET_DEPLOYMENT_IS_OPEN]: (state, action) => ({
            ...state,
            deploymentIsOpen: action.payload,
        }),
        [SET_DEPLOYMENT_ID]: (state, action) => ({
            ...state,
            deploymentId: action.payload,
        }),
        [SET_EXISTING_ADDITIONAL_INFO]: (state, action) => ({
            ...state,
            existingAdditionalInfo: action.payload,
        }),
        [SET_STATE_ENTERS_PRIVATE_DATA]: (state, action) => ({
            ...state,
            stateEntersPrivateData: action.payload,
        }),
        [SET_REPORT_DATA_LOADING]: (state, action) => ({
            ...state,
            reportDataLoading: action.payload,
        }),
        [SET_REPORT_METRICS_IDS]: (state, action) => ({
            ...state,
            reportMetricsIds: action.payload,
        }),
        [SET_REPORT_METRICS_IDS_LOADED]: (state, action) => ({
            ...state,
            reportMetricsIdsLoaded: action.payload,
        }),
        [SET_SCHOOLS_ENTERING_DATA]: (state, action) => ({
            ...state,
            schoolsEnteringData: action.payload,
        }),
        [SET_DISTRICTS_ENTERING_DATA]: (state, action) => ({
            ...state,
            districtsEnteringData: action.payload,
        }),
        [SET_ASSIGNMENT_PROGRESS_DATA_LOADED]: (state, action) => ({
            ...state,
            assignmentProgressDataLoaded: action.payload,
        }),
        [SET_DEPLOYMENT_DATA_LOADED]: (state, action) => ({
            ...state,
            deploymentDataLoaded: action.payload,
        }),
        [SET_COMPARISON_DATA]: (state, action) => ({
            ...state,
            comparisonData: action.payload,
        }),
        [SET_COMPARISON_DATA_LOADED]: (state, action) => ({
            ...state,
            comparisonDataLoaded: action.payload,
        }),
        [SET_NATIONAL_DATA]: (state, action) => ({
            ...state,
            nationalData: action.payload,
        }),
        [SET_NATIONAL_DATA_LOADED]: (state, action) => ({
            ...state,
            nationalDataLoaded: action.payload,
        }),
        [SET_BULK_DEF_DATA]: (state, action) => ({
            ...state,
            bulkDefData: { ...state.bulkDefData, ...action.payload },
        }),
        [SET_IMPORT_DATA]: (state, action) => {
            // console.log('SET_IMPORT_DATA', state.importData, action.payload);
            return {
                ...state,
                importData: { ...state.importData, ...action.payload },
            };
        },
        [SET_IMPORTED_INFO]: (state, action) => {
            return {
                ...state,
                importedInfo: action.payload,
            };
        },
        [SET_DISTRICTS_HAVE_DATA]: (state, action) => ({
            ...state,
            districtsHaveData: action.payload,
        }),
        [SET_SCHOOLS_HAVE_DATA]: (state, action) => ({
            ...state,
            schoolsHaveData: action.payload,
        }),
        [SET_REPORT_GROUP_TYPE]: (state, action) => ({
            ...state,
            reportGroupType: action.payload,
        }),
    },
    initialState
);
