/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import style from './style.css';
import _round from 'lodash/round';
import _isPlainObject from 'lodash/isPlainObject';

const Table = props => {
    const data = Object.keys(props.data).length > 0 ? props.data : props.fakeData;

    const comparisonGroupTitle1 = props.groupLabels[0];
    const comparisonGroupTitle2 = props.groupLabels[1];

    const { currentGroup } = data;
    const comparisonGroupKeys = Object.keys(data).filter(x => x !== 'currentGroup');
    const comparisonGroup1 = data[comparisonGroupKeys[0]];
    const comparisonGroup2 = data[comparisonGroupKeys[1]];

    const currentGroupHasData =
        _isPlainObject(currentGroup) && Object.keys(currentGroup).length > 0;
    const comparisonGroup1ExistsWithData =
        _isPlainObject(comparisonGroup1) && Object.keys(comparisonGroup1).length > 0;
    const comparisonGroup2ExistsWithData =
        _isPlainObject(comparisonGroup2) && Object.keys(comparisonGroup2).length > 0;

    if (!currentGroupHasData) {
        return (
            <div className="alert alert-info">
                Could not find data for your group for this time period.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ tableLayout: 'fixed', width: '100%' }}>
            <colgroup>
                <col style={{ width: '60px' }} />
                <col style={{ width: '60px' }} />
                {comparisonGroup1ExistsWithData && <col style={{ width: '60px' }} />}
                {comparisonGroup2ExistsWithData && <col style={{ width: '60px' }} />}
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading}>Domain/Factor</th>
                    <th className={style.tgHeading}>{currentGroup.State_Name || 'Self'}</th>
                    {comparisonGroup1ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle1}</th>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <th className={style.tgHeading}>{comparisonGroupTitle2}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Student Population</td>
                    <td className={style.tgCell}>
                        {currentGroup.Student_Population.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Student_Population.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Student_Population.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Chronically Absent Students (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        {_round(currentGroup.Chronically_Absent_Students_Per_1000, 2)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup1.Chronically_Absent_Students_Per_1000, 2)}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(comparisonGroup2.Chronically_Absent_Students_Per_1000, 2)}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Students with Chronic Health Conditions (per 1000)
                    </td>
                    <td className={style.tgCell}>
                        {_round(currentGroup.Total_Students_Chronic_Health_Condition_Per_1000, 2)}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(
                                comparisonGroup1.Total_Students_Chronic_Health_Condition_Per_1000,
                                2
                            )}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {_round(
                                comparisonGroup2.Total_Students_Chronic_Health_Condition_Per_1000,
                                2
                            )}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Free and Reduced Lunch</td>
                    <td className={style.tgCell}>
                        <div>
                            {currentGroup.PER_FREELUNCH &&
                                _round(currentGroup.PER_FREELUNCH, 2) + '%'}
                        </div>
                        <div style={{ textTransform: 'capitalize' }}>
                            {currentGroup.Poverty_Category &&
                                currentGroup.Poverty_Category + ' Poverty'}
                        </div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {comparisonGroup1.PER_FREELUNCH &&
                                    _round(comparisonGroup1.PER_FREELUNCH, 2) + '%'}
                            </div>
                            <div style={{ textTransform: 'capitalize' }}>
                                {comparisonGroup1.Poverty_Category &&
                                    comparisonGroup1.Poverty_Category + ' Poverty'}
                            </div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <div>
                                {comparisonGroup2.PER_FREELUNCH &&
                                    _round(comparisonGroup2.PER_FREELUNCH, 2) + '%'}
                            </div>
                            <div style={{ textTransform: 'capitalize' }}>
                                {comparisonGroup2.Poverty_Category &&
                                    comparisonGroup2.Poverty_Category + ' Poverty'}
                            </div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>English Learners</td>
                    <td className={style.tgCell}>
                        <div>
                            {currentGroup.PER_NONENG && _round(currentGroup.PER_NONENG, 2) + '%'}
                        </div>
                        <div style={{ textTransform: 'capitalize' }}>
                            {currentGroup.English_Language_Proficiency}
                        </div>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {' '}
                            <div>
                                {comparisonGroup1.PER_NONENG &&
                                    _round(comparisonGroup1.PER_NONENG, 2) + '%'}
                            </div>
                            <div style={{ textTransform: 'capitalize' }}>
                                {comparisonGroup1.English_Language_Proficiency}
                            </div>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {' '}
                            <div>
                                {comparisonGroup2.PER_NONENG &&
                                    _round(comparisonGroup2.PER_NONENG, 2) + '%'}
                            </div>
                            <div style={{ textTransform: 'capitalize' }}>
                                {comparisonGroup2.English_Language_Proficiency}
                            </div>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        TOTAL NURSING FTE
                        <ul>
                            <li>RN</li>
                            <li>LPN/LVN</li>
                            <li>Health Aide</li>
                        </ul>
                    </td>
                    <td className={style.tgCell}>
                        <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                            <li style={{ fontWeight: 'bold' }}>
                                {currentGroup.Total_Nursing_FTE &&
                                    currentGroup.Total_Nursing_FTE.toLocaleString() + ' total'}
                            </li>
                            <li>
                                {currentGroup.RN_Total_FTE &&
                                    currentGroup.RN_Total_FTE.toLocaleString() + ' RN'}
                            </li>
                            <li>
                                {currentGroup.LPN_LVN_Total_FTE &&
                                    currentGroup.LPN_LVN_Total_FTE.toLocaleString() + ' LPN/LVN'}
                            </li>
                            <li>
                                {currentGroup.Health_Aide_Total_FTE &&
                                    currentGroup.Health_Aide_Total_FTE.toLocaleString() + ' Aide'}
                            </li>
                        </ul>
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup1.Total_Nursing_FTE &&
                                        comparisonGroup1.Total_Nursing_FTE.toLocaleString() +
                                            ' total'}
                                </li>
                                <li>
                                    {comparisonGroup1.RN_Total_FTE &&
                                        comparisonGroup1.RN_Total_FTE.toLocaleString() + ' RN'}
                                </li>
                                <li>
                                    {comparisonGroup1.LPN_LVN_Total_FTE &&
                                        comparisonGroup1.LPN_LVN_Total_FTE.toLocaleString() +
                                            ' LPN/LVN'}
                                </li>
                                <li>
                                    {comparisonGroup1.Health_Aide_Total_FTE &&
                                        comparisonGroup1.Health_Aide_Total_FTE.toLocaleString() +
                                            ' Aide'}
                                </li>
                            </ul>
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                                <li style={{ fontWeight: 'bold' }}>
                                    {comparisonGroup2.Total_Nursing_FTE &&
                                        comparisonGroup2.Total_Nursing_FTE.toLocaleString() +
                                            ' total'}
                                </li>
                                <li>
                                    {comparisonGroup2.RN_Total_FTE &&
                                        comparisonGroup2.RN_Total_FTE.toLocaleString() + ' RN'}
                                </li>
                                <li>
                                    {comparisonGroup2.LPN_LVN_Total_FTE &&
                                        comparisonGroup2.LPN_LVN_Total_FTE.toLocaleString() +
                                            ' LPN/LVN'}
                                </li>
                                <li>
                                    {comparisonGroup2.Health_Aide_Total_FTE &&
                                        comparisonGroup2.Health_Aide_Total_FTE.toLocaleString() +
                                            ' Aide'}
                                </li>
                            </ul>
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        National School Nurse Certified
                    </td>
                    <td className={style.tgCell}>
                        {currentGroup.Nurses_National_School_Nurse_Certification &&
                            currentGroup.Nurses_National_School_Nurse_Certification.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nurses_National_School_Nurse_Certification &&
                                comparisonGroup1.Nurses_National_School_Nurse_Certification.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nurses_National_School_Nurse_Certification &&
                                comparisonGroup2.Nurses_National_School_Nurse_Certification.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        State School Nurse Certified
                    </td>
                    <td className={style.tgCell}>
                        {currentGroup.Nurses_State_School_Nurse_Certification &&
                            currentGroup.Nurses_State_School_Nurse_Certification.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nurses_State_School_Nurse_Certification &&
                                comparisonGroup1.Nurses_State_School_Nurse_Certification.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nurses_State_School_Nurse_Certification &&
                                comparisonGroup2.Nurses_State_School_Nurse_Certification.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Nursing Education: Doctoral Degree
                    </td>
                    <td className={style.tgCell}>
                        {currentGroup.Nursing_Education_Doctoral_Degree &&
                            currentGroup.Nursing_Education_Doctoral_Degree.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nursing_Education_Doctoral_Degree &&
                                comparisonGroup1.Nursing_Education_Doctoral_Degree.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nursing_Education_Doctoral_Degree &&
                                comparisonGroup2.Nursing_Education_Doctoral_Degree.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Nursing Education: Master's Degree
                    </td>
                    <td className={style.tgCell}>
                        {currentGroup.Nursing_Education_Masters_Degree_Plus &&
                            currentGroup.Nursing_Education_Masters_Degree_Plus.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nursing_Education_Masters_Degree_Plus &&
                                comparisonGroup1.Nursing_Education_Masters_Degree_Plus.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nursing_Education_Masters_Degree_Plus &&
                                comparisonGroup2.Nursing_Education_Masters_Degree_Plus.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        Nursing Education: Bachelor's Degree
                    </td>
                    <td className={style.tgCell}>
                        {currentGroup.Nursing_Education_Bachelors_Degree &&
                            currentGroup.Nursing_Education_Bachelors_Degree.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nursing_Education_Bachelors_Degree &&
                                comparisonGroup1.Nursing_Education_Bachelors_Degree.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nursing_Education_Bachelors_Degree &&
                                comparisonGroup2.Nursing_Education_Bachelors_Degree.toLocaleString()}
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>Associate Degree</td>
                    <td className={style.tgCell}>
                        {currentGroup.Nursing_Education_Associates_Degree_Deploma_GED &&
                            currentGroup.Nursing_Education_Associates_Degree_Deploma_GED.toLocaleString()}
                    </td>
                    {comparisonGroup1ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup1.Nursing_Education_Associates_Degree_Deploma_GED &&
                                comparisonGroup1.Nursing_Education_Associates_Degree_Deploma_GED.toLocaleString()}
                        </td>
                    )}
                    {comparisonGroup2ExistsWithData && (
                        <td className={style.tgCell}>
                            {comparisonGroup2.Nursing_Education_Associates_Degree_Deploma_GED &&
                                comparisonGroup2.Nursing_Education_Associates_Degree_Deploma_GED.toLocaleString()}
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default Table;
