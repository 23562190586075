import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

export default class Signup extends React.Component {
    render() {
        const url = this.props.match.params.pathName
            ? Boilerplate.url(`/${this.props.match.params.pathName}`)
            : Boilerplate.url(`/`);
        return (
            <div className={style.formBox}>
                <LoginForm
                    defaultRedirectUrl={url}
                    onSuccess={this.onSuccess}
                    title="Log In"
                    buttonText="Log In"
                />
            </div>
        );
    }
}
