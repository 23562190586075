import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import Header from '../../../components/DataEntryHeader/PublicSchools';
import { getDeployments, setDeploymentIsOpen } from 'js/reducers/nasn.js';
import style from './style.css';
import { client as apollo } from 'cccisd-apollo';
import surveillanceProgressQuery from './surveillance.graphql';

var Fortress = window.cccisd.fortress;

const IndividualESCDataEntry = () => {
    const [isLoading, setIsLoading] = useState(true);

    const reduxStore = useSelector(state => state.app.nasn);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await dispatch(getDeployments('individualESC'));
            setIsLoading(false);
        })();
    }, []);

    const deploymentHandle = reduxStore.selectedDeployment;

    useEffect(() => {
        // Extra check because we don't want this running with deploymentHandle's initial, falsy value
        if (deploymentHandle) {
            // DeploymentPlayer doesn't re-render when deploymentHandle is changed, so we setLoading here so entire page is forced to re-render.
            setIsLoading(true);
            (async () => {
                const result = await apollo.query({
                    query: surveillanceProgressQuery,
                    fetchPolicy: 'network-only',
                    variables: {
                        deploymentHandle,
                    },
                });

                if (!result.data.flows.deploymentList.length > 0) {
                    setIsLoading(false);
                    return;
                }

                const isOpenDeployment = result.data.flows.deploymentList[0].isOpen;
                dispatch(setDeploymentIsOpen(isOpenDeployment));

                setIsLoading(false);
            })();
        }
    }, [deploymentHandle]);

    const render = () => {
        if (reduxStore.deploymentIsOpen === false) {
            return (
                <>
                    <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                    <div className={style.notification}>Data entry for this school year is now closed.</div>
                </>
            );
        }

        return (
            <>
                <Header deploymentHandle={deploymentHandle} options={reduxStore.deployments} />
                <div>
                    <DeploymentPlayer
                        deploymentHandle={deploymentHandle}
                        pawnId={Fortress.user.acting.id}
                        pawnHash={Fortress.user.acting.random_hash}
                        onComplete={() => {
                            setIsLoading(true);
                            setIsLoading(false);
                        }}
                        disableLayout
                        enablePrintMode
                    />
                </div>
            </>
        );
    };

    if (isLoading || !reduxStore.deploymentDataLoaded) {
        return <Loader loading />;
    }

    return <>{render()}</>;
};

IndividualESCDataEntry.propTypes = {
    defaultProp: PropTypes.string,
};

export default IndividualESCDataEntry;
