import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { taskMasterClient, gql } from 'cccisd-apollo';
import _isEqual from 'lodash/isEqual';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import { BrowserOnly } from 'cccisd-laravel-report';

import query from './chronicConditions.graphql';
import ccVariables from './ccVariables.js';
import _round from 'lodash/round';

const Component = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const reduxStore = useSelector(state => state.app.nasn);
    const deploymentHandle = reduxStore.selectedDeployment.slice(0, 9) + `_individualESC`;
    const variables = {
        metricspawns: [window.cccisd.fortress.user.acting.id],
        deploymentHandle,
        ...ccVariables.publicSchool,
    };
    const isFirst = useRef(true);
    const prevDeps = useRef(variables);
    const { reportDataLoading } = reduxStore;

    useEffect(() => {
        const isSame = _isEqual(variables, prevDeps.current);
        if (!reportDataLoading) {
            if (isFirst.current || !isSame) {
                (async () => {
                    setLoading(true);
                    const formattedQuery = gql`
                        ${query}
                    `;
                    const response = await taskMasterClient.query({
                        query: formattedQuery,
                        variables,
                        fetchPolicy: 'network-only',
                    });
                    setData(response.data);
                    setLoading(false);
                })();
            }
            isFirst.current = false;
            prevDeps.current = variables;
        }
    }, [reduxStore.selectedDeployment, reportDataLoading]);

    const getData = () => {
        if (!loading) {
            const check = data.roles;
            if (check) {
                const summary = data.roles.anyRoleSummary;
                const totalStudents = summary.totalstudents;
                const totalAsthma = summary.totalasthma;
                const totalType1 = summary.totaltype1;
                const totalType2 = summary.totaltype2;
                const totalSeizure = summary.totalseizure;
                const totalLta = summary.totallta;
                const totalMecfs = summary.totalmecfs;
                const per1000 = x => _round((x / totalStudents) * 1000, 2);

                if (!totalStudents) {
                    return {
                        asthma: '?',
                        type1: '?',
                        type2: '?',
                        lta: '?',
                        seizure: '?',
                        mecfs: '?',
                    };
                }

                return {
                    asthma: per1000(totalAsthma),
                    type1: per1000(totalType1),
                    type2: per1000(totalType2),
                    lta: per1000(totalLta),
                    seizure: per1000(totalSeizure),
                    mecfs: per1000(totalMecfs),
                };
            }
        }
        return { asthma: '', type1: '', type2: '', lta: '', seizure: '', mecfs: '' };
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Chronic Conditions'}
                        </div>
                        {!loading && !data.roles.anyRoleSummary.totalstudents && (
                            <BrowserOnly>
                                <div
                                    style={{
                                        fontSize: '0.58em',
                                        color: 'red',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    Data for this section cannot be calculated without entering total student
                                    population.
                                </div>
                            </BrowserOnly>
                        )}
                    </div>
                    <div className={style.content}>
                        {loading ? (
                            <Skeleton height={73} />
                        ) : (
                            <div className={style.boxWrapper}>
                                <div className={style.box}>
                                    <div className={`${style.asthma}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Asthma</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().asthma || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.diabetes}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Diabetes</div>
                                        <div>
                                            Type 1: <span style={{ fontWeight: 'bold' }}>{getData().type1 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                        <div>
                                            Type 2: <span style={{ fontWeight: 'bold' }}>{getData().type2 || '?'}</span>{' '}
                                            / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.allergy}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>
                                            Life Threatening
                                            <br /> Allergy
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().lta || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.seizure}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>Seizure Disorder</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().seizure || '?'}</span> /
                                            1000
                                        </div>
                                    </div>
                                </div>
                                <div className={style.box}>
                                    <div className={`${style.mecfs}`} />
                                    <div className={style.boxText}>
                                        <div style={{ fontWeight: 'bold' }}>ME/CFS</div>
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>{getData().mecfs || '?'}</span> / 1000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
