export default {
    currentGroup: {
        State_Name: 'California',
        State_Abbreviation: 'CA',
        Num_Districts: '2170',
        Num_Districts_Reporting: '',
        NCES_Region: 'West',
        DHHS_Region: 'Region 9',
        NCES_Locale_Designation: '',
        Title_1_Eligibility_Num: '0',
        Title_1_Eligibility: '3497',
        Student_Population: '',
        Pupil_Teacher_Ratio: '22.67',
        Full_Time_Equivalent_Teachers: '271805.2',
        PER_FREELUNCH: '',
        Poverty_Category: '',
        PER_NONENG: '',
        English_Language_Proficiency: '',
        Male_Students: '100',
        Female_Students: '100',
        American_Indian_Alaska_Native_Students: '30277',
        Asian_Asian_Pacific_Islander_Students: '721540',
        Hispanic_Students: '3381085',
        Black_African_American_Students: '324479',
        White_Students: '1381627',
        Nat_Hawaiian_Other_Pacific_Is_Students: '27195',
        Two_Or_More_Races_Students: '296506',
        Total_Nursing_FTE: '',
        RN_Total_FTE: '',
        RN_Assigned_Caseload: '',
        RN_Float: '',
        RN_Special_Assignment: '',
        RN_Administrative: '',
        LPN_LVN_Total_FTE: '',
        LPN_LVN_Assigned_Caseload: '',
        LPN_LVN_Float: '',
        LPN_LVN_Special_Assignment: '',
        LPN_LVN_Administrative: '',
        Health_Aide_Total_FTE: '',
        Health_Aide_Assigned_Caseload: '',
        Health_Aide_Float: '',
        Health_Aide_Special_Assignment: '',
        Health_Aide_Administrative: '',
        Nursing_Education_Masters_Degree_Plus: '',
        Nursing_Education_Bachelors_Degree: '',
        Nursing_Education_Associates_Degree_Deploma_GED: '',
        Nurses_National_School_Nurse_Certification: '',
        Nurses_State_School_Nurse_Certification: '',
        Nurses_National_Nurse_Practitioner_Certification: '',
        Chronically_Absent_Students: '',
        Chronically_Absent_Students_Per_1000: '',
        Total_Students_Chronic_Health_Condition: '',
        Total_Students_Asthma: '',
        Total_Students_Type_1_Diabetes: '',
        Total_Students_Type_2_Diabetes: '',
        Total_Students_Seizure_Disorder: '',
        Total_Students_Life_Threatening_Allergy: '',
        Total_Students_ME_CFS: '',
        Total_Students_Chronic_Health_Condition_Per_1000: '',
        Total_Students_Asthma_Per_1000: '',
        Total_Students_Type_1_Diabetes_Per_1000: '',
        Total_Students_Type_2_Diabetes_Per_1000: '',
        Total_Students_Seizure_Disorder_Per_1000: '',
        Total_Students_Life_Threatening_Allergy_Per_1000: '',
        Total_Students_ME_CFS_Per_1000: '',
        MOP_1: '',
        MOP_2: '',
        MOP_3: '',
        MOP_4: '',
        MOP_5: '',
        MOP_6: '',
        MOP_7: '',
        MOP_8: '',
        TOTAL_HEALTH_VISITS: '',
        TOTAL_RN_VISITS: '',
        TOTAL_RN_VISITS_CLASS: '',
        TOTAL_RN_VISITS_HOME: '',
        TOTAL_RN_VISITS_911: '',
        TOTAL_LPN_VISITS: '',
        TOTAL_LPN_VISITS_CLASS: '',
        TOTAL_LPN_VISITS_HOME: '',
        TOTAL_LPN_VISITS_911: '',
        TOTAL_AIDE_VISITS: '',
        TOTAL_AIDE_VISITS_CLASS: '',
        TOTAL_AIDE_VISITS_HOME: '',
        TOTAL_AIDE_VISITS_911: '',
        Proportion_RN_VISITS: '',
        Proportion_RN_CLASS: '',
        Proportion_RN_HOME: '',
        Proportion_RN_911: '',
        Proportion_LPN_VISITS: '',
        Proportion_LPN_CLASS: '',
        Proportion_LPN_HOME: '',
        Proportion_LPN_911: '',
        Proportion_AIDE_VISITS: '',
        Proportion_AIDE_CLASS: '',
        Proportion_AIDE_HOME: '',
        Proportion_AIDE_911: '',
    },
    NCES_Region: {
        Num_Groups_Reporting: 14,
        Student_Population: 0,
        Chronically_Absent_Students: 0,
        Chronically_Absent_Students_Per_1000: 0,
        Total_Students_Chronic_Health_Condition: 0,
        Total_Students_Chronic_Health_Condition_Per_1000: 0,
        PER_FREELUNCH: 0,
        Poverty_Category: 'low',
        PER_NONENG: 0,
        English_Language_Proficiency: 'high',
        Total_Nursing_FTE: 0,
        Full_Time_Equivalent_Teachers: 0,
        RN_Total_FTE: 0,
        RN_Assigned_Caseload: 0,
        RN_Float: 0,
        RN_Special_Assignment: 0,
        RN_Administrative: 0,
        LPN_LVN_Total_FTE: 0,
        LPN_LVN_Assigned_Caseload: 0,
        LPN_LVN_Float: 0,
        LPN_LVN_Special_Assignment: 0,
        LPN_LVN_Administrative: 0,
        Health_Aide_Total_FTE: 0,
        Health_Aide_Assigned_Caseload: 0,
        Health_Aide_Float: 0,
        Health_Aide_Special_Assignment: 0,
        Health_Aide_Administrative: 0,
        Nursing_Education_Masters_Degree_Plus: 0,
        Nursing_Education_Bachelors_Degree: 0,
        Nursing_Education_Associates_Degree_Deploma_GED: 0,
        Nurses_National_School_Nurse_Certification: 0,
        Nurses_State_School_Nurse_Certification: 0,
        Nurses_National_Nurse_Practitioner_Certification: 0,
        MOP_1: 0,
        MOP_2: 0,
        MOP_3: 0,
        MOP_4: 0,
        MOP_5: 0,
        MOP_6: 0,
        MOP_7: 0,
        MOP_8: 0,
        Total_Students_Asthma: 0,
        Total_Students_Type_1_Diabetes: 0,
        Total_Students_Type_2_Diabetes: 0,
        Total_Students_Seizure_Disorder: 0,
        Total_Students_Life_Threatening_Allergy: 0,
        Total_Students_ME_CFS: 0,
        Total_Students_Asthma_Per_1000: 0,
        Total_Students_Type_1_Diabetes_Per_1000: 0,
        Total_Students_Type_2_Diabetes_Per_1000: 0,
        Total_Students_Seizure_Disorder_Per_1000: 0,
        Total_Students_Life_Threatening_Allergy_Per_1000: 0,
        Total_Students_ME_CFS_Per_1000: 0,
        TOTAL_HEALTH_VISITS: 0,
        TOTAL_RN_VISITS: 0,
        TOTAL_RN_VISITS_CLASS: 0,
        TOTAL_RN_VISITS_HOME: 0,
        TOTAL_RN_VISITS_911: 0,
        TOTAL_LPN_VISITS: 0,
        TOTAL_LPN_VISITS_CLASS: 0,
        TOTAL_LPN_VISITS_HOME: 0,
        TOTAL_LPN_VISITS_911: 0,
        TOTAL_AIDE_VISITS: 0,
        TOTAL_AIDE_VISITS_CLASS: 0,
        TOTAL_AIDE_VISITS_HOME: 0,
        TOTAL_AIDE_VISITS_911: 0,
        Proportion_RN_VISITS: 0,
        Proportion_RN_CLASS: 0,
        Proportion_RN_HOME: 0,
        Proportion_RN_911: 0,
        Proportion_LPN_VISITS: 0,
        Proportion_LPN_CLASS: 0,
        Proportion_LPN_HOME: 0,
        Proportion_LPN_911: 0,
        Proportion_AIDE_VISITS: 0,
        Proportion_AIDE_CLASS: 0,
        Proportion_AIDE_HOME: 0,
        Proportion_AIDE_911: 0,
    },
    DHHS_Region: {
        Num_Groups_Reporting: 5,
        Student_Population: 0,
        Chronically_Absent_Students: 0,
        Chronically_Absent_Students_Per_1000: 0,
        Total_Students_Chronic_Health_Condition: 0,
        Total_Students_Chronic_Health_Condition_Per_1000: 0,
        PER_FREELUNCH: 0,
        Poverty_Category: 'low',
        PER_NONENG: 0,
        English_Language_Proficiency: 'high',
        Total_Nursing_FTE: 0,
        Full_Time_Equivalent_Teachers: 0,
        RN_Total_FTE: 0,
        RN_Assigned_Caseload: 0,
        RN_Float: 0,
        RN_Special_Assignment: 0,
        RN_Administrative: 0,
        LPN_LVN_Total_FTE: 0,
        LPN_LVN_Assigned_Caseload: 0,
        LPN_LVN_Float: 0,
        LPN_LVN_Special_Assignment: 0,
        LPN_LVN_Administrative: 0,
        Health_Aide_Total_FTE: 0,
        Health_Aide_Assigned_Caseload: 0,
        Health_Aide_Float: 0,
        Health_Aide_Special_Assignment: 0,
        Health_Aide_Administrative: 0,
        Nursing_Education_Masters_Degree_Plus: 0,
        Nursing_Education_Bachelors_Degree: 0,
        Nursing_Education_Associates_Degree_Deploma_GED: 0,
        Nurses_National_School_Nurse_Certification: 0,
        Nurses_State_School_Nurse_Certification: 0,
        Nurses_National_Nurse_Practitioner_Certification: 0,
        MOP_1: 0,
        MOP_2: 0,
        MOP_3: 0,
        MOP_4: 0,
        MOP_5: 0,
        MOP_6: 0,
        MOP_7: 0,
        MOP_8: 0,
        Total_Students_Asthma: 0,
        Total_Students_Type_1_Diabetes: 0,
        Total_Students_Type_2_Diabetes: 0,
        Total_Students_Seizure_Disorder: 0,
        Total_Students_Life_Threatening_Allergy: 0,
        Total_Students_ME_CFS: 0,
        Total_Students_Asthma_Per_1000: 0,
        Total_Students_Type_1_Diabetes_Per_1000: 0,
        Total_Students_Type_2_Diabetes_Per_1000: 0,
        Total_Students_Seizure_Disorder_Per_1000: 0,
        Total_Students_Life_Threatening_Allergy_Per_1000: 0,
        Total_Students_ME_CFS_Per_1000: 0,
        TOTAL_HEALTH_VISITS: 0,
        TOTAL_RN_VISITS: 0,
        TOTAL_RN_VISITS_CLASS: 0,
        TOTAL_RN_VISITS_HOME: 0,
        TOTAL_RN_VISITS_911: 0,
        TOTAL_LPN_VISITS: 0,
        TOTAL_LPN_VISITS_CLASS: 0,
        TOTAL_LPN_VISITS_HOME: 0,
        TOTAL_LPN_VISITS_911: 0,
        TOTAL_AIDE_VISITS: 0,
        TOTAL_AIDE_VISITS_CLASS: 0,
        TOTAL_AIDE_VISITS_HOME: 0,
        TOTAL_AIDE_VISITS_911: 0,
        Proportion_RN_VISITS: 0,
        Proportion_RN_CLASS: 0,
        Proportion_RN_HOME: 0,
        Proportion_RN_911: 0,
        Proportion_LPN_VISITS: 0,
        Proportion_LPN_CLASS: 0,
        Proportion_LPN_HOME: 0,
        Proportion_LPN_911: 0,
        Proportion_AIDE_VISITS: 0,
        Proportion_AIDE_CLASS: 0,
        Proportion_AIDE_HOME: 0,
        Proportion_AIDE_911: 0,
    },
};
