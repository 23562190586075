import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { Report, Page } from 'cccisd-laravel-report';
import ReportHeader from './ReportHeader';
import Workforce from './widgets/workforce';
import ModelsOfPractice from './widgets/modelsOfPractice';
import ChronicConditions from './widgets/chronicConditions';
import HealthOfficeVisits from './widgets/healthOfficeVisits';
import groupLabelsQuery from './groupLabels.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;
const userGroup = Fortress.user.acting.group;

const GroupComparisonReport = props => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [groupLabels, setGroupLabels] = useState([]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const url = Boilerplate.route('app.comparison.report.job');
            let result = await axios.post(url, {
                groupId: userGroup ? userGroup.id : 3,
                schoolYear: props.schoolYear.slice(0, 9) || '2021-2022',
                comparisonType1Field: 'group',
                comparisonType1Value: props.groupsToCompare[0],
                comparisonType2Field: 'group',
                comparisonType2Value: props.groupsToCompare[1] ? props.groupsToCompare[1] : null,
            });

            setData(result.data);

            const groupLabelsResult = await apollo.query({
                query: groupLabelsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    groupIds: props.groupsToCompare,
                },
            });
            const labelsList = groupLabelsResult.data.groups.anyGroupList.map(g => g.group.label);
            setGroupLabels(labelsList);
            setLoading(false);
            return result;
        })();
    }, []);

    return (
        <Report>
            <Page>
                <div style={{ marginBottom: '2em' }}>
                    <ReportHeader schoolYear={props.schoolYear} />
                </div>
                <Workforce loading={loading} data={data} groupLabels={groupLabels} />
            </Page>
            <Page>
                <div style={{ marginBottom: '2em' }}>
                    <ReportHeader schoolYear={props.schoolYear} />
                </div>
                <ModelsOfPractice loading={loading} data={data} groupLabels={groupLabels} />
            </Page>
            <Page>
                <div style={{ marginBottom: '2em' }}>
                    <ReportHeader schoolYear={props.schoolYear} />
                </div>
                <ChronicConditions loading={loading} data={data} groupLabels={groupLabels} />
            </Page>
            <Page>
                <div style={{ marginBottom: '2em' }}>
                    <ReportHeader schoolYear={props.schoolYear} />
                </div>
                <HealthOfficeVisits loading={loading} data={data} groupLabels={groupLabels} />
            </Page>
        </Report>
    );
};

GroupComparisonReport.propTypes = {
    schoolYear: PropTypes.string,
    groupsToCompare: PropTypes.array,
};

export default GroupComparisonReport;
