import React from 'react';
import style from './style.css';

const HorizontalBar = props => {
    const maxScore = 100;
    const { chartData } = props;
    return (
        <div className={style.wrapper}>
            <div className={style.yAxisLabel}>{props.yAxisLabel || 'RN'}</div>
            <div className={style.chart}>
                {chartData.data.map((data, index) => {
                    return (
                        <div
                            className={style.barBg}
                            key={data.title}
                            style={
                                index === chartData.data.length - 1 ? {} : { marginBottom: '0.2em' }
                            }
                        >
                            <div
                                className={style.score}
                                style={
                                    data.color
                                        ? {
                                              background: data.color,
                                              width: `${(data.score / maxScore) * 100}%`,
                                          }
                                        : {
                                              width: `${(data.score / maxScore) * 100}%`,
                                          }
                                }
                            />
                            <span
                                className={style.scoreLabel}
                                style={
                                    (data.score / maxScore) * 100 >= 80
                                        ? { textAlign: 'end', marginRight: '0.5em' }
                                        : { marginLeft: `${(data.score / maxScore) * 100 + 1}%` }
                                }
                            >
                                {Number.isFinite(data.score) ? data.score.toFixed(0) : ''}
                                {chartData.scoreSuffix && Number.isFinite(data.score)
                                    ? chartData.scoreSuffix
                                    : ''}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HorizontalBar;
