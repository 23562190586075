import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import filterFields from '../filterFields.js';
import widgets from '../widgets.js';
import { useIsFetching } from 'cccisd-react-query';

const Component = props => {
    const { workforce, chronicConditions, absenteeism, healthOfficeVisits } = props.widgets;
    const reduxStore = useSelector(state => state.app.nasn);
    const isFetching = useIsFetching();

    return (
        <Report
            downloadFilename={
                props.tabSelected === 'overview' ? 'ESC_Public_School_Report' : 'ESC_Private_School_Report'
            }
            disableDownload={!!isFetching}
            disableDownloadMessage="Loading"
        >
            <Page>
                <ReportHeader
                    settings={props.settings}
                    filters={props.filters}
                    group={props.group}
                    reportGroupType={props.reportGroupType}
                    schoolsEnteringData={reduxStore.schoolsEnteringData}
                    districtsEnteringData={reduxStore.districtsEnteringData}
                    tabSelected={props.tabSelected}
                    overviewType={props.overviewType}
                />
                {workforce}
                {chronicConditions}
                {absenteeism}
                {healthOfficeVisits}
                <ReportFooter />
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    options: PropTypes.array, // Deployment handles for school year filter
    reportGroupType: PropTypes.string,
    group: PropTypes.object,
    schoolYear: PropTypes.string,
    childReportOpen: PropTypes.bool,
    tabSelected: PropTypes.string,
    overviewType: PropTypes.string,
};

Component.defaultProps = {
    tabSelected: 'overview',
};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: {
                reportGroupType: props.thisGroupReport ? props.group.groupType : props.reportGroupType,
                group: props.group,
                thisGroupReport: props.thisGroupReport,
                tabSelected: props.tabSelected,
            },
        })),
    getFilterFields: props => {
        return filterFields.map(field => {
            if (field.name === 'schoolYear') {
                return {
                    ...field,
                    options: props.options,
                    initialValue: props.options[props.options.length - 1]
                        ? props.options[props.options.length - 1].value
                        : '',
                    hidden: props.thisGroupReport,
                    tabSelected: props.tabSelected,
                    thisGroupReport: props.thisGroupReport,
                };
            }
            return field;
        });
    },
})(Component);
