import React from 'react';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import style from './style.css';
import _get from 'lodash/get';
import moment from 'moment';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class ExportData extends React.Component {
    static propTypes = {};

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        await this.getCsvFiles();

        await this.setState({ loading: false });
    };

    getCsvFiles = async () => {
        const result = await axios.get(Boilerplate.route('api.resources.file.privateIndex'));
        const csvfiles = _get(result.data, 'data');
        let stateCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_state_data.csv');
        });

        let districtCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_district_data.csv');
        });

        let publicSchoolCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_publicSchool_data.csv');
        });

        let privateSchoolCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_privateSchool_data.csv');
        });

        privateSchoolCSVs.sort((a, b) => {
            return new Date(a.updated_at) - new Date(b.updated_at);
        });

        let statePrivateCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_statePrivate_data.csv');
        });

        statePrivateCSVs.sort((a, b) => {
            return new Date(a.updated_at) - new Date(b.updated_at);
        });

        let nationalCSVs = csvfiles.filter(csv => {
            return csv.filename.includes('_national_data.csv');
        });

        await this.setState({
            stateCSVs,
            districtCSVs,
            publicSchoolCSVs,
            privateSchoolCSVs,
            statePrivateCSVs,
            nationalCSVs,
        });
    };

    showCsvButton = file => {
        return (
            <a href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)} rel="noopener noreferrer">
                <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                    {file.filename}
                </button>
            </a>
        );
    };

    render() {
        if (this.state.loading) {
            return <Loader loading={this.state.loading} />;
        }

        const { stateCSVs, districtCSVs, publicSchoolCSVs, privateSchoolCSVs, statePrivateCSVs, nationalCSVs } =
            this.state;

        return (
            <div className="container">
                <h1>Data Sets</h1>

                <div className={style.block}>
                    <div className={style.blockLeft}>
                        <div className={style.state} />
                    </div>

                    <div className={style.district} />
                    <div className={style.school} />
                </div>
                <div className={style.row}>
                    <div className={style.column}>
                        <h4>State</h4>
                        <div className={style.exportButtons}>
                            {stateCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>

                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>District</h4>
                        <div className={style.exportButtons}>
                            {districtCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>
                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Public School</h4>
                        <div className={style.exportButtons}>
                            {publicSchoolCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>
                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>State (Private)</h4>
                        <div className={style.exportButtons}>
                            {statePrivateCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>
                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Private School</h4>
                        <div className={style.exportButtons}>
                            {privateSchoolCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>
                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>National</h4>
                        <div className={style.exportButtons}>
                            {nationalCSVs.map(file => (
                                <div>
                                    <a
                                        href={Boilerplate.url(`api/resources/file/private/nasn/${file.filename}`)}
                                        rel="noopener noreferrer"
                                    >
                                        <button type="button" className={`btn btn-primary btn-md ${style.csvButton}`}>
                                            {file.filename.slice(0, 9)}
                                        </button>
                                    </a>
                                    <h5>
                                        <em>
                                            <>
                                                Last updated:{' '}
                                                {moment
                                                    .utc(file.updated_at)
                                                    .tz('America/New_York')
                                                    .format('MM/DD/YYYY HH:mm')}
                                            </>
                                        </em>
                                    </h5>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
