export default {
    nationalPublic: 'National Public Schools',
    nationalPrivate: 'National Private Schools',
    nationalAll: 'National All Schools',
    esc1: 'ESC Tier 1',
    esc2: 'ESC Tier 2',
    esc3: 'ESC Tier 3',
    ncesMidwest: 'NCES Midwest',
    ncesNortheast: 'NCES Northeast',
    ncesSouth: 'NCES South',
    ncesWest: 'NCES West',
    dhhs1: 'DHHS Region 1',
    dhhs2: 'DHHS Region 2',
    dhhs3: 'DHHS Region 3',
    dhhs4: 'DHHS Region 4',
    dhhs5: 'DHHS Region 5',
    dhhs6: 'DHHS Region 6',
    dhhs7: 'DHHS Region 7',
    dhhs8: 'DHHS Region 8',
    dhhs9: 'DHHS Region 9',
    popHigh: 'High Population',
    popMidHigh: 'Mid-High Population',
    popMidLow: 'Mid-Low Population',
    popLow: 'Low Population',
    povertyHigh: 'High Poverty',
    povertyMidHigh: 'Mid-High Poverty',
    povertyMidLow: 'Mid-Low Poverty',
    povertyLow: 'Low Poverty',
    ellHigh: 'High ELL',
    ellMidHigh: 'Mid-High ELL',
    ellMidLow: 'Mid-Low ELL',
    ellLow: 'Low ELL',
    cityLocale: 'City Locale',
    suburbLocale: 'Suburb Locale',
    townLocale: 'Town Locale',
    ruralLocale: 'Rural Locale',
    myDistrict: 'My District',
    myState: 'My State',
};
