import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import publicSchoolsQuery from './publicSchoolsQuery.graphql';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';

import PublicSchoolReport from './PublicSchoolReport.js';
import IconReport from 'cccisd-icons/file-presentation';

const Fortress = window.cccisd.fortress;

const PublicSchoolOverviewTable = props => {
    const userGroup = Fortress.user.acting.group?.label;
    const schoolDeploymentHandle = props.deploymentHandle.slice(0, 9) + '_publicSchool';
    const renderButton = status => {
        if (!status) {
            return (
                <Tooltip title="This school has not entered data for this year.">
                    <button type="button" className="btn btn-xs btn-primary" disabled>
                        <IconReport spaceRight />
                        View Report
                    </button>
                </Tooltip>
            );
        }

        return (
            <button type="button" className="btn btn-xs btn-primary">
                <IconReport spaceRight />
                View Report
            </button>
        );
    };

    const columns = [
        { name: 'group.label', label: 'School', sort: true, filter: true },
        {
            name: 'childRoles.metricspawn.assignmentProgress.status',
            label: 'Data Entry Status',
            hideInTable: true,
            render: ({ value, row }) => (value === 'Not Started' ? 'Not Started/Imported' : value),
        },
        {
            name: 'childRoles.metricspawn.assignmentProgress.status',
            label: 'Overview Report',
            sort: true,
            className: 'text-center',
            totalPath: 'publicSchoolSummary.totalStarted',
            hideInCsv: true,
            render: ({ value, row }) => {
                if (Object.keys(row).length === 0) {
                    return value;
                }
                return (
                    <>
                        <Modal
                            trigger={renderButton(row['childRoles.metricspawn.assignmentProgress.status'])}
                            size="large"
                            title={row['group.label']}
                        >
                            <PublicSchoolReport
                                row={row}
                                reportSettings={props.reportSettings}
                                metricspawns={[row['childRoles.metricspawn.pawn.pawnId']]}
                            />
                        </Modal>
                    </>
                );
            },
        },
        {
            name: 'parentGroup.district.group.label',
            label: 'School District',
            sort: true,
            filter: true,
        },
    ];

    return (
        <Table
            rowKey="group.groupId"
            graphqlVariables={{ deploymentHandle: schoolDeploymentHandle }}
            query={publicSchoolsQuery}
            columns={columns}
            perPage={10}
            showTotal
            totalHeader={<div>Total schools that have started or completed data entry:</div>}
            useTaskMaster
            csvFilename={`${userGroup}_public_schools.csv`}
        />
    );
};

PublicSchoolOverviewTable.propTypes = {
    deploymentIsOpen: PropTypes.bool,
    deploymentHandle: PropTypes.string,
    setChildReportOpen: PropTypes.func,
    childReportOpen: PropTypes.bool,
    reportSettings: PropTypes.object,
};

export default PublicSchoolOverviewTable;
