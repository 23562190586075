import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Html } from 'cccisd-wysiwyg';
import ChartLegend from '../../ChartLegend';
import PieChart from './PieChart';
import IconChart from 'cccisd-icons/pie-chart3';
import style from './style.css';

import publicSchoolQuery from './publicSchoolHov.graphql';
import privateSchoolQuery from './privateSchoolHov.graphql';
import districtQuery from './districtHov.graphql';
import stateQuery from './stateHov.graphql';

import hovVariables from './hovVariables.js';

import _every from 'lodash/every';
import _round from 'lodash/round';
import { useGetOverviewReportData } from 'js/queries';

const Component = props => {
    const reduxStore = useSelector(state => state.app.nasn);
    const deploymentHandle = reduxStore.selectedDeployment.slice(0, 9) + `_${props.widgetProps.reportGroupType}`;

    const getQueryType = () => {
        let query = stateQuery;
        let variables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_state',
            workforce: hovVariables.state,
        };
        const districtVariables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_district',
            workforce: hovVariables.district,
        };
        const schoolVariables = {
            metricspawns: props.widgetProps.metricspawns,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_publicSchool',
            workforce: hovVariables.publicSchool,
        };

        const privateSchoolVariables = {
            metricspawns: props.widgetProps.metricspawns,
            workforce: hovVariables.privateSchool,
            deploymentHandle: deploymentHandle.slice(0, 9) + '_privateSchool',
        };

        if (props.widgetProps.reportGroupType === 'district') {
            query = districtQuery;
            variables = districtVariables;
        }
        if (props.widgetProps.reportGroupType === 'publicSchool') {
            query = publicSchoolQuery;
            variables = schoolVariables;
        }
        if (props.widgetProps.reportGroupType === 'privateSchool') {
            query = privateSchoolQuery;
            variables = privateSchoolVariables;
        }

        return [{ query, variables }];
    };

    const overviewReportQuery = useGetOverviewReportData(
        'hov singleGroup',
        props.widgetProps.reportGroupType,
        getQueryType()
    );

    const queriesLoading = overviewReportQuery.some(q => q.isFetching);

    const getData = () => {
        if (!queriesLoading) {
            const dataReturned = overviewReportQuery.every(q => q.data);
            if (dataReturned) {
                const data = overviewReportQuery[0].data;
                const mpSummary = data.data.roles.metricspawnSummary;
                return {
                    rn: [
                        {
                            id: '911',
                            label: '911',
                            value: _round((mpSummary.totalRn911 / mpSummary.totalRn) * 100, 2) || '',
                        },
                        {
                            id: 'home',
                            label: 'home',
                            value: _round((mpSummary.totalRnHome / mpSummary.totalRn) * 100, 2) || '',
                        },
                        {
                            id: 'class',
                            label: 'class',
                            value: _round((mpSummary.totalRnClass / mpSummary.totalRn) * 100, 2) || '',
                        },
                    ],
                    lpn: [
                        {
                            id: '911',
                            label: '911',
                            value: _round((mpSummary.totalLpn911 / mpSummary.totalLpn) * 100, 2) || '',
                        },
                        {
                            id: 'home',
                            label: 'home',
                            value: _round((mpSummary.totalLpnHome / mpSummary.totalLpn) * 100, 2) || '',
                        },
                        {
                            id: 'class',
                            label: 'class',
                            value: _round((mpSummary.totalLpnClass / mpSummary.totalLpn) * 100, 2) || '',
                        },
                    ],
                    aide: [
                        {
                            id: '911',
                            label: '911',
                            value: _round((mpSummary.totalAide911 / mpSummary.totalAide) * 100, 2) || '',
                        },
                        {
                            id: 'home',
                            label: 'home',
                            value: _round((mpSummary.totalAideHome / mpSummary.totalAide) * 100, 2) || '',
                        },
                        {
                            id: 'class',
                            label: 'class',
                            value: _round((mpSummary.totalAideClass / mpSummary.totalAide) * 100, 2) || '',
                        },
                    ],
                };
            }
        }

        return {
            rn: [
                {
                    id: '911',
                    label: '911',
                    value: '',
                },
                {
                    id: 'home',
                    label: 'home',
                    value: '',
                },
                {
                    id: 'class',
                    label: 'class',
                    value: '',
                },
            ],
            lpn: [
                {
                    id: '911',
                    label: '911',
                    value: '',
                },
                {
                    id: 'home',
                    label: 'home',
                    value: '',
                },
                {
                    id: 'class',
                    label: 'class',
                    value: '',
                },
            ],
            aide: [
                {
                    id: '911',
                    label: '911',
                    value: '',
                },
                {
                    id: 'home',
                    label: 'home',
                    value: '',
                },
                {
                    id: 'class',
                    label: 'class',
                    value: '',
                },
            ],
        };
    };

    const renderChartValues = domainData => {
        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                {domainData.map((d, i) => {
                    return (
                        <div
                            key={d.label}
                            style={{
                                background: ['#b2e2cd', '#fdcdab', '#cbd6e7'][i],
                                flexGrow: 1,
                                padding: '0 5px',
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#333',
                                }}
                            >
                                {d.value ? d.value + '%' : 0 + '%'}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {props.settings.title || 'Health Office Visits'}
                        </div>
                    </div>
                    <div className={style.content}>
                        {queriesLoading ? (
                            <Skeleton height={268} />
                        ) : (
                            <>
                                <ChartLegend
                                    items={[
                                        { label: 'Sent home', color: '#fdcdab' },
                                        { label: 'Called 911', color: '#b2e2cd' },
                                        { label: 'Sent back to class', color: '#cbd6e7' },
                                    ]}
                                />
                                <div className={style.chartWrapper}>
                                    <div className={style.box}>
                                        <div className={style.boxHeading}>RN</div>
                                        {_every(getData().rn, ['value', '']) ? (
                                            <div className={style.chartPlaceholder}>
                                                <IconChart />
                                            </div>
                                        ) : (
                                            <>
                                                <PieChart data={getData().rn} />
                                                {renderChartValues(getData().rn)}
                                            </>
                                        )}
                                    </div>
                                    <div className={style.box}>
                                        <div className={style.boxHeading}>LPN</div>
                                        {_every(getData().lpn, ['value', '']) ? (
                                            <div className={style.chartPlaceholder}>
                                                <IconChart />
                                            </div>
                                        ) : (
                                            <>
                                                <PieChart data={getData().lpn} />
                                                {renderChartValues(getData().lpn)}
                                            </>
                                        )}
                                    </div>
                                    <div className={style.box}>
                                        <div className={style.boxHeading}>AIDE</div>
                                        {_every(getData().aide, ['value', '']) ? (
                                            <div className={style.chartPlaceholder}>
                                                <IconChart />
                                            </div>
                                        ) : (
                                            <>
                                                <PieChart data={getData().aide} />
                                                {renderChartValues(getData().aide)}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={style.additionalInfo}>
                            <Html content={props.settings.additionalInfo} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
