import React, { useEffect } from 'react';
import { DataBrowser } from 'cccisd-laravel-assignment';

function StateViewData(props) {
    useEffect(() => {}, []);

    useEffect(() => {}, [props.checked]);

    return <DataBrowser projectId={props.projectId} />;
}

export default StateViewData;
